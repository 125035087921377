import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { DateType } from '@date-io/type';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { InputProps } from '@material-ui/core/Input';
import { InputLabelProps } from '@material-ui/core/InputLabel';
import { FormHelperTextProps } from '@material-ui/core/FormHelperText';
import ClearIcon from '@material-ui/icons/Clear';

import DatePicker, { DatePickerProps } from '@components/DatePicker';

const DATE_TIME_FORMAT = 'D MMMM YYYY';

const useStyles = makeStyles(
  createStyles({
    clearIcon: {
      cursor: 'pointer',
      height: '0.8em',
      width: '0.8em',
    },
    filedNullValue: {
      '& input': {
        color: 'rgba(0, 0, 0, 0)',
      },
    },
  }),
);

type Views = 'year' | 'date' | 'month';

interface DateFieldProps extends Omit<DatePickerProps, 'value' | 'error'> {
  className?: string;
  value?: number;
  error?: string;
  touched?: boolean;
  label?: string;
  disablePast?: boolean;
  name?: string;
  dataTestAttribute?: string;
  helperText?: string;
  fullWidth?: boolean;
  format?: string;
  removable?: boolean;
  openTo?: Views;
  views?: Views[];
  minDate?: Date;
  maxDate?: Date;
  initialDate?: number;
  disableToolbar?: boolean;
  clearable?: boolean;
  onChange: (value: DateType | null) => void;
  onBlur?: InputProps['onBlur'];
  InputLabelProps?: InputLabelProps;
  FormHelperTextProps?: FormHelperTextProps;
  withoutLabel?: boolean;
  endAdornment?: JSX.Element | null;
}

const DateField: FC<DateFieldProps> = ({
  className,
  value,
  error,
  touched,
  label = 'Дата',
  disablePast = true,
  fullWidth = true,
  name = 'date',
  removable = true,
  dataTestAttribute,
  helperText,
  format = DATE_TIME_FORMAT,
  initialDate = moment(),
  openTo = 'date',
  views = ['year', 'month', 'date'],
  minDate,
  maxDate,
  disableToolbar,
  clearable = true,
  onChange,
  onBlur,
  InputLabelProps,
  withoutLabel = false,
  inputProps,
  InputProps,
  disabled,
  FormHelperTextProps,
  endAdornment,
}) => {
  const classes = useStyles();

  const dateValue: moment.Moment | undefined = value ? moment.unix(value) : undefined;

  const handleClearClick = useCallback(
    (e: React.MouseEvent<SVGElement>): void => {
      e.stopPropagation();
      onChange(null);
    },
    [onChange],
  );

  const _endAdornment = useMemo(() => {
    if (endAdornment) {
      return endAdornment;
    }
    if (value && removable) {
      return <ClearIcon className={classes.clearIcon} onClick={handleClearClick} />;
    }
    return null;
  }, [endAdornment, value, removable, classes, handleClearClick]);

  return (
    <DatePicker
      className={classNames(className, { [classes.filedNullValue]: !value })}
      autoOk={true}
      fullWidth={fullWidth}
      value={dateValue}
      variant="inline"
      label={!withoutLabel && label}
      name={name}
      format={format}
      disablePast={disablePast}
      error={Boolean(touched && error)}
      helperText={(touched && error) || helperText}
      initialFocusedDate={initialDate}
      views={views}
      openTo={openTo}
      minDate={minDate}
      maxDate={maxDate}
      disableToolbar={disableToolbar}
      data-test={dataTestAttribute}
      clearable={clearable}
      InputProps={{
        endAdornment: _endAdornment,
        notched: false,
        ...InputProps,
      }}
      InputLabelProps={InputLabelProps}
      onBlur={onBlur}
      onChange={onChange}
      inputProps={inputProps}
      disabled={disabled}
      FormHelperTextProps={FormHelperTextProps}
    />
  );
};

export default DateField;
