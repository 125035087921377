import React, { useCallback, useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { Button, Hidden, Grid, SwipeableDrawer } from '@material-ui/core';
import Navbar from './Navbar';

import { useStyles } from './styles';
import MenuNavbar from './MenuNavbar';

const Header = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const setOpenCallback = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Hidden mdUp>
          <SwipeableDrawer open={open} onClose={setOpenCallback} onOpen={setOpenCallback}>
            <div className={classes.menu}>
              <MenuNavbar setOpenCallback={setOpenCallback} />
            </div>
          </SwipeableDrawer>
        </Hidden>
        <Grid className={classes.iconGrid}>
          <Hidden mdUp>
            <Button className={classes.openButton} onClick={setOpenCallback}>
              <MenuIcon />
            </Button>
          </Hidden>
        </Grid>
        <Hidden smDown>
          <Navbar />
        </Hidden>
      </div>
    </div>
  );
};

export default Header;
