import React, { FC, useCallback, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Divider, Grid, Hidden, Slide, TextField, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import { OptionType } from '@core/components/Autocomplete';

import AddressSearch from './AddressSearch';

import config from '@core/constants/config';
import { parseFlatNumber } from '@core/utils/parseFlatNumber';
import { useSmevStore } from './hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: '15px',
      [theme.breakpoints.up('sm')]: {
        height: theme.spacing(8 * 2),
      },
    },
    rootShadow: {
      [theme.breakpoints.up('sm')]: {
        background: '#FFFFFF',
        boxShadow: '0px 8px 16px rgba(68, 84, 107, 0.1)',
        borderRadius: theme.spacing(2),
      },
    },
    wrapper: {
      position: 'relative',
      height: '100%',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        padding: theme.spacing(0, 3, 0, 2),
        border: '1px solid rgba(7, 0, 61, 0.15)',
        borderRadius: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 6, 0, 2),
      },
    },
    formItem: {
      width: 'auto',
      [theme.breakpoints.up('sm')]: {
        flex: '1 1 auto',
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(5),
        background: '#FFFFFF',
        boxShadow: '0px 8px 16px rgba(68, 84, 107, 0.1)',
        borderRadius: theme.spacing(1),
      },
    },
    executorWrapper: {
      [theme.breakpoints.up('sm')]: {
        minWidth: 260,
        width: '50%',
      },
    },
    accountWrapper: {
      minWidth: 120,
      [theme.breakpoints.up('sm')]: {
        width: '23%',
        maxWidth: 300,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
    },
    buttonWrapper: {
      minWidth: 88,
      [theme.breakpoints.up('sm')]: {
        width: '17%',
        maxWidth: 160,
      },
      [theme.breakpoints.down('xs')]: {
        height: theme.spacing(6 * 2),
      },
    },
    button: {
      height: '100%',
      [theme.breakpoints.up('sm')]: {
        height: theme.spacing(10),
        fontSize: 15,
      },
    },
    buttonNext: {
      color: '#FFFFFF',
      height: '100%',
      padding: theme.spacing(1.5, 2),
      [theme.breakpoints.up('sm')]: {
        height: theme.spacing(10),
        fontSize: 12,
      },
      [theme.breakpoints.up('md')]: {
        height: theme.spacing(10),
        fontSize: 15,
      },
    },
    buttonLabel: {
      lineHeight: 1.25,
    },
    inputClear: {
      padding: 4,
      borderRadius: '50%',
      minWidth: theme.spacing(7),
      '& > span > svg': {
        width: 20,
        height: 20,
        '& > path': {
          fill: 'rgba(0, 0, 0, 0.54) !important',
        },
      },
    },
    iconHidden: {
      visibility: 'hidden',
    },
    addressInput: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      '& > div': {
        height: '100%',
        marginTop: '0 !important',
      },
      '& > div > div': {
        height: '100%',
        paddingTop: `${theme.spacing(1.625)}px !important`,
        paddingBottom: `${theme.spacing(1.625)}px !important`,
        marginTop: '0 !important',
        [theme.breakpoints.up('sm')]: {
          minHeight: 62,
        },
        '& > fieldset': {
          borderStyle: 'none',
        },
      },
    },
    accountNumberInput: {
      height: '100%',
      marginTop: '0 !important',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
      '& > div': {
        height: '100%',
        marginTop: '0 !important',
        paddingRight: theme.spacing(2),
        '& > fieldset': {
          borderStyle: 'none',
        },
      },
    },
    divider: {
      alignSelf: 'center',
      height: theme.spacing(10),
    },
    icon: {
      height: 18,
      width: 18,
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(4),
      },
      '& > path': {
        fill: 'rgba(7, 0, 61, 0.2) !important',
      },
    },
    borderHidden: {
      border: 'none',
    },
    orgSwitcher: {
      position: 'absolute',
      bottom: '-40px',
      left: '0px',
      fontSize: '15px',
      color: '#18cda6',
      cursor: 'pointer',

      [theme.breakpoints.down('xs')]: {
        top: '2px',
        bottom: 'unset',
        left: '6px',
        fontSize: '12px',
      },
    },
    recaptchaText: {
      color: 'rgba(68, 84, 107, 0.5)',
      fontSize: 13,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),

      textAlign: 'center',
      '& > a': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
    },
  }),
);

interface PaymentFormProps {
  addrValue: null | OptionType;
  setAddrValue: React.Dispatch<React.SetStateAction<OptionType | null>>;
  accValue: string;
  setAccValue: React.Dispatch<React.SetStateAction<string>>;
  switchOrgSearch?: () => void;
}

const DadataPaymentForm: FC<PaymentFormProps> = ({
  switchOrgSearch,
  addrValue,
  setAddrValue,
  accValue,
  setAccValue,
}) => {
  const classes = useStyles();

  const recaptchaRef = React.useRef<ReCAPTCHA>(null);
  const [isRobot, setIsRobot] = useState(false);

  const { loadSmevAccounts, loadingSmevAccounts, capchaError } = useSmevStore();

  const [addressError, setAddressError] = useState<string>('');
  const [addressErrorColor, setAddressErrorColor] = useState<boolean>(false);
  const [accountError, setAccountError] = useState<string>('');

  useEffect(() => {
    if (capchaError) setIsRobot(true);
  }, [capchaError]);

  const clearErrors = useCallback(() => {
    setAddressError('');
    setAccountError('');
  }, []);

  const selectAddress = useCallback(
    (value: OptionType | null): void => {
      clearErrors();
      setAddrValue(value);
    },
    [clearErrors],
  );

  const selectAccountNumber = useCallback(
    e => {
      clearErrors();
      setAccValue(e.target.value);
    },
    [setAccValue, clearErrors],
  );

  const validateFormInputs = () => {
    let valid = true;
    let flatNumber = undefined;

    if (!addrValue) {
      setAddressErrorColor(true);
      setAddressError('Введите адрес');

      valid = false;
    }
    if (addrValue && addrValue?.value === 'null') {
      setAddressErrorColor(true);
      setAddressError('Адрес указан не полностью');

      valid = false;
    }
    if (addrValue && addrValue?.value && addrValue?.value !== 'null') {
      flatNumber = parseFlatNumber(addrValue?.label);
    }
    if (!flatNumber && addrValue?.value && addrValue?.value !== 'null') {
      setAddressErrorColor(true);
      setAddressError('Укажите номер квартиры');

      valid = false;
    }
    if (!accValue) {
      setAccountError('Введите лицевой счёт');

      valid = false;
    }

    return { valid, flatNumber };
  };

  const loadAccounts = async () => {
    clearErrors();

    const valid = validateFormInputs();

    if (valid.valid && valid.flatNumber) {
      if (recaptchaRef.current) {
        const captchaResponse = await recaptchaRef.current.executeAsync();

        const params = {
          filter: {
            houseFiasId: {
              $eq: addrValue?.value,
            },
            accountNumber: {
              $eq: accValue,
            },
            flatNumber: {
              $eq: String(valid.flatNumber),
            },
            allAccounts: true,
            captchaResponse: captchaResponse,
          },
          limit: 0,
          offset: 0,
        };

        loadSmevAccounts(params).finally(() => {
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
        });
      }
    }
  };

  return (
    <Slide direction="right" in mountOnEnter unmountOnExit>
      <form
        className={classNames(classes.root)}
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          loadAccounts();
        }}
      >
        <Grid container direction="column" wrap="nowrap" className={classNames(classes.wrapper)}>
          <Grid item className={classNames(classes.formItem, classes.executorWrapper)}>
            <AddressSearch
              className={classes.addressInput}
              setValue={selectAddress}
              value={addrValue}
              textFieldProps={{
                placeholder: 'Введите адрес',
                error: addressErrorColor,
                helperText: addressError,
              }}
              setAddressErrorColor={setAddressErrorColor}
              setAddressError={setAddressError}
              disabled={loadingSmevAccounts || isRobot}
              setIsRobot={setIsRobot}
            />
          </Grid>

          <Hidden xsDown>
            <Divider orientation="vertical" className={classes.divider} />
          </Hidden>

          <Grid item className={classNames(classes.formItem, classes.accountWrapper)}>
            <TextField
              fullWidth
              className={classes.accountNumberInput}
              value={accValue}
              onChange={selectAccountNumber}
              placeholder="Лицевой счёт"
              error={Boolean(accountError)}
              helperText={accountError}
              tabIndex={2}
              disabled={loadingSmevAccounts || isRobot}
              InputProps={{
                endAdornment: (
                  <Button
                    disabled={loadingSmevAccounts || isRobot}
                    onClick={(): void => setAccValue('')}
                    className={classNames(classes.inputClear, {
                      [classes.iconHidden]: !accValue,
                    })}
                  >
                    <ClearIcon />
                  </Button>
                ),
              }}
            />
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            className={classNames(classes.formItem, classes.buttonWrapper)}
          >
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.button}
              tabIndex={3}
              type={'submit'}
              disabled={loadingSmevAccounts || isRobot}
            >
              Найти
            </Button>
          </Grid>

          <Typography onClick={switchOrgSearch} className={classes.orgSwitcher}>
            Найти по исполнителю ЖКУ
          </Typography>
          {config.recaptchaKey && (
            <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={config.recaptchaKey} />
          )}
        </Grid>
      </form>
    </Slide>
  );
};

export default DadataPaymentForm;
