import React, { FC, useCallback, useState } from 'react';
import { Box } from '@material-ui/core';

import SmevDetailsModal from './SmevDetailsModal';
import { SmevAccountInterface } from '@core/entities/Billing/Smev';
import { useSmevStore } from './hooks';
import Preloader from 'oc-preloader/lib';
import { isSpecialBilling } from '@core/constants/project';
import SmevAccountCard from './SmevAccountCard';
import SmevPaymentFormDialog from './SmevPaymentFormDialog';
import SmevErrorBanner from './SmevErrorBanner';
import { SmevNotFound } from './SmevNotFound/SmevNotFound';

interface SmevPaymentBlockProps {
  paymentSource?: number;
  fromPublic?: boolean;
}

const SmevPaymentBlock: FC<SmevPaymentBlockProps> = ({ paymentSource, fromPublic }) => {
  const {
    loadSmevAccounts,
    loadingSmevAccounts,
    loadingSmevAccountsError,
    smevAccountList,
    smevAccountListCleanUp,
    smevAccountsError,
    notFirstFromSmev,
  } = useSmevStore();

  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [selectedSmevAccount, setSelectedSmevAccount] = useState<SmevAccountInterface | undefined>(
    undefined,
  );

  const openDetailsModal = () => {
    setDetailsModalOpen(true);
  };

  const openPaymentModal = () => {
    setPaymentModalOpen(true);
  };

  const setCurrentAccount = (account: SmevAccountInterface) => {
    setSelectedSmevAccount(account);
  };

  const handleCloseDetailsModal = useCallback(() => {
    setDetailsModalOpen(false);
  }, []);

  const handleClosePaymentModal = useCallback(() => {
    setPaymentModalOpen(false);
  }, []);

  if (!notFirstFromSmev && fromPublic) {
    return <></>;
  }

  return (
    <>
      {loadingSmevAccounts ? (
        <Box paddingTop={'100px'}>
          <Preloader color={isSpecialBilling ? '#3167F3' : ''} />
        </Box>
      ) : (
        <>
          {smevAccountList?.result && smevAccountList?.result?.length > 0 ? (
            smevAccountList?.result.map(smevAccount => (
              <SmevAccountCard
                smevAccount={smevAccount}
                openDetailsModal={openDetailsModal}
                openPaymentModal={openPaymentModal}
                setCurrentAccount={setCurrentAccount}
                fromPublic={Boolean(fromPublic)}
              />
            ))
          ) : (
            <SmevNotFound publicPart={fromPublic} />
          )}

          {fromPublic && (
            <SmevDetailsModal
              modalVisibility={detailsModalOpen}
              closeModal={handleCloseDetailsModal}
              selectedSmevAccount={selectedSmevAccount}
            />
          )}

          <SmevPaymentFormDialog
            modalVisibility={paymentModalOpen}
            closeModal={handleClosePaymentModal}
            selectedSmevAccount={selectedSmevAccount}
          />
        </>
      )}
      {smevAccountsError && <SmevErrorBanner error={smevAccountsError} />}
    </>
  );
};

export default SmevPaymentBlock;
