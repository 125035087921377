import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';

import { useFastPaymentStore } from '@public/components/Layout/hooks';

import { useStyles } from './styles';
import { ReactComponent as CabinetImage } from '../images/cabinet.svg';
import { ReactComponent as SignUpImage } from '../images/sign_in.svg';
import { ReactComponent as PayImage } from '../images/pay.svg';

import { FAST_PAYMENT, SIGN_IN, SIGN_UP } from '@public/constants/routes';

const Navbar = () => {
  const classes = useStyles();

  const { cleanUp } = useFastPaymentStore();

  return (
    <Grid className={classes.root}>
      <Link to={FAST_PAYMENT} className={classes.link}>
        <PayImage className={classes.navbarIcon} />
        Оплата ЖКУ
      </Link>

      <Link to={SIGN_UP} onClick={cleanUp} className={classes.link}>
        <SignUpImage className={classNames(classes.navbarIcon, classes.signUp)} />
        Зарегистрироваться
      </Link>

      <Link to={SIGN_IN} onClick={cleanUp} className={classes.link}>
        <CabinetImage className={classes.navbarIcon} />
        Войти
      </Link>
    </Grid>
  );
};

export default Navbar;
