/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

export const useAccountStore = () => {
  const { accountStore } = useStores();

  return useObserver(() => ({
    load: accountStore.load,
    partnerNotActive: accountStore.partnerNotActive,
    checkPartners: accountStore.checkPartners,
  }));
};

export const useMerchantGroupStore = () => {
  const { merchantGroupStore } = useStores();

  return useObserver(() => ({
    loadMerchantGroup: merchantGroupStore.load,
  }));
};

export const useFastPaymentStore = () => {
  const { fastPaymentStore } = useStores();

  return useObserver(() => ({
    setOrgName: fastPaymentStore.setOrgName,
    setAccount: fastPaymentStore.setAccountNumber,
    getOrgName: fastPaymentStore.orgName,
    getAccount: fastPaymentStore.accountNumber,
  }));
};

export const useCounterStore = () => {
  const { counterStore } = useStores();

  return useObserver(() => ({
    loadCounters: counterStore.load,
  }));
};

export const useMerchantStore = () => {
  const { merchantStore } = useStores();

  return useObserver(() => ({
    loadMerchant: merchantStore.load,
  }));
};

export const useOrganizationStore = () => {
  const { billingOrganizationStore } = useStores();

  return useObserver(() => ({
    loadOrg: billingOrganizationStore.load,
  }));
};

export const useOperationStore = () => {
  const { operationStore } = useStores();

  return useObserver(() => ({
    isAllowed: operationStore.isAllowed,
  }));
};
