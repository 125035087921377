import React, { FC, useCallback, useMemo, useState, useEffect, ReactNode } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  IconButton,
  TextField,
  Typography,
  Link,
  useTheme,
  useMediaQuery,
  DialogActions,
  Button,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { PaymentMethods } from '@core/components/PaymentMethods';
import { floatValueFormatter } from '@core/utils/formatter';
import { SmevAccountInterface } from '@core/entities/Billing/Smev';
import Icon from '@core/components/Icon';
import { Banner } from '@core/components';
import { usePaymentStore } from './hooks';
import moment from 'moment';

const textColor = '#07003D';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    padding: '16px 16px 16px 32px',
  },

  dialogTitleWrapper: {
    width: 'calc(100% - 60px)',
  },

  dialogTitle: {
    color: textColor,
  },

  сontent: {
    padding: '0 32px',
    flexGrow: 0,
  },

  actions: {
    padding: 32,
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },

  link: {
    cursor: 'pointer',
  },

  button: {
    fontSize: 15,
    marginLeft: 24,
    minWidth: 220,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      width: '100%',
    },
  },

  label: {
    color: 'rgba(7, 0, 61, 0.5)',
  },

  input: {
    color: textColor,
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },

  dialogContainer: {
    marginTop: 0,
    marginBottom: 0,
  },

  paymentInfo: {
    paddingTop: 2,
  },

  paymentMethodsWrapper: {
    marginTop: 10,
    marginBottom: 5,
  },

  infoBannerWrapper: {
    marginTop: 12,
    marginBottom: 6,
  },

  bannerRoot: {
    padding: '12px !important',
  },

  iconWrapper: {
    alignSelf: 'center',
  },

  text: {
    fontSize: '13px !important',
  },
}));

interface Props {
  selectedSmevAccount?: SmevAccountInterface;
  modalVisibility: boolean;
  closeModal: () => void;
  paymentSource?: number;
}

const SmevPaymentFormDialog: FC<Props> = ({ modalVisibility, closeModal, selectedSmevAccount }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const { paymentGetLink } = usePaymentStore();

  const [total, setTotal] = useState(0);
  const [paymentError, setPaymentError] = useState('');
  const [loadingPaymentLink, setLoadingPaymentLink] = useState(false);

  const [totalErrorColor, setTotalErrorColor] = useState(false);
  const [totalError, setTotalError] = useState('');

  const getSummary = () => {
    if (!selectedSmevAccount?.merchantBalance || selectedSmevAccount.merchantBalance < 0) {
      return 0;
    }

    return selectedSmevAccount.merchantBalance;
  };

  const handleClose = useCallback((): void => {
    setPaymentError('');
    setTotalErrorColor(false);
    setTotalError('');
    closeModal();
  }, [closeModal]);

  const validateTotal = (smevAccount: SmevAccountInterface | undefined, total: number) => {
    const minimalPaymentSum =
      smevAccount?.payment?.minimalPaymentSum && smevAccount?.payment?.minimalPaymentSum !== 'null'
        ? smevAccount?.payment?.minimalPaymentSum
        : '0';
    const maximumPaymentSum =
      smevAccount?.payment?.maximumPaymentSum && smevAccount?.payment?.maximumPaymentSum !== 'null'
        ? smevAccount?.payment?.maximumPaymentSum
        : '15000';

    if (total === 0) {
      setTotalErrorColor(true);
      setTotalError(`Сумма должна быть больше нуля`);
      return false;
    }

    if (total < Number(minimalPaymentSum)) {
      setTotalErrorColor(true);
      setTotalError(`Минимально возможный платеж  ${minimalPaymentSum} руб.`);
      return false;
    }

    if (total > Number(maximumPaymentSum)) {
      setTotalErrorColor(true);
      setTotalError(`Максимально возможный платеж ${maximumPaymentSum} руб.`);
      return false;
    }

    setTotalErrorColor(false);
    setTotalError('');

    return true;
  };

  const handleSumChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const total = parseFloat(floatValueFormatter(event.target.value, 2, 5));

    if (total) {
      validateTotal(selectedSmevAccount, total);
    }

    setTotal(total);
  };

  const smevPaymentGetLink = () => {
    if (
      selectedSmevAccount?.partner?.externalId &&
      selectedSmevAccount?.accountNumber &&
      selectedSmevAccount?.merchantGroupId &&
      validateTotal(selectedSmevAccount, total)
    ) {
      setLoadingPaymentLink(true);

      paymentGetLink({
        providerId: selectedSmevAccount?.partner?.externalId,
        merchantGroupId: selectedSmevAccount?.merchantGroupId,
        accountNumber: selectedSmevAccount?.accountNumber,
        amount: total,
        date: Number(moment(selectedSmevAccount?.balanceActualDate, 'YYYY-MM-DD').format('X')),
        // paymentSource,
        // emailForFiscalReceipt,
      })
        .then(({ value, error }) => {
          if (value) {
            // yM.goals.sendFormPaymentFast();
            window.open(value);
          }

          if (error) {
            setPaymentError(error);
          }
        })
        .finally(() => {
          setLoadingPaymentLink(false);
        });
    }
  };

  useEffect(() => {
    setTotal(getSummary);
  }, [selectedSmevAccount]);

  const getComission = (): React.ReactNode => {
    const comissionType =
      selectedSmevAccount?.payment?.commissionType &&
      selectedSmevAccount?.payment?.commissionType !== 'null'
        ? selectedSmevAccount?.payment?.commissionType
        : undefined;
    const percent =
      selectedSmevAccount?.payment?.percent && selectedSmevAccount?.payment?.percent !== 'null'
        ? selectedSmevAccount?.payment?.percent
        : undefined;

    const minCom =
      selectedSmevAccount?.payment?.minimalCommission &&
      selectedSmevAccount?.payment?.minimalCommission !== 'null'
        ? selectedSmevAccount?.payment?.minimalCommission
        : undefined;

    if (comissionType && comissionType == 'UPPER' && percent) {
      return `Комиссия: ${percent} %`;
    } else if (minCom) {
      return `Комиссия: ${minCom} ₽`;
    }

    return 'Без комиссии';
  };

  return (
    <>
      <Dialog open={modalVisibility} onClose={handleClose} fullScreen={isXs} fullWidth={true}>
        <DialogTitle classes={{ root: classes.title }}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item className={classes.dialogTitleWrapper}>
              <Typography variant="h4" className={classes.dialogTitle}>
                Оплата услуг ЖКХ
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent className={classes.сontent}>
          <Grid className={classes.dialogContainer} direction="column" container>
            <Grid item={true}>
              <TextField
                fullWidth={true}
                label="Сумма к оплате"
                type="number"
                value={total}
                error={totalErrorColor}
                helperText={totalError}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSumChange(event)}
                disabled={loadingPaymentLink}
                InputLabelProps={{
                  className: classes.label,
                }}
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                  endAdornment: !isXs && <PaymentMethods adornment />,
                }}
              />
            </Grid>

            <Grid className={classes.paymentInfo}>
              <Typography variant="caption" component="p" color="textSecondary">
                {getComission()}
              </Typography>

              <Typography variant="caption" component="p" color="textSecondary">
                Принимаются карты только российских банков
              </Typography>

              <Hidden smUp>
                <Typography variant="caption" component="p" color="textSecondary">
                  {`Нажимая \u00ABОплатить\u00BB, Вы соглашаетесь `}
                  <Link
                    className={classes.link}
                    target="_blank"
                    href={selectedSmevAccount?.payment?.offer || ''}
                  >
                    {`с\u00A0условиями\u00A0оферты.`}
                  </Link>
                </Typography>
              </Hidden>

              <Hidden smUp>
                <Grid container className={classes.paymentMethodsWrapper}>
                  <PaymentMethods />
                </Grid>
              </Hidden>
            </Grid>

            <Grid item className={classes.infoBannerWrapper}>
              <Banner className={classes.bannerRoot}>
                <Grid container wrap="nowrap" spacing={3}>
                  <Grid item className={classes.iconWrapper}>
                    <Icon icon="info" />
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" className={classes.text}>
                      Обновление информации о балансе вашего лицевого счета осуществляется раз в
                      месяц. При совершении платежа вы можете указать ваш email для получения
                      информации о платеже
                    </Typography>
                  </Grid>
                </Grid>
              </Banner>
            </Grid>
          </Grid>

          {paymentError && (
            <Grid item={true}>
              <Alert severity="error">{paymentError}</Alert>
            </Grid>
          )}
        </DialogContent>

        <DialogActions classes={{ root: classes.actions }}>
          <Hidden xsDown>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="caption" component="p" color="textSecondary">
                Оплачивая, Вы соглашаетесь
              </Typography>
              <Typography variant="caption" component="p" color="textSecondary">
                {`с\u00A0условиями\u00A0`}
                <Link
                  className={classes.link}
                  target="_blank"
                  href={selectedSmevAccount?.payment?.offer || ''}
                >
                  {`оферты.`}
                </Link>
              </Typography>
            </div>
          </Hidden>

          <Button
            variant="contained"
            color="primary"
            disabled={loadingPaymentLink}
            className={classes.button}
            onClick={smevPaymentGetLink}
          >
            Оплатить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SmevPaymentFormDialog;
