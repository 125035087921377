import React, { FC } from 'react';
import classnames from 'classnames';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  useMediaQuery,
  useTheme,
  IconButton,
  Button,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import { PASSWORD_RECOVERY, SIGN_IN } from '@public/constants/routes';

interface AlreadyExistModalProps {
  open: boolean;
  onClose: () => void;
  email: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      width: '100%',
      textAlign: 'center',
    },
    dialogContent: {
      padding: `0 ${theme.spacing(8)}px ${theme.spacing(8)}px ${theme.spacing(8)}px`,
    },
    title: {
      fontWeight: 500,
      paddingBottom: theme.spacing(3),
      fontSize: '21px',
    },
    text: {
      paddingBottom: theme.spacing(8),
      lineHeight: '24px',
    },
    outlinedButton: {
      marginBottom: theme.spacing(3),
    },
    modal: {
      borderRadius: '8px',
      maxWidth: '415px',
    },
  }),
);

const AlreadyExistModal: FC<AlreadyExistModalProps> = ({ open, onClose, email }) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const history = useHistory();

  const goToRecovery = (): void => {
    history.push(PASSWORD_RECOVERY);
  };

  const goToSignIn = (): void => {
    history.push(SIGN_IN);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={'xs'}
      fullScreen={isXs}
      fullWidth={true}
      PaperProps={{ className: classes.modal }}
    >
      <DialogTitle>
        <Grid container={true} alignItems="center" justify="flex-end">
          <Grid item={true}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container={true}>
          <Grid item={true} className={classes.row}>
            <Typography variant={'h5'} className={classes.title}>
              Email был ранее зарегистрирован{' '}
            </Typography>
          </Grid>
          <Grid item={true} className={classnames(classes.row, classes.text)}>
            {`Email ${email} был ранее зарегистрирован. Вы можете прямо сейчас войти в личный кабинет. Если вы забыли пароль для входа в кабинет, воспользуйтесь восстановлением пароля`}
          </Grid>
          <Grid item={true} className={classes.row}>
            <Button
              color="primary"
              variant="outlined"
              fullWidth={true}
              className={classes.outlinedButton}
              onClick={goToRecovery}
            >
              Не помню пароль
            </Button>
          </Grid>
          <Grid item={true} className={classes.row}>
            <Button color="primary" variant="contained" fullWidth={true} onClick={goToSignIn}>
              Войти
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AlreadyExistModal;
