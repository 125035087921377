import React, { FC, ReactNode } from 'react';

interface Props {
  loading: boolean;
  view: ReactNode;
}

export const AlternativeWrapper: FC<Props> = ({ loading, view, children }) => {
  return <>{loading ? <>{view}</> : <>{children}</>}</>;
};
