import React, { FC, ReactElement, useState, useEffect, useRef } from 'react';
import BaseAutocomplete, { RenderOptionState } from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { OptionType, RenderInput } from '.';

interface AutocompleteProps {
  className?: string;
  loading?: boolean;
  options: OptionType[];
  value: OptionType | null;
  disabled?: boolean;
  freeSolo?: boolean;
  renderInput: RenderInput;
  onChange: (value: OptionType | null) => void;
  onInputChange?: (value: string) => void;
  onClear: () => void;
}

const Autocomplete: FC<AutocompleteProps> = ({
  className,
  loading,
  options,
  value,
  disabled,
  freeSolo = false,
  renderInput,
  onInputChange,
  onChange,
  onClear,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [noOptionsText, setNoOptionsText] = useState<string>('Введите текст для поиска');
  const noOptionsTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect((): void => {
    if (noOptionsTimeout.current) {
      clearTimeout(noOptionsTimeout.current);
    }

    if (inputValue && loading) {
      noOptionsTimeout.current = setTimeout(() => {
        setNoOptionsText('Идет загрузка');
      }, 500);
    }

    if (inputValue && options.length === 0) {
      noOptionsTimeout.current = setTimeout(() => {
        setNoOptionsText('Не найдено');
      }, 500);
    }
    if (!inputValue) {
      noOptionsTimeout.current = setTimeout(() => {
        setNoOptionsText('Введите текст для поиска');
      }, 500);
    }
  }, [inputValue, options, loading]);

  useEffect((): void => {
    if (inputValue) {
      setInputValue(inputValue);
    }
  }, [inputValue]);

  const handleOpen = (): void => setOpen(true);

  const handleClose = (): void => setOpen(false);

  const handleChange = (_: unknown, newValue: OptionType | null): void => onChange(newValue);

  const getOptionLabel = (option: OptionType): string => option.label;

  const getOptionSelected = (option: OptionType, optionValue: OptionType): boolean =>
    option.value === optionValue.value;

  const handleInputChange = (_: unknown, newInputValue: string, reason: string): void => {
    if (reason === 'input' || reason === 'reset') {
      if (onInputChange) {
        onInputChange(newInputValue);
      }

      setInputValue(newInputValue);
    }

    if (reason === 'clear') {
      if (onInputChange) {
        onInputChange('');
      }

      setInputValue('');

      onChange(null);

      onClear();
    }
  };

  const renderOption = (
    option: OptionType,
    { inputValue: inputValueState }: RenderOptionState,
  ): ReactElement => {
    const matches = match(option.label, inputValueState);
    const parts = parse(option.label, matches);

    return (
      <div>
        {parts.map<ReactElement>((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    );
  };

  const filterOptions = (values: OptionType[]): OptionType[] => values;

  return (
    <BaseAutocomplete
      className={className}
      inputValue={inputValue}
      loading={loading}
      open={open}
      options={options}
      disabled={disabled}
      noOptionsText={noOptionsText}
      loadingText="Идет загрузка"
      closeText="Закрыть"
      clearText="Очистить"
      openText="Раскрыть"
      autoSelect={true}
      selectOnFocus={true}
      freeSolo={freeSolo}
      includeInputInList={true}
      filterOptions={filterOptions}
      value={value}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onOpen={handleOpen}
      onClose={handleClose}
      renderInput={renderInput}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
    />
  );
};

export default Autocomplete;
