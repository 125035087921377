import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '1441px',
      padding: '150px 149px 0 235px',
      [theme.breakpoints.up('lg')]: {
        margin: 'auto',
      },
      [theme.breakpoints.down('md')]: {
        padding: '42px 80px 55px 100px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '42px 40px 55px 40px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '42px 15px 55px 15px',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    contentContainer: {
      [theme.breakpoints.up('sm')]: {
        '& + &': {
          marginLeft: '30px',
        },
      },
    },
    container: {
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },

    subtitle: {
      fontWeight: 800,
      fontSize: '24px',
      lineHeight: '32px',
      color: '#07003D',
      marginBottom: '24px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        lineHeight: '28px',
        marginBottom: '16px',
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: '25px',
        textAlign: 'center',
        paddingLeft: '40px',
        paddingRight: '40px',
      },
    },
    content: {
      fontSize: '18px',
      lineHeight: '28px',
      color: '#07003D',
      marginBottom: '24px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: '26px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '15px',
        lineHeight: '20px',
        textAlign: 'center',
        paddingLeft: '20px',
        paddingRight: '20px',
        marginBottom: '8px',
      },
    },

    link: {
      textDecoration: 'none',
      position: 'relative',
      color: '#18CDA6',
      display: 'flex',
      alignItems: 'center',
      fontSize: '15px',

      [theme.breakpoints.up('sm')]: {
        '&:after': {
          display: 'flex',
          alignItems: 'center',
          content: '"\\2192"',
          marginBottom: '2px',
        },
        fontSize: '18px',
      },
    },
    imageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '20px',
        width: '70vw',
        height: '70%',
      },
    },
  }),
);
