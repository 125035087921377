import React, { FC } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';

import { MerchantGroup } from '@entities/Billing/MerchantGroup';
import { isSpecialBilling } from '@core/constants/project';

interface DetailsModalProps {
  data: MerchantGroup;
  modalVisibility: boolean;
  closeModal: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    maxWidth: 600,
    width: '100%',
  },
  dialogTitle: {
    padding: theme.spacing(6, 6, 4, 8),
  },
  dialogContent: {
    padding: theme.spacing(2, 8, 8, 8),
  },
  row: {
    '& + &': {
      marginTop: theme.spacing(2),
    },
  },
  rowTitle: {
    marginRight: theme.spacing(7),
  },
  text: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px !important',
    },
  },
  iconButton: {
    padding: theme.spacing(1),
  },
}));

const DetailsModal: FC<DetailsModalProps> = ({ modalVisibility, closeModal, data }) => {
  const classes = useStyles();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const { paymentName, paymentInn, paymentAcc, paymentCorAcc, paymentBik, paymentBank } = data;

  return (
    <Dialog
      fullScreen={isXs}
      open={modalVisibility}
      onClose={closeModal}
      classes={{ paper: classes.modalContainer }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <Grid item>{isSpecialBilling ? 'Информация о получателе' : 'Платёжная информация'}</Grid>
          <Grid item>
            <IconButton onClick={closeModal} className={classes.iconButton}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container direction="column">
          <Grid container justify="space-between" wrap="nowrap" className={classes.row}>
            <Grid item className={classes.rowTitle}>
              <Typography variant="body2" align="left" className={classes.text}>
                Получатель
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" align="right" className={classes.text}>
                {paymentName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="space-between" wrap="nowrap" className={classes.row}>
            <Grid item className={classes.rowTitle}>
              <Typography variant="body2" align="left" className={classes.text}>
                ИНН получателя
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" align="right" className={classes.text}>
                {paymentInn}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="space-between" wrap="nowrap" className={classes.row}>
            <Grid item className={classes.rowTitle}>
              <Typography variant="body2" align="left" className={classes.text}>
                Расчетный счет
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" align="right" className={classes.text}>
                {paymentAcc}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="space-between" wrap="nowrap" className={classes.row}>
            <Grid item className={classes.rowTitle}>
              <Typography variant="body2" align="left" className={classes.text}>
                Корр. счет
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" align="right" className={classes.text}>
                {paymentCorAcc}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="space-between" wrap="nowrap" className={classes.row}>
            <Grid item className={classes.rowTitle}>
              <Typography variant="body2" align="left" className={classes.text}>
                БИК
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" align="right" className={classes.text}>
                {paymentBik}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="space-between" wrap="nowrap" className={classes.row}>
            <Grid item className={classes.rowTitle}>
              <Typography variant="body2" align="left" className={classes.text}>
                Банк
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" align="right" className={classes.text}>
                {paymentBank}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DetailsModal;
