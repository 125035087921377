/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

export const useOwnProfileStore = () => {
  const { ownProfileStore } = useStores();

  return useObserver(() => ({
    billingUsers: ownProfileStore.billingUsers,
    cleanUp: ownProfileStore.cleanUp,
    selectedBillingUser: ownProfileStore.selectedBillingUser,
    user: ownProfileStore.user,
    profile: ownProfileStore.profile,
    update: ownProfileStore.update,
    updateBillingUser: ownProfileStore.updateBillingUser,
    orgId: ownProfileStore.selectedBillingUser?.organizationId,
  }));
};

export const useAccrualStore = () => {
  const { accrualStore } = useStores();

  return useObserver(() => ({
    load: accrualStore.load,
    getMeanBalanceByMerchant: accrualStore.getMeanBalanceByMerchant,
    loading: accrualStore.loading,
    accrualList: accrualStore.list,
  }));
};

export const useMerchantGroupStore = () => {
  const { merchantGroupStore } = useStores();

  return useObserver(() => ({
    sbpEnabled: merchantGroupStore.sbpEnabled,
  }));
};
