import { v4 as uuidV4 } from 'uuid';

import { FilterOperators } from '@stores/interfaces/Filter';
import { Load } from '@stores/interfaces/Entity';

export interface AddressParts {
  area?: string;
  areaPrefix?: string;
  region?: string;
  regionPrefix?: string;
  city?: string;
  cityPrefix?: string;
  district?: string;
  districtPrefix?: string;
  street?: string;
  streetPrefix?: string;
  house?: string;
  building?: string;
  houseLiter?: string;
  room?: string;
  roomLiter?: string;
  roomLiterOnly?: boolean;
  roomPrefix?: string;
}

export interface AccountInterface {
  active: number;
  address?: string | null;
  addressParts?: AddressParts | null;
  allowReceiveMeterReadFlag: boolean;
  balance?: number | null;
  balanceByNds?: number | null;
  balanceByService?: number | null;
  balanceDate?: string | null;
  canLoadCounter?: boolean | null;
  currentMonthPayed: number;
  invoiceFileUrl?: string | null;
  lastCounterDate?: number | null;
  lastPayedDate: number | null;
  number?: string | null;
  payer?: string | null;
  penalty?: number | null;
  providerId?: number | null;
  type: number;
  transferringPossibility: boolean;
}

export enum AccountStatus {
  CLOSED,
  ACTIVE,
}

export class Account {
  public id?: string;
  public active: number;
  public address?: string | null;
  public addressParts?: AddressParts | null;
  public allowReceiveMeterReadFlag: boolean;
  public balance?: number | null;
  public balanceByNds?: number | null;
  public balanceByService?: number | null;
  public balanceDate?: string | null;
  public canLoadCounter?: boolean | null;
  public currentMonthPayed: number;
  public invoiceFileUrl?: string | null;
  public lastCounterDate?: number | null;
  public lastPayedDate: number | null;
  public number?: string | null;
  public payer?: string | null;
  public penalty?: number | null;
  public providerId?: number | null;
  public type: number;
  public transferringPossibility: boolean;

  public constructor(value: AccountInterface) {
    this.id = uuidV4();
    this.active = value.active;
    this.address = value.address;
    this.addressParts = value.addressParts;
    this.allowReceiveMeterReadFlag = value.allowReceiveMeterReadFlag;
    this.number = value.number;
    this.payer = value.payer;
    this.providerId = value.providerId;
    this.balance = value.balance;
    this.balanceByService = value.balanceByService;
    this.penalty = value.penalty;
    this.balanceByService = value.balance;
    this.balanceByNds = value.balanceByNds;
    this.balanceDate = value.balanceDate;
    this.canLoadCounter = value.canLoadCounter;
    this.lastPayedDate = value.lastPayedDate;
    this.currentMonthPayed = value.currentMonthPayed;
    this.invoiceFileUrl = value.invoiceFileUrl;
    this.lastCounterDate = value.lastCounterDate;
    this.type = value.type;
    this.transferringPossibility = value.transferringPossibility;
  }
}

export enum AccountKeys {
  ID = 'id',
  ORGANIZATION_ID = 'organizationId',
  ACCOUNT_NUMBER = 'accountNumber',
  PROVIDER_ID = 'providerId',
}

export enum ActKeys {
  ACCOUNT_NUMBER = 'accountNumber',
  PROVIDER_ID = 'providerId',
  MERCHANT_ID = 'merchantId',
  FROM_DATE = 'fromDate',
  TO_DATE = 'toDate',
}

export interface AccountFilterProps {
  [AccountKeys.ID]?: FilterOperators<number>;
  [AccountKeys.ORGANIZATION_ID]?: FilterOperators<number>;
  [AccountKeys.ACCOUNT_NUMBER]?: FilterOperators<string>;
  [AccountKeys.PROVIDER_ID]?: FilterOperators<number>;
}

export interface ActFilterProps {
  [ActKeys.ACCOUNT_NUMBER]?: FilterOperators<string>;
  [ActKeys.PROVIDER_ID]?: FilterOperators<number>;
  [ActKeys.MERCHANT_ID]?: FilterOperators<string>;
  [ActKeys.FROM_DATE]?: FilterOperators<string>;
  [ActKeys.TO_DATE]?: FilterOperators<string>;
}

export type LoadAccount = Load<
  { filter?: AccountFilterProps; limit?: number; offset?: number; withoutLoader?: boolean },
  Account[]
>;

export type LoadActs = Load<{ filter?: ActFilterProps }, Act[]>;

export type AccountTemplate = {
  executor: string;
  executorId: string;
  flatAccountNumber: string;
};

export type Act = {
  documentUrl: string;
};
