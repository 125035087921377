import React, { FC, ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Zoom from '@material-ui/core/Zoom';

import OpencityOperations from '@services/opencity/methods';
import BillingMethods from '@core/services/billing/methods';
import IsAllowed from '@components/IsAllowed';
import * as routes from '@public/constants/routes';
import { DEFAULT_TRANSITION_DELAY } from '@public/constants/ui';
import config from '@constants/config';
import { useFastPaymentStore } from '../hooks';
import { isUsinsk } from '@core/constants/project';

const zoomTransitionStyle = {
  transitionDelay: DEFAULT_TRANSITION_DELAY,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: 16,
      fontWeight: 500,
      padding: theme.spacing(3, 4),
      whiteSpace: 'nowrap',
    },
    container: {
      marginLeft: theme.spacing(12),
    },
    link: {
      color: theme.palette.text.primary,
      fontFamily: 'roboto',
      textDecoration: 'none',
    },
    underline: {
      background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
      height: 2,
      marginLeft: theme.spacing(4),
      width: `calc(100% - ${theme.spacing(8)}px)`,
    },
    underlineContainer: {
      textAlign: 'center',
    },
  }),
);

interface DesktopMenuProps {
  id: string;
  pathname: string;
}

const DesktopMenu: FC<DesktopMenuProps> = ({ id, pathname }) => {
  const classes = useStyles();

  const { cleanUp } = useFastPaymentStore();

  const handleClick = (): void => {
    cleanUp();
  };

  const renderMenuItem = (
    label: string,
    to: string,
    itemId: string,
    operation: OpencityOperations | BillingMethods,
  ): ReactElement => (
    <IsAllowed operation={operation}>
      {(): ReactNode => (
        <Grid className={classes.underlineContainer} item={true} onClick={handleClick}>
          <Link className={classes.link} to={to} id={itemId}>
            <div className={classes.button}>{label}</div>
          </Link>
          <Zoom in={pathname === to} style={zoomTransitionStyle}>
            <Divider className={classes.underline} />
          </Zoom>
        </Grid>
      )}
    </IsAllowed>
  );

  return (
    <Grid
      className={classes.container}
      container={true}
      spacing={2}
      wrap="nowrap"
      alignItems="center"
    >
      {renderMenuItem('Главная', routes.ROOT, `${id}__home`, OpencityOperations.ISSUE_CREATE)}
      {!isUsinsk &&
        renderMenuItem('Заявка', routes.ORDER, `${id}__order`, OpencityOperations.ISSUE_CREATE)}
      {!isUsinsk &&
        renderMenuItem(
          'Оплата ЖКУ',
          routes.FAST_PAYMENT,
          `${id}__fast_payment`,
          BillingMethods.ACCOUNT_INDEX,
        )}

      {renderMenuItem(
        'Отключения',
        routes.SHUTDOWNS,
        `${id}__shutdowns`,
        OpencityOperations.INTERRUPT_INDEX,
      )}
      {renderMenuItem(
        'Рейтинги',
        routes.RATINGS,
        `${id}__ratings`,
        OpencityOperations.ORGANIZATION_RATING_INDEX,
      )}
      {config.helpCenterPublic && (
        <Grid className={classes.underlineContainer} item={true}>
          <a
            className={classes.link}
            href={config.helpCenterPublic}
            target={'_blank'}
            rel="noopener noreferrer"
          >
            <div className={classes.button}>Справка</div>
          </a>
        </Grid>
      )}
    </Grid>
  );
};

export default DesktopMenu;
