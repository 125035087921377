import React, { FC, useCallback, useState } from 'react';
import { Grid, Typography, Button, makeStyles, createStyles, Theme } from '@material-ui/core';
import { textPrimary } from 'oc-theme/lib';

import { yM } from '@core/utils/yandexMetrika/yandexMetrika';
import { Account } from '@core/entities/Billing/Account';
import { MerchantGroup } from '@core/entities/Billing/MerchantGroup';
import { DocumentsModal } from '@private/components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    organizationBlock: {
      marginBottom: theme.spacing(5),

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },

    organizationName: {
      fontWeight: 500,
      paddingRight: theme.spacing(4),
    },

    organizationButtonsBlock: {
      width: 'auto',

      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },

    downloadButton: {
      border: '1px solid rgba(7, 0, 61, 0.15)',
      fontSize: '15px',
      lineHeight: '32px',
      color: textPrimary,

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginTop: '15px',
      },
    },
  }),
);

interface Props {
  type: Account['type'];
  invoiceFileUrl: MerchantGroup['invoiceFileUrl'];
  docList: MerchantGroup['docList'];
}

export const PaymentDetails: FC<Props> = ({ type, invoiceFileUrl, docList = [] }) => {
  const classes = useStyles();

  const [isDocumentsModalVisible, setDocumentsModalState] = useState(false);

  const handleDocumentsOpen = useCallback(() => {
    setDocumentsModalState(true);
  }, []);

  const handleDocumentsClose = useCallback(() => {
    setDocumentsModalState(false);
  }, []);

  return (
    <Grid
      container
      justify={'space-between'}
      alignItems={'flex-start'}
      className={classes.organizationBlock}
    >
      <Grid item>
        <Typography className={classes.organizationName}>Детализация услуг</Typography>
      </Grid>
      {type && docList.length ? (
        <Grid item container className={classes.organizationButtonsBlock}>
          <Button
            color="primary"
            variant="outlined"
            size="medium"
            // disabled={!docList.length}
            onClick={handleDocumentsOpen}
            className={classes.downloadButton}
          >
            Скачать документы
          </Button>
        </Grid>
      ) : (
        <Grid item container className={classes.organizationButtonsBlock}>
          {invoiceFileUrl && (
            <Button
              color="primary"
              variant="outlined"
              size="medium"
              onClick={(): void => {
                yM.goals.downloadInvoice();
                window.open(invoiceFileUrl);
              }}
              className={classes.downloadButton}
            >
              Скачать квитанцию
            </Button>
          )}
        </Grid>
      )}

      <DocumentsModal
        isVisible={isDocumentsModalVisible}
        onClose={handleDocumentsClose}
        docList={docList}
      />
    </Grid>
  );
};
