import React, { FC, useState, useEffect } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';

import { useDadataStoreStore } from './hooks';
import Autocomplete, { RenderInput, OptionType } from '@components/Autocomplete';
import { parseFlatNumber } from '@core/utils/parseFlatNumber';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '@core/constants/config';
import { makeDebounce } from '@core/utils/makeDebounce';

interface AddressSearchProps {
  textFieldProps?: TextFieldProps;
  selectAddress?: (value: string | null) => void;
  className?: string;
  value?: OptionType | null;
  setValue?: (value: OptionType | null) => void;
  isNeedToClear?: boolean;
  disabled?: boolean;
  setAddressErrorColor: (value: React.SetStateAction<boolean>) => void;
  setAddressError: (value: React.SetStateAction<string>) => void;
  setIsRobot: (value: React.SetStateAction<boolean>) => void;
}

const AddressSearch: FC<AddressSearchProps> = ({
  textFieldProps,
  selectAddress,
  className,
  value,
  setValue,
  isNeedToClear,
  disabled,
  setAddressErrorColor,
  setAddressError,
  setIsRobot,
}) => {
  const {
    loading,
    loadingError,
    list,
    cleanUpList,
    loadList,
    capchaError,
    checkDadataIsShowCaptcha,
  } = useDadataStoreStore();

  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const [_value, _setValue] = useState<OptionType | null>(null);
  const [options, setOptions] = useState<OptionType[]>([]);
  const [inProgress, setInProgress] = useState<boolean>(false);

  useEffect(() => {
    if (list?.suggestions && list?.suggestions.length) {
      setOptions([]);
      const options = list.suggestions.map(item => ({
        label: `${item.fullAddress}`,
        value: String(item.houseFiasId),
      }));
      setOptions(options);
    } else {
      setOptions([]);
    }
  }, [list]);

  useEffect(() => {
    if (capchaError) setIsRobot(true);
  }, [capchaError]);

  const handleChangeInput = async (inputValue: string) => {
    // cleanUpList();
    if (!inProgress) {
      setInProgress(true);

      await checkDadataIsShowCaptcha().then(async result => {
        if (recaptchaRef.current && result) {
          await recaptchaRef.current.executeAsync().then(result => {
            loadList({
              address: inputValue ? inputValue : '',
              captchaResponse: result,
            }).finally(() => {
              if (recaptchaRef.current) {
                recaptchaRef.current.reset();
              }
              setInProgress(false);
            });
          });
        } else {
          loadList({
            address: inputValue ? inputValue : '',
            captchaResponse: '',
          }).finally(() => {
            setInProgress(false);
          });
        }
      });
    }
  };

  const handleChange = (option: OptionType | null) => {
    if (option) {
      setValue ? setValue(option) : _setValue(option);
      selectAddress?.(option.value);

      if (option.value === 'null') {
        setAddressErrorColor(false);
        setAddressError('Адрес указан не полностью');
      } else if (!parseFlatNumber(option.label)) {
        setAddressErrorColor(false);
        setAddressError('Укажите номер квартиры');
      } else {
        setAddressError('');
      }
    }
  };

  const debouncedHandler = makeDebounce(handleChangeInput, 500);

  const renderInput: RenderInput = params => {
    return (
      <TextField
        {...params}
        {...textFieldProps}
        variant={'outlined'}
        InputLabelProps={{
          ...params.InputLabelProps,
          ...textFieldProps?.InputLabelProps,
          shrink: true,
        }}
        InputProps={{
          ...params.InputProps,
          ...textFieldProps?.InputProps,
          notched: false,
        }}
        FormHelperTextProps={{
          variant: 'standard',
        }}
        tabIndex={1}
        disabled={disabled}
      />
    );
  };

  const clear = (): void => {
    handleChangeInput('');
    selectAddress?.(null);
    setValue ? setValue(null) : _setValue(null);
  };

  useEffect(() => {
    if (isNeedToClear) clear();
  }, [isNeedToClear]);

  return (
    <>
      <Autocomplete
        {...textFieldProps}
        loading={loading}
        options={options}
        value={value || _value}
        renderInput={renderInput}
        onChange={handleChange}
        onClear={clear}
        onInputChange={debouncedHandler}
        className={className}
        disabled={disabled}
      />

      {config.recaptchaKey && (
        <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={config.recaptchaKey} />
      )}
    </>
  );
};

export default AddressSearch;
