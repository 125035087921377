import React, { FC } from 'react';
import { Grid, Typography, useTheme, Fade, Paper, Button, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

// import { isSpecialBilling } from '@core/constants/project';
import { SmevAccountInterface } from '@core/entities/Billing/Smev';
import moment from 'moment';
import { firstCharUpperFormat, moneyFormat } from '@core/utils/formatter';

const useStyles = makeStyles((theme: Theme) => ({
  cardGrid: {
    padding: '25px',
  },
  sectionGrid: {
    [theme.breakpoints.up(1000)]: {
      borderRight: '1px solid rgba(7, 0, 61, 0.15)',
    },
  },
  beakingPointWrapper: {
    padding: '25px',
    display: 'flex',
    flexDirection: 'column',
  },
  sectionWrapper: {
    paddingRight: '10px',
  },
  paymentSectionWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  serviceOrgSectionWrapper: {
    [theme.breakpoints.up(1000)]: {
      marginLeft: '35px',
    },
  },
  dateWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  summaryWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  summWrapper: {
    marginBottom: '5px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  summaryFont: {
    fontWeight: 600,
  },
  buttonWrapper: {
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  button: {
    height: '36px',
    fontSize: '13px',
    lineHeight: '36px',
    maxWidth: '160px',

    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
      maxWidth: '100%',
    },
  },
  headerFont: {
    marginBottom: '15px',
    fontSize: '18px',
    lineHeight: '18px',
    fontWeight: 500,

    [theme.breakpoints.down(1100)]: {
      fontSize: '15px',
    },

    [theme.breakpoints.down(1000)]: {
      marginBottom: '5px',
    },
  },
  mainFont: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '8px',
    fontSize: '15px',
    lineHeight: '28px',

    [theme.breakpoints.down(1100)]: {
      fontSize: '12px',
    },
  },
  grayFont: {
    color: 'rgba(7, 0, 61, 0.5)',
    fontSize: '15px',
    lineHeight: '28px',

    [theme.breakpoints.down(1100)]: {
      fontSize: '12px',
    },
  },
  titleFont: {
    fontSize: '15px',
    lineHeight: '28px',
    color: 'rgba(7, 0, 61, 0.6)',

    [theme.breakpoints.down(1100)]: {
      fontSize: '12px',
    },
  },
  dateFont: {
    padding: '4px 12px',
    fontSize: '13px',
    lineHeight: '20px',
    color: 'rgba(7, 0, 61, 0.5)',
    background: '#F0F2F5',
    borderRadius: '54px',
  },
  moreFont: {
    margin: '10px 0',
    color: '#18cda6',
    fontSize: '15px',
    cursor: 'pointer',

    [theme.breakpoints.down(1100)]: {
      fontSize: '12px',
    },
  },
}));

interface SmevAccountCardProps {
  smevAccount: SmevAccountInterface;
  fromPublic?: boolean;
  setCurrentAccount: (currentAccount: SmevAccountInterface) => void;
  openDetailsModal: () => void;
  openPaymentModal: () => void;
}

const SmevAccountCard: FC<SmevAccountCardProps> = ({
  smevAccount,
  setCurrentAccount,
  openDetailsModal,
  openPaymentModal,
  fromPublic,
}) => {
  const classes = useStyles();

  const theme = useTheme();

  const isSmallButton = useMediaQuery(theme.breakpoints.down(1155));
  const isBreakingPoint = useMediaQuery(theme.breakpoints.up(1000));

  const {
    recipient,
    bank,
    partner,
    payment,
    merchantBalance,
    balanceActualDate,
    accountNumber,
    accountAddress,
  } = smevAccount;

  const getSummary = () => {
    if (!merchantBalance || merchantBalance < 0) {
      return 0;
    }

    return merchantBalance;
  };

  const getComission = (): React.ReactNode => {
    const comissionType =
      smevAccount?.payment?.commissionType && smevAccount?.payment?.commissionType !== 'null'
        ? smevAccount?.payment?.commissionType
        : undefined;
    const percent =
      smevAccount?.payment?.percent && smevAccount?.payment?.percent !== 'null'
        ? smevAccount?.payment?.percent
        : undefined;
    const minCom =
      smevAccount?.payment?.minimalCommission && smevAccount?.payment?.minimalCommission !== 'null'
        ? smevAccount?.payment?.minimalCommission
        : undefined;

    if (comissionType && comissionType == 'UPPER' && percent) {
      return ` ${percent} %`;
    } else if (minCom) {
      return ` ${minCom} ₽`;
    }

    return 'без комиссии';
  };

  const moreClickHandler = (smevAccount: SmevAccountInterface) => {
    setCurrentAccount(smevAccount);
    openDetailsModal();
  };

  const payClickHandler = (smevAccount: SmevAccountInterface) => {
    setCurrentAccount(smevAccount);
    openPaymentModal();
  };

  const grayFormatPayedValue = (value: number | null | undefined): [string, string] => {
    const [intPart, decimalPart] = moneyFormat(Number(value)).split(',');

    return [intPart, decimalPart];
  };

  const coloredMerchantBalance = grayFormatPayedValue(merchantBalance ? merchantBalance : 0);

  return (
    <Fade in timeout={500}>
      <Grid style={{ marginBottom: '25px' }}>
        <Paper style={{ height: 'auto' }}>
          {isBreakingPoint ? (
            <Grid container className={classes.cardGrid}>
              <Grid item xs={12} sm={6} lg={6} className={classes.sectionGrid}>
                <div className={classes.sectionWrapper}>
                  <Typography className={classes.headerFont}>Получатель платежа:</Typography>

                  <Grid item container direction="row" wrap="nowrap">
                    <Typography className={classes.titleFont}>
                      Наименование: <span className={classes.mainFont}>{recipient?.name}</span>
                    </Typography>
                  </Grid>

                  <Grid item container direction="row" wrap="nowrap">
                    <Typography className={classes.titleFont}>ИНН:</Typography>{' '}
                    <Typography className={classes.mainFont}>{recipient?.inn}</Typography>
                  </Grid>

                  {!fromPublic && (
                    <Grid item container direction="row" wrap="nowrap">
                      <Typography className={classes.titleFont}>КПП:</Typography>{' '}
                      <Typography className={classes.mainFont}>{recipient?.kpp}</Typography>
                    </Grid>
                  )}

                  <Grid item container direction="row" wrap="nowrap">
                    <Typography className={classes.titleFont}>Расчётный счёт:</Typography>{' '}
                    <Typography className={classes.mainFont}>
                      {recipient?.paymentAccount}
                    </Typography>
                  </Grid>

                  <Grid item container direction="row" wrap="nowrap">
                    <Typography className={classes.titleFont}>
                      Банк: <span className={classes.mainFont}>{bank?.name}</span>
                    </Typography>
                  </Grid>

                  {!fromPublic && (
                    <Grid item container direction="row" wrap="nowrap">
                      <Typography className={classes.titleFont}>
                        БИК банка: <span className={classes.mainFont}>{bank?.bik}</span>
                      </Typography>
                    </Grid>
                  )}

                  <Grid item container direction="row" wrap="nowrap">
                    <Typography className={classes.titleFont}>Корр. счёт:</Typography>{' '}
                    <Typography className={classes.mainFont}>
                      {bank?.correspondentAccount}
                    </Typography>
                  </Grid>

                  {fromPublic && (
                    <Grid item container direction="row">
                      <Typography
                        className={classes.moreFont}
                        onClick={() => moreClickHandler(smevAccount)}
                      >
                        Подробнее
                      </Typography>
                    </Grid>
                  )}
                </div>
              </Grid>

              <Grid item xs={12} sm={4} lg={4}>
                <div className={classes.serviceOrgSectionWrapper}>
                  <Typography className={classes.headerFont}>Обслуживающая организация:</Typography>

                  <Grid item container direction="row" wrap="nowrap">
                    <Typography className={classes.titleFont}>
                      Наименование: <span className={classes.mainFont}>{partner?.name}</span>
                    </Typography>
                  </Grid>

                  {fromPublic && (
                    <>
                      <Grid item container direction="row" wrap="nowrap">
                        <Typography className={classes.titleFont}>Лицевой счёт:</Typography>

                        <Typography className={classes.mainFont}>{accountNumber}</Typography>
                      </Grid>

                      <Grid item container direction="row" wrap="nowrap">
                        <Typography className={classes.titleFont}>
                          Адрес: <span className={classes.mainFont}>{accountAddress}</span>
                        </Typography>
                      </Grid>
                    </>
                  )}

                  <Grid item container direction="row" wrap="nowrap">
                    <Typography className={classes.titleFont}>К оплате по услугам:</Typography>

                    <Typography className={classes.mainFont}>
                      {coloredMerchantBalance[0]}
                      <span className={classes.grayFont}>
                        {coloredMerchantBalance[1] && `,${coloredMerchantBalance[1]}`}
                      </span>
                    </Typography>
                  </Grid>

                  <Grid item container direction="row" wrap="nowrap">
                    <Typography className={classes.titleFont}>Комиссия:</Typography>

                    <Typography className={classes.mainFont}>{getComission()}</Typography>
                  </Grid>
                </div>
              </Grid>

              <Grid item xs={12} sm={2} lg={2}>
                <div className={classes.paymentSectionWrapper}>
                  <div className={classes.dateWrapper}>
                    <Typography className={classes.dateFont}>
                      {firstCharUpperFormat(
                        moment(balanceActualDate, 'YYYY-MM-DD').format('MMMM YYYY'),
                      )}
                    </Typography>
                  </div>

                  <div className={classes.summaryWrapper}>
                    <div className={classes.summWrapper}>
                      <Typography className={classes.summaryFont}>
                        {moneyFormat(getSummary())}
                      </Typography>
                    </div>

                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      tabIndex={3}
                      onClick={() => payClickHandler(smevAccount)}
                    >
                      {!isSmallButton &&
                        (getSummary() <= 0 ? 'Оплатить аванс' : 'Перейти к оплате')}
                      {isSmallButton && 'Оплатить'}
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          ) : (
            <div className={classes.beakingPointWrapper}>
              <div className={classes.dateWrapper}>
                <Typography className={classes.dateFont}>
                  {firstCharUpperFormat(
                    moment(balanceActualDate, 'YYYY-MM-DD').format('MMMM YYYY'),
                  )}
                </Typography>
              </div>

              <div className={classes.sectionWrapper}>
                <Typography className={classes.headerFont}>Получатель платежа:</Typography>

                <Grid item container direction="row" wrap="nowrap">
                  <Typography className={classes.titleFont}>
                    Наименование: <span className={classes.mainFont}>{recipient?.name}</span>
                  </Typography>
                </Grid>

                <Grid item container direction="row" wrap="nowrap">
                  <Typography className={classes.titleFont}>ИНН:</Typography>{' '}
                  <Typography className={classes.mainFont}>{recipient?.inn}</Typography>
                </Grid>

                {!fromPublic && (
                  <Grid item container direction="row" wrap="nowrap">
                    <Typography className={classes.titleFont}>КПП:</Typography>{' '}
                    <Typography className={classes.mainFont}>{recipient?.kpp}</Typography>
                  </Grid>
                )}

                <Grid item container direction="row" wrap="nowrap">
                  <Typography className={classes.titleFont}>Расчётный счёт:</Typography>{' '}
                  <Typography className={classes.mainFont}>{recipient?.paymentAccount}</Typography>
                </Grid>

                <Grid item container direction="row" wrap="nowrap">
                  <Typography className={classes.titleFont}>
                    Банк: <span className={classes.mainFont}>{bank?.name}</span>
                  </Typography>
                </Grid>

                {!fromPublic && (
                  <Grid item container direction="row" wrap="nowrap">
                    <Typography className={classes.titleFont}>
                      БИК банка: <span className={classes.mainFont}>{bank?.bik}</span>
                    </Typography>
                  </Grid>
                )}

                <Grid item container direction="row" wrap="nowrap">
                  <Typography className={classes.titleFont}>Корр. счёт:</Typography>{' '}
                  <Typography className={classes.mainFont}>{bank?.correspondentAccount}</Typography>
                </Grid>
              </div>

              <div style={{ marginTop: '20px' }}>
                <Typography className={classes.headerFont}>Обслуживающая организация:</Typography>

                <Grid item container direction="row" wrap="nowrap">
                  <Typography className={classes.titleFont}>
                    Наименование: <span className={classes.mainFont}>{partner?.name}</span>
                  </Typography>
                </Grid>

                {fromPublic && (
                  <>
                    <Grid item container direction="row" wrap="nowrap">
                      <Typography className={classes.titleFont}>Лицевой счёт:</Typography>

                      <Typography className={classes.mainFont}>{accountNumber}</Typography>
                    </Grid>

                    <Grid item container direction="row" wrap="nowrap">
                      <Typography className={classes.titleFont}>
                        Адрес: <span className={classes.mainFont}>{accountAddress}</span>
                      </Typography>
                    </Grid>
                  </>
                )}

                <Grid item container direction="row" wrap="nowrap">
                  <Typography className={classes.titleFont}>К оплате по услугам:</Typography>

                  <Typography className={classes.mainFont}>
                    {moneyFormat(merchantBalance ? merchantBalance : 0)}
                  </Typography>
                </Grid>

                <Grid item container direction="row" wrap="nowrap">
                  <Typography className={classes.titleFont}>Комиссия:</Typography>

                  <Typography className={classes.mainFont}>{getComission()}</Typography>
                </Grid>
              </div>

              <div className={classes.buttonWrapper}>
                {fromPublic && (
                  <Button
                    fullWidth
                    variant="outlined"
                    color="default"
                    className={classes.button}
                    tabIndex={3}
                    onClick={() => moreClickHandler(smevAccount)}
                  >
                    Подробнее
                  </Button>
                )}

                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  tabIndex={3}
                  onClick={() => payClickHandler(smevAccount)}
                >
                  Оплатить
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </Grid>
    </Fade>
  );
};

export default SmevAccountCard;
