import React, { FC, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Button,
  Divider,
  Grid,
  Hidden,
  TextField,
  Typography,
  TextFieldProps,
  Slide,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';

import {
  useAccountStore,
  useFastPaymentStore,
  useMerchantGroupStore,
  useCounterStore,
  useMerchantStore,
  useOrganizationStore,
  useOperationStore,
} from './hooks';

import { MerchantGroup } from '@entities/Billing/MerchantGroup';
import { Account, AccountInterface, AccountKeys } from '@entities/Billing/Account';

import { OptionType } from '@components/Autocomplete';
import OrganizationSearch from '@private/components/OrganizationSearch';
import { FAST_PAYMENT } from '@public/constants/routes';
import { yM } from '@core/utils/yandexMetrika/yandexMetrika';
import { FastPaymentAccountStatus } from '@public/pages/FastPayment/PaymentBlock/PaymentBlock';
import { CounterKeys, Counter } from '@entities/Billing/Counter';
import { Merchant } from '@core/entities/Billing/Merchant';
import { EXTERNAL_SUPPLIER } from '@core/constants/openCityProviderId';
import config from '@core/constants/config';
import { OrganizationKeys } from '@core/entities/Billing/Organization';
import BillingMethods from '@core/services/billing/methods';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: '15px',
      [theme.breakpoints.up('sm')]: {
        height: theme.spacing(8 * 2),
      },
    },
    rootShadow: {
      [theme.breakpoints.up('sm')]: {
        background: '#FFFFFF',
        boxShadow: '0px 8px 16px rgba(68, 84, 107, 0.1)',
        borderRadius: theme.spacing(2),
      },
    },
    wrapper: {
      position: 'relative',
      height: '100%',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        padding: theme.spacing(0, 3, 0, 2),
        border: '1px solid rgba(7, 0, 61, 0.15)',
        borderRadius: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 6, 0, 2),
      },
    },
    formItem: {
      width: 'auto',
      [theme.breakpoints.up('sm')]: {
        flex: '1 1 auto',
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(5),
        background: '#FFFFFF',
        boxShadow: '0px 8px 16px rgba(68, 84, 107, 0.1)',
        borderRadius: theme.spacing(1),
      },
    },
    executorWrapper: {
      [theme.breakpoints.up('sm')]: {
        minWidth: 260,
        width: '50%',
      },
    },
    accountWrapper: {
      minWidth: 120,
      [theme.breakpoints.up('sm')]: {
        width: '23%',
        maxWidth: 300,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
    },
    buttonWrapper: {
      minWidth: 88,
      [theme.breakpoints.up('sm')]: {
        width: '17%',
        maxWidth: 160,
      },
      [theme.breakpoints.down('xs')]: {
        height: theme.spacing(6 * 2),
      },
    },
    button: {
      height: '100%',
      [theme.breakpoints.up('sm')]: {
        height: theme.spacing(10),
        fontSize: 15,
      },
    },
    buttonNext: {
      color: '#FFFFFF',
      height: '100%',
      padding: theme.spacing(1.5, 2),
      [theme.breakpoints.up('sm')]: {
        height: theme.spacing(10),
        fontSize: 12,
      },
      [theme.breakpoints.up('md')]: {
        height: theme.spacing(10),
        fontSize: 15,
      },
    },
    buttonLabel: {
      lineHeight: 1.25,
    },
    inputClear: {
      padding: 4,
      borderRadius: '50%',
      minWidth: theme.spacing(7),
      '& > span > svg': {
        width: 20,
        height: 20,
        '& > path': {
          fill: 'rgba(0, 0, 0, 0.54) !important',
        },
      },
    },
    iconHidden: {
      visibility: 'hidden',
    },
    organizationInput: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      '& > div': {
        height: '100%',
        marginTop: '0 !important',
      },
      '& > div > div': {
        height: '100%',
        paddingTop: `${theme.spacing(1.625)}px !important`,
        paddingBottom: `${theme.spacing(1.625)}px !important`,
        marginTop: '0 !important',
        [theme.breakpoints.up('sm')]: {
          minHeight: 62,
        },
        '& > fieldset': {
          borderStyle: 'none',
        },
      },
    },
    accountNumberInput: {
      height: '100%',
      marginTop: '0 !important',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
      '& > div': {
        height: '100%',
        marginTop: '0 !important',
        paddingRight: theme.spacing(2),
        '& > fieldset': {
          borderStyle: 'none',
        },
      },
    },
    divider: {
      alignSelf: 'center',
      height: theme.spacing(10),
    },
    icon: {
      height: 18,
      width: 18,
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(4),
      },
      '& > path': {
        fill: 'rgba(7, 0, 61, 0.2) !important',
      },
    },
    borderHidden: {
      border: 'none',
    },
    orgSwitcher: {
      position: 'absolute',
      bottom: '-40px',
      left: '0px',
      fontSize: '15px',
      color: '#18cda6',
      cursor: 'pointer',

      [theme.breakpoints.down('xs')]: {
        top: '0px',
        bottom: 'unset',
        left: '6px',
        fontSize: '12px',
      },
    },
  }),
);

interface Data {
  merchantGroups: MerchantGroup[];
  merchants: Merchant[];
  counters: Counter[];
}

interface PaymentFormProps {
  action?: boolean;
  fromLanding?: boolean;
  setMerchantGroups?: React.Dispatch<React.SetStateAction<MerchantGroup[]>>;
  setMerchants?: React.Dispatch<React.SetStateAction<Merchant[]>>;
  setSingleMerchantGroup?: React.Dispatch<React.SetStateAction<MerchantGroup | null>>;
  setAccountTrouble?: React.Dispatch<React.SetStateAction<FastPaymentAccountStatus>>;
  setAction?: React.Dispatch<React.SetStateAction<boolean>>;
  toggleLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setOrganizationData?: (organizationId: number, organizationName: string) => void;
  setParentAccounts?: (val: Account[]) => void;
  switchOrgSearch?: () => void;
}

const PaymentForm: FC<PaymentFormProps> = ({
  action = false,
  fromLanding = false,
  setMerchantGroups,
  setMerchants,
  setSingleMerchantGroup,
  setAccountTrouble,
  setAction,
  toggleLoading,
  setOrganizationData,
  setParentAccounts,
  switchOrgSearch,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const { loadMerchantGroup } = useMerchantGroupStore();
  const { checkPartners } = useAccountStore();
  const { loadCounters } = useCounterStore();
  const { loadMerchant } = useMerchantStore();
  const { isAllowed } = useOperationStore();

  const { setAccount, setOrgName, getAccount, getOrgName } = useFastPaymentStore();
  const { loadOrg } = useOrganizationStore();

  const [orgValue, setOrgValue] = useState<null | OptionType>(getOrgName);
  const [accValue, setAccValue] = useState(getAccount);
  const [organizationError, setOrganizationError] = useState('');
  const [accountError, setAccountError] = useState('');

  const url = new URL(window.location.href);
  const providerId = url.searchParams.get('providerId');

  useEffect(() => {
    if (orgValue && setOrganizationData) {
      const { data } = orgValue;
      const { id, name } = data;

      setOrganizationData(id, name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgValue]);

  const loadData = async (accounts: AccountInterface, accountNumber: string): Promise<Data> => {
    let result: Data = {
      merchantGroups: [],
      merchants: [],
      counters: [],
    };

    const merchantGroups = await loadMerchantGroup({
      filter: {
        providerId: { $eq: Number(accounts.providerId) },
        accountNumber: { $eq: accountNumber },
      },
    });

    const merchants = EXTERNAL_SUPPLIER.includes(Number(accounts.providerId))
      ? await loadMerchant({
          filter: {
            providerId: { $eq: Number(accounts.providerId) },
            accountNumber: { $eq: accountNumber },
            merchantGroupId: { $in: merchantGroups.map(value => value.id) },
          },
          limit: 0,
        })
      : [];

    const counters = await loadCounters({
      filter: {
        [CounterKeys.PROVIDER_ID]: { $eq: Number(accounts.providerId) },
        [CounterKeys.ACCOUNT_NUMBER]: { $eq: accountNumber },
      },
    });

    result = { ...result, merchantGroups, merchants, counters };

    return result;
  };

  const clearErrors = useCallback(() => {
    setOrganizationError('');
    setAccountError('');
  }, []);

  const selectOrganization = useCallback(
    (value: OptionType | null): void => {
      clearErrors();
      setOrgValue(value);
    },
    [clearErrors],
  );

  const selectAccountNumber = useCallback(
    e => {
      clearErrors();
      setAccValue(e.target.value);
    },
    [setAccValue, clearErrors],
  );

  const loadAccount = (): void => {
    clearErrors();
    if (!accValue && !orgValue) {
      setOrganizationError('Выберите организацию');
      setAccountError('Введите лицевой счёт');
      return;
    }
    if (!orgValue) {
      setOrganizationError('Выберите организацию');
      return;
    }
    if (!accValue) {
      setAccountError('Введите лицевой счёт');
      return;
    }
    if (fromLanding) {
      setAccount(accValue);
      setOrgName(orgValue);
      history.push(FAST_PAYMENT);
    } else {
      toggleLoading?.(true);
      setAction?.(true);
      setAccountTrouble?.(FastPaymentAccountStatus.NOT_TROUBLE);
      setAccount(accValue);
      setOrgName(orgValue);

      checkPartners({
        filter: {
          ...(orgValue
            ? { [AccountKeys.ORGANIZATION_ID]: { $eq: Number(orgValue.value) } }
            : { [AccountKeys.PROVIDER_ID]: { $eq: Number(providerId) } }),
          [AccountKeys.ACCOUNT_NUMBER]: { $eq: accValue },
        },
      }).then(accounts => {
        if (!accounts.length) {
          setAccountTrouble?.(FastPaymentAccountStatus.NOT_FOUND);
          yM.goals.accountNotFound();
        } else if (!accounts[0].active && Number(accounts[0].balance) <= 0) {
          setAccountTrouble?.(FastPaymentAccountStatus.NOT_ACTIVE);
        } else {
          setAccValue(accValue);
          loadData(accounts[0], accValue)
            .then(result => {
              setMerchantGroups?.(result.merchantGroups);
              setMerchants?.(result.merchants);

              if (result.merchantGroups.length === 1) {
                setSingleMerchantGroup?.(result.merchantGroups[0]);
              }

              if (Number(accounts[0].balance) > 0) {
                setParentAccounts?.(accounts);
                setAccountTrouble?.(FastPaymentAccountStatus.NOT_TROUBLE);
              }
            })
            .finally(() => toggleLoading?.(false));
        }
      });
    }
  };

  useEffect(() => {
    if (!action && getAccount && getOrgName) {
      loadAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccount, getOrgName, action]);

  useEffect(() => {
    if (EXTERNAL_SUPPLIER.includes(Number(config.providerId))) {
      const name =
        Number(config.providerId) === EXTERNAL_SUPPLIER[0]
          ? 'АО Татэнергосбыт 1657082308'
          : 'ООО Центр-СБК 5254024741';

      loadOrg({
        filter: { [OrganizationKeys.NAME]: { $like: name } },
      }).then(res => {
        if (res.length) {
          const options = res.map(item => ({
            label: `${item.name} ИНН ${item.inn}`,
            value: item.id.toString(),
            data: item,
          }));
          setOrgValue(options[0]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const textFieldProps: TextFieldProps | undefined = {
    placeholder: 'Введите название или ИНН поставщика услуг',
    error: Boolean(organizationError),
    helperText: organizationError,
  };

  return (
    <Slide direction="left" in mountOnEnter unmountOnExit>
      <form
        className={classNames(classes.root, { [classes.rootShadow]: fromLanding })}
        onSubmit={(e: React.FormEvent): void => {
          e.preventDefault();
          setMerchantGroups?.([]);
          setMerchants?.([]);
          loadAccount();
        }}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          className={classNames(classes.wrapper, { [classes.borderHidden]: fromLanding })}
        >
          <Grid item className={classNames(classes.formItem, classes.executorWrapper)}>
            <OrganizationSearch
              className={classes.organizationInput}
              setValue={selectOrganization}
              value={orgValue}
              textFieldProps={{
                placeholder: 'Введите название или ИНН поставщика услуг',
                error: Boolean(organizationError),
                helperText: organizationError,
              }}
            />
          </Grid>

          <Hidden xsDown>
            <Divider orientation="vertical" className={classes.divider} />
          </Hidden>

          <Grid item className={classNames(classes.formItem, classes.accountWrapper)}>
            <TextField
              fullWidth
              className={classes.accountNumberInput}
              value={accValue}
              onChange={selectAccountNumber}
              placeholder="Лицевой счёт"
              error={Boolean(accountError)}
              helperText={accountError}
              tabIndex={2}
              InputProps={{
                endAdornment: (
                  <Button
                    onClick={(): void => setAccValue('')}
                    className={classNames(classes.inputClear, {
                      [classes.iconHidden]: !accValue,
                    })}
                  >
                    <ClearIcon />
                  </Button>
                ),
              }}
            />
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            className={classNames(classes.formItem, classes.buttonWrapper)}
          >
            {fromLanding ? (
              <Button
                fullWidth
                // onClick={loadAccount}
                variant="contained"
                color="secondary"
                className={classes.buttonNext}
                classes={{ label: classes.buttonLabel }}
                type={'submit'}
              >
                Перейти к оплате
              </Button>
            ) : (
              <Button
                fullWidth
                // onClick={loadAccount}
                variant="contained"
                color="primary"
                className={classes.button}
                tabIndex={3}
                type={'submit'}
              >
                Найти
              </Button>
            )}
          </Grid>

          {!fromLanding && isAllowed(BillingMethods.CLERK_ACCOUNT_INDEX) && (
            <Typography onClick={switchOrgSearch} className={classes.orgSwitcher}>
              Найти по адресу
            </Typography>
          )}
        </Grid>
      </form>
    </Slide>
  );
};

export default PaymentForm;
