/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

export const useFastPaymentStore = () => {
  const { fastPaymentStore } = useStores();

  return useObserver(() => ({
    getAccount: fastPaymentStore.accountNumber,
    setAccount: fastPaymentStore.setAccountNumber,
  }));
};

export const useCounterStore = () => {
  const { counterStore } = useStores();

  return useObserver(() => ({
    loadCounters: counterStore.load,
  }));
};

export const useMerchantGroupStore = () => {
  const { merchantGroupStore } = useStores();

  return useObserver(() => ({
    loadMerchantGroup: merchantGroupStore.load,
  }));
};

export const useMerchantStore = () => {
  const { merchantStore } = useStores();

  return useObserver(() => ({
    loadMerchant: merchantStore.load,
  }));
};

export const useAccountStore = () => {
  const { accountStore } = useStores();

  return useObserver(() => ({
    loadAccount: accountStore.load,
    partnerNotActive: accountStore.partnerNotActive,
    checkPartners: accountStore.checkPartners,
  }));
};

export const useSmevStore = () => {
  const { smevStore } = useStores();

  return useObserver(() => ({
    smevAccountListCleanUp: smevStore.smevAccountListCleanUp,
    setNotFirstFromSmev: smevStore.setNotFirstFromSmev,
  }));
};
