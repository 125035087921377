import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import cn from 'classnames';

import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import config from '@core/constants/config';
import { SIGN_UP } from '@public/constants/routes';
import { ORGANIZATION_SIGN_UP_LINK } from '@public/constants/organization';
import { isUsinsk } from '@core/constants/project';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: theme.spacing(44),

      [theme.breakpoints.down('xs')]: {
        width: theme.spacing(75),
      },
    },
    container: {
      display: 'flex',
      marginTop: theme.spacing(90),

      [theme.breakpoints.down(1240)]: {
        marginTop: theme.spacing(24),
      },
    },

    containerAfterFastPay: {
      marginTop: theme.spacing(24),

      [theme.breakpoints.down(1240)]: {
        marginTop: theme.spacing(16),
      },
    },

    content: {
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
      },
    },
    controlsContainer: {
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: theme.spacing(164),
      padding: `${theme.spacing(6, 10)} !important`,
      width: '100%',

      [theme.breakpoints.down('xs')]: {
        boxShadow: 'none !important',
      },
    },
    controlsTitle: {
      marginBottom: theme.spacing(10),

      [theme.breakpoints.down('md')]: {
        fontSize: '1.85rem !important',
      },
    },
    img: {
      width: '100%',
    },
    link: {
      textDecoration: 'none',
    },
    registrationBlockContainer: {
      [theme.breakpoints.down('md')]: {
        paddingTop: '50px',
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    senselessBlock: {
      opacity: 0,
      position: 'absolute',
    },
    textContainer: {
      '& > div:nth-child(n + 1)': {
        paddingTop: theme.spacing(4),
      },

      maxWidth: theme.spacing(174),
    },
    title: {
      fontWeight: 700,

      [theme.breakpoints.down('md')]: {
        fontSize: '1.85rem !important',
        textAlign: 'center',
      },
    },
  }),
);

interface AboutProps {
  id: string;
  beforeFastPay?: boolean;
}

const About: FC<AboutProps> = ({ id, beforeFastPay = false }) => {
  const classes = useStyles();

  const renderTextParagraph = (value: string): ReactElement => (
    <Grid item={true}>
      <Typography color="textPrimary" align="justify" component="p" variant={'body1'}>
        {value}
      </Typography>
    </Grid>
  );

  const titleElement: ReactElement = (
    <Grid item={true}>
      <Typography className={classes.title} color="textPrimary" variant="h2" align="center">
        О проекте «{config.projectName}»
      </Typography>
    </Grid>
  );

  const textElement: ReactElement = (
    <Grid className={classes.textContainer} container={true} item={true} direction="column">
      {renderTextParagraph(
        'Воспользовавшись системой, вы сможете заявить о любых неисправностях, возникших в вашей квартире, подъезде, доме или прилегающей территории.',
      )}
      {renderTextParagraph(
        '	Оставив заявку, вы получаете возможность отслеживать статус ее выполнения в режиме реального времени и быть в курсе проводимых работ.',
      )}
      {renderTextParagraph(
        '	Заявка может быть закрыта, только если качество выполненных работ вас полностью удовлетворяет, иначе ее исполнение будет возобновлено.',
      )}
    </Grid>
  );

  const registrationElement: ReactElement = (
    <Grid className={classes.registrationBlockContainer} container={true} item={true}>
      <Paper className={classes.controlsContainer}>
        <Typography
          className={classes.controlsTitle}
          align="center"
          color="textPrimary"
          variant="h2"
        >
          Регистрация
        </Typography>

        <Grid container={true} justify="space-around" spacing={4}>
          {!isUsinsk && (
            <Grid container={true} item={true} justify="center" md={6} sm={6} xs={12}>
              <Link
                id={`${id}__sign-up-citizen`}
                tabIndex={-1}
                className={classes.link}
                to={SIGN_UP}
              >
                <Button className={classes.button} color="primary" variant="contained">
                  Жителям
                </Button>
              </Link>
            </Grid>
          )}
          <Grid container={true} item={true} justify="center" md={6} sm={6} xs={12}>
            <a
              className={classes.link}
              id={`${id}__sign-up-organization`}
              tabIndex={-1}
              target="_blank"
              rel="noopener noreferrer"
              href={ORGANIZATION_SIGN_UP_LINK}
            >
              <Button className={classes.button} color="primary" variant="contained">
                Организациям
              </Button>
            </a>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );

  return (
    <Grid
      className={cn(classes.container, beforeFastPay && classes.containerAfterFastPay)}
      container={true}
      justify="space-between"
      component="section"
      id={id}
      data-testid="about-project"
    >
      <Hidden smDown={true}>
        <Grid item={true} md={6}>
          <img className={classes.img} alt="" src="/images/about_project.png" />
          <Hidden only={['xl', 'lg']}>{registrationElement}</Hidden>
        </Grid>
      </Hidden>
      <div className={classes.senselessBlock}>Спасибо! Молодцы!</div>
      <Grid
        className={classes.content}
        container={true}
        item={true}
        direction="column"
        alignItems="flex-start"
        justify="center"
        spacing={6}
        md={5}
      >
        {titleElement}
        {textElement}
        <Hidden only="md">{registrationElement}</Hidden>
      </Grid>
    </Grid>
  );
};

export default About;
