import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paymentBlock: {
      borderRadius: theme.spacing(4),
      position: 'relative',

      [theme.breakpoints.down('md')]: {
        boxShadow: 'none !important',
        height: 'auto',
      },
    },

    container: {
      height: '100%',
      flexWrap: 'nowrap',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },

    services: {
      flexGrow: 1,
      height: '100%',
      padding: '24px 24px 24px 32px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      marginRight: theme.spacing(6),

      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(4),
        padding: theme.spacing(4),
        marginRight: 0,
      },
    },

    scrollList: {
      height: 'calc(100% - 100px)',
      background: 'none',
      '@global': {
        '*::-webkit-scrollbar': {
          width: '0.4em',
          height: '10px',
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
          background: '#eceff1',
          borderRadius: '10px',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#c0cbd7',
          borderRadius: '10px',
          width: '0.2em',
          height: '10px',
        },
      },
    },

    listContainer: {
      height: '100%',
      overflowY: 'auto',
      background: 'none',
      paddingRight: '16px',
    },

    pay: {
      flexGrow: 0.4,
      height: '100%',
      background: '#FFFFFF',
      borderRadius: '8px',
      padding: '24px 32px',

      [theme.breakpoints.down('md')]: {
        borderRadius: theme.spacing(4),
        background: '#fff',
        padding: theme.spacing(4),
      },

      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },

      [theme.breakpoints.up('sm')]: {
        minWidth: '340px',
      },

      [theme.breakpoints.up('lg')]: {
        maxWidth: 700,
      },
    },

    payFull: {
      width: '100%',
      maxWidth: '100%',
    },

    paymentData: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',

      [theme.breakpoints.down('xs')]: {
        height: 'auto',
      },
    },

    organizationNameBlock: {
      display: 'flex',
      paddingBottom: '24px',
      flexWrap: 'wrap',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },

    organizationName: {
      fontWeight: 500,
      paddingRight: theme.spacing(4),
    },

    organizationDate: {
      fontWeight: 400,
      opacity: '0.8',
      backgroundColor: '#F0F2F5',
      borderRadius: '54px',
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,

      '&:first-letter': {
        textTransform: 'uppercase',
      },

      '@media (max-width: 400px)': {
        padding: `${theme.spacing(1 / 2)}px ${theme.spacing(2)}px`,
      },
    },

    moreButton: {
      marginRight: theme.spacing(2),
    },

    amountBlock: {
      marginBottom: '28px',
    },

    boldText: {
      fontWeight: 600,
    },

    payButton: {
      minWidth: '152px',
      marginLeft: theme.spacing(4),

      '@media (max-width: 750px)': {
        flexGrow: '0.6',
      },
    },

    backButton: {
      background: '#fff',
      display: 'none',
      marginTop: theme.spacing(2),

      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    merchantGroup: {
      paddingBottom: theme.spacing(4),
      boxSizing: 'border-box',
      width: '100%',
    },
    merchantGroupPublic: {
      paddingRight: 0,
    },
    merchantGroupItem: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: theme.spacing(4),
      padding: theme.spacing(6),

      '@media (max-width: 750px)': {
        flexDirection: 'column',
      },
    },
    merchantGroupItemPublic: {
      boxShadow: 'none !important',
      border: '1px solid rgba(7, 0, 61, 0.15)',
      boxSizing: 'border-box',
      borderRadius: 8,
      '@media (max-width: 1024px)': {
        flexDirection: 'column',
      },
    },
    dialogContent: {
      paddingTop: 0,
    },

    infoBlock: {
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottom: '1px solid rgba(7, 0, 61, 0.15)',
        paddingBottom: theme.spacing(2),
      },

      '& + &': {
        marginLeft: theme.spacing(14),

        [theme.breakpoints.down('sm')]: {
          marginLeft: 0,
          marginTop: theme.spacing(2),
        },
      },
    },

    infoBlockContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginRight: theme.spacing(5),

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginRight: theme.spacing(5),
      },

      [theme.breakpoints.down('xs')]: {
        marginBottom: 10,
      },

      '@media (max-width: 750px)': {
        marginRight: 0,
      },
    },

    infoBlockContainerPublic: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginRight: theme.spacing(0),
      },
    },

    infoBlockRootContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,
    },

    infoText: {
      fontSize: '15px',
      lineHeight: '24px',
      color: 'rgba(7, 0, 61, 0.5)',

      '& + &': {
        marginTop: theme.spacing(1),

        [theme.breakpoints.down('sm')]: {
          marginTop: 0,
          marginLeft: theme.spacing(2),
        },
      },
    },

    paymentInfoContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },

    detailsButton: {
      width: '136px',
      color: '#07003D',
      border: '1px solid rgba(7, 0, 61, 0.15)',
      fontWeight: 'normal',

      '@media (max-width: 750px)': {
        flexGrow: '0.4',
      },
    },

    sbpButtonPublic: {
      position: 'relative',
      minWidth: '216px',
      fontSize: 15,
      fontWeight: 400,
      lineHeight: '24px',
      color: '#07003D',
      padding: '2px',
      borderColor: 'rgba(0, 0, 0, 0.15)',

      '&:hover': {
        borderColor: 'rgba(7, 0, 61, 0.5)',
        backgroundColor: '#FFFFFF',
      },

      '@media (max-width: 750px)': {
        flexGrow: '1',
      },

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginBottom: theme.spacing(4),
        padding: '4px 2px',
      },
    },

    payButtonPublicWrapper: {
      display: 'flex',
      flexGrow: 1,
      maxWidth: '190px',
      marginLeft: '16px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        maxWidth: 'none',
        marginLeft: 0,
      },
    },

    payButtonPublic: {
      minWidth: '190px',
      marginLeft: theme.spacing(4),
      fontSize: 15,
      fontWeight: 500,
      lineHeight: '24px',
      padding: '8px 16px',

      '@media (max-width: 750px)': {
        minWidth: '240px',
      },

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginLeft: 0,
      },
    },

    detailsButtonPublicWrapper: {
      marginTop: '16px',
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
        padding: '4px 0',
      },
    },

    qrCodeButtonPublicWrapper: {
      display: 'flex',
      flexGrow: 1,
      maxWidth: '216px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        maxWidth: 'none',
      },
    },

    qrButton: {
      lineHeight: '32px',
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },

      [theme.breakpoints.up('sm')]: {
        border: '1px solid rgba(7, 0, 61, 0.15)',
        color: '#07003D',
      },
    },

    sbpButton: {
      lineHeight: '32px',
      marginTop: theme.spacing(2),
      color: '#07003D',
      border: '1px solid rgba(23, 16, 73, 0.15)',

      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },

    detailsButtonPublic: {
      width: 'auto',
      color: '#18CDA6',
      fontSize: 15,
      padding: '0px !important',
      fontWeight: 'normal',
    },

    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      '@media (min-width: 751px)': {
        width: 330,
      },
    },

    buttonPublicContainer: {
      '@media (min-width: 751px)': {
        width: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },

    paymentResultText: {
      fontSize: '15px',
      lineHeight: '24px',
      color: '#07003D',
      display: 'none',

      '@media (max-width: 750px)': {
        display: 'flex',
      },
    },

    paymentResultTextPublic: {
      '@media (max-width: 1024px)': {
        display: 'flex',
      },
    },

    paymentResultContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flex: 1,

      '@media (max-width: 750px)': {
        justifyContent: 'space-between',
      },
    },

    paymentResultContainerPublic: {
      '@media (max-width: 1024px)': {
        justifyContent: 'space-between',
      },
    },

    detailsPaymentInfo: {
      borderBottom: '1px dashed rgba(7, 0, 61, 0.2)',
      padding: '8px 0',
      justifyContent: 'space-between',
      alignItems: 'center',

      '&:first-child': {
        borderTop: '1px dashed rgba(7, 0, 61, 0.2)',
      },
    },

    detailsInfoText: {
      marginTop: 0,
    },

    intPart: {
      color: '#07003D',
    },
    feesTooltip: {
      fontSize: '15px',
      backgroundColor: 'rgb(22 41 53 / 90%)',
      borderRadius: theme.spacing(2),
      padding: theme.spacing(4),
    },
    feesArrow: {
      color: 'rgb(22 41 53 / 90%)',
    },
    link: {
      display: 'block',
      color: theme.palette.primary.main,
      marginTop: theme.spacing(3),
      cursor: 'pointer',
    },

    sbpImg: {
      width: 60,
    },
    sbpText: { marginLeft: '20px', fontWeight: 400, fontSize: '15px', lineHeight: '24px' },

    sbpImgPublic: {
      width: 51,
      height: 'auto',
      marginTop: 2,
      '@media (max-width: 420px)': {
        width: 68,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: 0,
        paddingLeft: 4,
        margin: 0,
      },
    },
    sbpTextPublic: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: '24px',
      color: '#07003D',
      '@media (max-width: 420px)': {
        paddingLeft: 6,
      },
    },
  }),
);
