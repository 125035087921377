import React, { FC, useEffect, useState, useRef, ReactElement, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
  DialogContent,
  Typography,
  makeStyles,
  TextField,
  Button,
} from '@material-ui/core';
import { useFormik } from 'formik';
import cn from 'classnames';
import { CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { usePaymentStore, useOperationStore, useOwnProfileStore } from './hooks';
import { FAST_PAYMENT } from '@public/constants/routes';
import OpencityMethods from '@services/opencity/methods';
// import { yM } from '@core/utils/yandexMetrika/yandexMetrika';
import { FormSchema } from './FormSchema';

interface QrPaymentModalProps {
  open: boolean;
  providerId: number;
  accountNumber: string;
  close: () => void;
}

const useStyles = makeStyles(theme => ({
  qrCode: {
    width: '200px',
    height: '200px',
    borderRadius: theme.spacing(1),
    position: 'relative',
    margin: '0 auto',
    backgroundSize: 'contain !important',
  },
  title: {
    fontWeight: 500,
  },
  row: {
    marginBottom: theme.spacing(8),
    width: '100%',
  },
  loaderContainer: {
    width: '50px',
    height: '50px',
    background: theme.palette.primary.main,
    borderRadius: '50px',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorContainer: {
    width: '50px',
    height: '50px',
    background: 'red',
    borderRadius: '50px',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: '#fff',
    width: '30px !important',
    height: '30px !important',
  },

  textField: {
    '& + &': {
      marginTop: '20px',
    },
  },

  buttonContainer: {
    justifyContent: 'flex-end',
    marginLeft: 'auto',
  },

  closeButton: {
    border: '1px solid rgba(7, 0, 61, 0.15)',
    color: '#07003D',
    fontSize: '15px',
    flex: 2,
  },

  sendButton: {
    fontSize: '15px',
    whiteSpace: 'nowrap',
    flex: 3,
    marginLeft: theme.spacing(4),
  },

  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 8,
    marginBottom: 32,
  },

  successIcon: {
    width: 80,
    height: 80,
  },

  successfulText: {
    paddingBottom: 24,
    color: '#44546B',
  },

  tryLater: {
    marginTop: 18,
    marginLeft: 0,
  },

  dialogContent: {
    paddingBottom: '35px',
  },

  textFieldLabel: {
    color: 'rgba(7, 0, 61, 0.5)',
  },

  resultImage: {
    maxWidth: '100%',
  },

  resultModalContent: {
    [theme.breakpoints.down('xs')]: {
      flexGrow: 0,
    },
  },
  caption: {
    textAlign: 'center',
    lineHeight: '24px',
  },

  lastRow: {
    marginBottom: 0,
  },
}));

import './style.css';
import config from '@core/constants/config';
import { isBilling } from '@core/constants/project';

// const {
//   Ya: { share2 },
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
// } = window as any;

const QrPaymentModal: FC<QrPaymentModalProps> = ({ open, providerId, accountNumber, close }) => {
  const classes = useStyles();
  const { userEmail } = useOwnProfileStore();
  // const shareElement = useRef(null);
  const [loading, setLoading] = useState(true);
  const [qrLink, setLink] = useState('');
  const [qrError, setError] = useState('');
  const [isQrLoading, setQrLoading] = useState(false);
  const [isQrSent, setQrSent] = useState(false);
  const [isSendingSuccessful, setSendingSuccessful] = useState(false);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const { createQrCode, sendQrOnEmail } = usePaymentStore();
  const { isAllowed } = useOperationStore();

  const sendQr = useCallback(
    values => {
      setQrLoading(true);
      sendQrOnEmail({ accountNumber, providerId, email: values.email })
        .then(res => {
          if (!res.error) {
            setSendingSuccessful(true);
            // yM.goals.sendQrToEmail();
          } else {
            setSendingSuccessful(false);
            // yM.goals.sendQrToEmailError();
          }
        })
        .finally(() => {
          setQrLoading(false);
          setQrSent(true);
        });
    },
    [accountNumber, sendQrOnEmail],
  );

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      email: userEmail,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: sendQr,
    validationSchema: FormSchema,
  });

  const loadQrCode = async (): Promise<void> => {
    const { link, error } = await createQrCode({ providerId, accountNumber });

    if (error) {
      setError(error);
    }

    if (link) {
      setLink(link);
    }
  };

  useEffect(() => {
    if (open) {
      loadQrCode().finally(() => {
        setLoading(false);
      });
    } else {
      setLink('');
      setError('');
    }
  }, [open]);

  // useEffect(() => {
  //   if (shareElement.current && qrLink) {
  //     share2(shareElement.current, {
  //       contentByService: {
  //         vkontakte: {
  //           url: qrLink,
  //           title: config.projectName,
  //           description: config.projectDescription,
  //           image: qrLink,
  //         },
  //         facebook: {
  //           url: qrLink,
  //           title: config.projectName,
  //           description: config.projectDescription,
  //           image: qrLink,
  //         },
  //         odnoklassniki: {
  //           url: qrLink,
  //           title: config.projectName,
  //           description: config.projectDescription,
  //           image: qrLink,
  //         },
  //         telegram: {
  //           url: qrLink,
  //           title: config.projectName,
  //           description: config.projectDescription,
  //           image: qrLink,
  //         },
  //         viber: {
  //           url: qrLink,
  //           title: config.projectName,
  //           description: config.projectDescription,
  //           image: qrLink,
  //         },
  //         whatsapp: {
  //           url: qrLink,
  //           title: config.projectName,
  //           description: config.projectDescription,
  //           image: qrLink,
  //         },
  //       },
  //       theme: {
  //         services: 'vkontakte,facebook,odnoklassniki,telegram,viber,whatsapp',
  //         curtain: true,
  //         popupDirection: 'top',
  //         shape: 'round',
  //         size: 'l',
  //       },
  //     });
  //   }
  // }, [shareElement, qrLink]);

  const Loader: ReactElement = (
    <div className={classes.loaderContainer}>
      <CircularProgress className={classes.icon} />
    </div>
  );

  const Error: ReactElement = (
    <div className={classes.errorContainer}>
      <CloseIcon className={classes.icon} />
    </div>
  );

  const QrIcon: ReactElement | null = loading ? Loader : qrError ? Error : null;

  const QrCodeImage: ReactElement = (
    <div className={classes.qrCode} style={{ background: `url("${qrLink}"), #F0F2F5` }}>
      {QrIcon}
    </div>
  );

  // const SocialSharing: ReactElement = (
  //   <div>
  //     <div id="ya-share2" ref={shareElement} />
  //   </div>
  // );

  if (isQrSent) {
    return (
      <Dialog
        open={open}
        onClose={(): void => {
          setSendingSuccessful(false);
          setQrSent(false);
          close();
        }}
        maxWidth={'sm'}
        fullScreen={isXs}
        fullWidth={true}
      >
        <DialogTitle>
          <Grid container={true} alignItems={'center'} justify={'space-between'}>
            <Grid item={true}>Получение QR-кода</Grid>
            <Grid item={true}>
              <IconButton
                onClick={(): void => {
                  setSendingSuccessful(false);
                  setQrSent(false);
                  close();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.resultModalContent}>
          <Grid className={classes.iconContainer}>
            <img
              className={classes.resultImage}
              src={
                isSendingSuccessful ? '/images/qr_email_success.png' : '/images/qr_email_fail.png'
              }
              alt="result"
            />
          </Grid>
          <Typography className={classes.successfulText}>
            {isSendingSuccessful ? (
              <>
                Мы отправили qr-код для оплаты коммунальных услуг на ваш email адрес{' '}
                <strong>{values.email}</strong>
                <Button
                  className={cn(classes.sendButton, classes.tryLater)}
                  color="primary"
                  variant="contained"
                  onClick={(): void => {
                    setSendingSuccessful(false);
                    setQrSent(false);
                    close();
                  }}
                  fullWidth
                >
                  Закрыть
                </Button>
              </>
            ) : (
              <>
                Нам не удалось отправить письмо на ваш email. Сообщите нам об этом или повторите
                попытку позднее
                <Button
                  className={cn(classes.sendButton, classes.tryLater)}
                  color="primary"
                  variant="contained"
                  onClick={(): void => {
                    setSendingSuccessful(false);
                    setQrSent(false);
                    close();
                  }}
                  fullWidth
                >
                  Повторить позже
                </Button>
              </>
            )}
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={close} maxWidth={'sm'} fullScreen={isXs} fullWidth={true}>
      <DialogTitle>
        <Grid container={true} alignItems={'center'} justify={'space-between'}>
          <Grid item={true}>Получение QR-кода</Grid>
          <Grid item={true}>
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form onSubmit={handleSubmit}>
          <Grid container={true} direction={'column'}>
            <Grid item={true} className={classes.row}>
              <Typography className={classes.title}>QR-код для оплаты ЖКУ</Typography>
            </Grid>
            <Grid item={true} className={classes.row}>
              {QrCodeImage}
            </Grid>
            <Grid item={true} className={classes.row}>
              <Typography variant={'body2'} className={classes.caption}>
                Этот код можно сканировать с помощью специального приложения или камеры на телефоне
                (если такая возможность поддерживается).
                <br />
                {`Код предназначен для совершения платежа в сервисе "${config.projectName}".`}
              </Typography>
            </Grid>
            <Grid item={true} className={cn(classes.row, !isBilling && classes.lastRow)}>
              {isBilling && (
                <>
                  <Typography className={classes.textFieldLabel} variant="body2">
                    Отправить на email
                  </Typography>
                  <TextField
                    InputLabelProps={{
                      className: classes.textFieldLabel,
                    }}
                    className={classes.textField}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={(touched.email && errors.email) || ' '}
                    error={Boolean(touched.email && errors.email)}
                    name="email"
                    disabled={isQrLoading}
                    fullWidth
                  />
                </>
              )}

              {!isAllowed(OpencityMethods.PROFILE_ACCOUNT_CREATE) && (
                <Grid item={true} className={classes.row}>
                  <Typography className={classes.title}>Поделиться</Typography>
                </Grid>
              )}

              {!isAllowed(OpencityMethods.PROFILE_ACCOUNT_CREATE) && (
                <TextField
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                  className={classes.textField}
                  label={'Ссылка на счёт для оплаты'}
                  value={`${window.origin}${FAST_PAYMENT}?accountNumber=${accountNumber}&providerId=${providerId}`}
                  onClick={(): void => {
                    window.navigator.clipboard.writeText(
                      `${window.origin}${FAST_PAYMENT}?accountNumber=${accountNumber}&providerId=${providerId}`,
                    );
                  }}
                  fullWidth
                />
              )}
            </Grid>
            {/*<Grid item={true} className={classes.row}>*/}
            {/*  {SocialSharing}*/}
            {/*</Grid>*/}
          </Grid>

          {isBilling && (
            <Grid className={classes.buttonContainer} container>
              <Button className={cn(classes.closeButton)} onClick={close}>
                Закрыть
              </Button>
              <Button
                className={cn(classes.sendButton)}
                color="primary"
                variant="contained"
                type="submit"
              >
                Отправить на email
              </Button>
            </Grid>
          )}
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default QrPaymentModal;
