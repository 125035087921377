import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
  useMemo,
  ReactNode,
} from 'react';
import {
  Paper,
  Grid,
  Button,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Fade,
  Hidden,
  Tooltip,
  Link,
} from '@material-ui/core';
import cn from 'classnames';
import ClearIcon from '@material-ui/icons/Clear';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';

import { Account } from '@entities/Billing/Account';
import { Merchant } from '@entities/Billing/Merchant';
import { Service } from '@entities/Billing/Service';
import { useAccountStore, useServiceStore } from '@private/pages/Payment/hooks';
import { MerchantGroup } from '@entities/Billing/MerchantGroup';
import MerchantServices from './MerchantServices';
import PaymentFormDialog from './PaymentFormDialog';
import PaymentData from './PaymentData';

import Preloader from 'oc-preloader/lib';
import { moneyFormat } from '@utils/formatter';
import moment from 'moment';
import { yM } from '@core/utils/yandexMetrika/yandexMetrika';
import DetailsModal from './DetailsModal';
import { PenaltyTooltip } from '@core/components/PenaltyTooltip';
import { PaymentTooltip } from '@core/components/PaymentTooltip';
import QrPaymentModal from '@common/components/QrPaymentModal';
import { PaymentAndCountersFormDialog } from '@core/components';
import config from '@core/constants/config';
import { useOwnProfileStore } from '../hooks';
import { PaymentDetails } from '@private/components';
import { isSpecialBilling, isBilling } from '@core/constants/project';
import useBankCommissionList from '@core/utils/useBankCommissionList';
import { AccountStatus } from '@core/entities/Billing/Account';
import { useMerchantGroupStore } from './hooks';
import QrPaymentWindow from '../QrPaymentWindow/QrPaymentWindow';

interface PaymentBlockProps {
  accounts?: Account[];
  accountNumber: Account['number'];
  accountType: Account['type'];
  selectedSingleMerchantGroup: MerchantGroup | null;
  setSingleMerchantGroup: (merchantGroup: MerchantGroup | null) => void;
  cleanUp?(): void;
  merchantGroups: MerchantGroup[];
  merchants?: Merchant[];
  services?: Service[];
  loading?: boolean;
  fromPublic?: boolean;
  paymentSource?: number;
  isPrepaymentOpen?: boolean;
  setOpenPrepayment?: React.Dispatch<React.SetStateAction<boolean>>;
}

const PAYMENT_CONTENT = 'payment_content';

const getLastPayedValue = (value: number | null | undefined): [string, string] => {
  const [intPart, decimalPart] = moneyFormat(Number(value)).split(',');

  return [intPart, decimalPart];
};

const PaymentBlock: FC<PaymentBlockProps> = ({
  accounts,
  accountNumber,
  accountType,
  selectedSingleMerchantGroup,
  setSingleMerchantGroup,
  cleanUp,
  merchantGroups,
  merchants = [],
  services = [],
  loading,
  fromPublic = false,
  paymentSource,
  isPrepaymentOpen,
  setOpenPrepayment,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { externalSupplier, selectedBillingUser, selectBillingUser } = useOwnProfileStore();
  const { partnerNotActive } = useAccountStore();

  const accountIsClosed = selectedBillingUser?.account?.active === AccountStatus.CLOSED;

  const paymentSourceByPage = fromPublic
    ? config.fastPaymentActionSource
    : config.privateActionSource;

  const hasOverPayment = (merchantGroup: MerchantGroup | null): boolean => {
    return !!(merchantGroup && merchantGroup.balance && merchantGroup.balance < 0);
  };

  const defaultPrevMonth = moment()
    .subtract(1, 'months')
    .format('MMMM YYYY');
  const defaultCurrentMothFirstDay = moment()
    .date(1)
    .format('D MMMM YYYY');

  const url = new URL(window.location.href);
  const accountNumberGetParam = url.searchParams.get('accountNumber');
  const providerIdGetParam = url.searchParams.get('providerId');

  const [selectedMerchantGroup, setSelectedMerchantGroup] = useState<MerchantGroup | null>(null);
  const [paymentFormDialogIsOpen, setPaymentFormDialogIsOpen] = useState(false);
  const [paymentAndCountersFormDialogIsOpen, setPaymentAndCountersFormDialogIsOpen] = useState(
    false,
  );
  const [servicesDialogIsOpen, setServicesDialogIsOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [penaltyTooltip, togglePenaltyTooltip] = useState(true);
  const [qrModalVisibility, toggleQrModalVisibility] = useState(false);
  const [qrForMerchant, setQrForMerchant] = useState<MerchantGroup | null>(null);

  const [isSbpEnabled, setIsSbpEnabled] = useState(false);
  const [qrPaymentWindowOpen, setQrPaymentWindowOpen] = useState(false);

  const setQrPaymentWindowClose = () => setQrPaymentWindowOpen(false);

  const getSbpEnabled = (selectedSingleMerchantGroup: MerchantGroup | null): boolean => {
    let sbpEnabled = false;

    if (selectedSingleMerchantGroup && selectedSingleMerchantGroup.bankCommissionList?.length) {
      const amount = selectedSingleMerchantGroup.balance ? selectedSingleMerchantGroup.balance : 0;

      selectedSingleMerchantGroup.bankCommissionList.forEach(item => {
        if (amount >= Number(item?.paymentMin) && amount <= Number(item?.paymentMax)) {
          if (item?.sbpEnabled) {
            sbpEnabled = true;
          }
        }
      });
    }

    return sbpEnabled;
  };

  const renderQrModal = (): ReactElement | null => {
    const account = accountNumber || accountNumberGetParam;
    const provider =
      qrForMerchant?.providerId || selectedSingleMerchantGroup?.providerId || providerIdGetParam;

    if (!account || !provider) return null;

    return (
      <QrPaymentModal
        open={qrModalVisibility}
        accountNumber={account}
        providerId={Number(provider)}
        close={(): void => {
          toggleQrModalVisibility(false);
          setQrForMerchant(null);
        }}
      />
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const feesTooltipEvent = (event: any): void => {
    if (
      event.target &&
      event.target.classList &&
      !event.target.classList.contains(classes.feesTooltip)
    ) {
      togglePenaltyTooltip(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('click', feesTooltipEvent);
    }, 500);

    return (): void => {
      window.removeEventListener('click', feesTooltipEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsSbpEnabled(getSbpEnabled(selectedSingleMerchantGroup));
  }, [selectedSingleMerchantGroup]);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        togglePenaltyTooltip(true);
      }, 500);
    }
  }, [loading]);

  const { listService } = useServiceStore();

  const loaderPadding = useMemo(() => {
    if (fromPublic) {
      return `100px`;
    } else {
      return `200px`;
    }
  }, [fromPublic]);

  const handleMoreDetails = useCallback(
    merchantGroup => {
      if (fromPublic) {
        setSelectedMerchantGroup(merchantGroup);
        setDetailsModalOpen(true);
      } else {
        setSingleMerchantGroup(merchantGroup);
      }
    },
    [fromPublic, setSingleMerchantGroup],
  );

  const handleCloseDetailsModal = useCallback(() => {
    setDetailsModalOpen(false);
  }, []);

  const handleOpenPaymentFormDialog = (merchantGroup: MerchantGroup): void => {
    if (fromPublic) {
      yM.goals.buttonPayFast();
    } else {
      yM.goals.buttonPay();
    }
    setSelectedMerchantGroup(merchantGroup);
    setPaymentFormDialogIsOpen(true);
  };

  const handleOpenPaymentAndCountersFormDialog = (merchantGroup: MerchantGroup): void => {
    setSelectedMerchantGroup(merchantGroup);
    setPaymentAndCountersFormDialogIsOpen(true);
  };

  const handleClosePaymentFormDialog = (): void => {
    setPaymentFormDialogIsOpen(false);
    setSelectedMerchantGroup(null);
    setOpenPrepayment?.(false);
  };

  const handleClosePaymentAndCountersFormDialog = (): void => {
    setPaymentAndCountersFormDialogIsOpen(false);
    setSelectedMerchantGroup(null);
  };

  const handleCloseOpenServicesDialog = (): void => setServicesDialogIsOpen(false);

  const FeesTooltip: FC<{ merchantGroup: MerchantGroup }> = ({ merchantGroup }): ReactElement => (
    <Grid container={true} direction={'column'}>
      <Grid item={true}>У вас сформировались пени.</Grid>
      <Grid item={true}>Поторопитесь оплатить услуги.</Grid>
      <Grid item={true}>
        <Link
          onClick={(): void => {
            handleOpenPaymentFormDialog(merchantGroup);
          }}
          className={classes.link}
        >
          Оплатить онлайн
        </Link>
      </Grid>
    </Grid>
  );

  useEffect(() => {
    if (!isPrepaymentOpen) {
      return;
    }

    handleOpenPaymentFormDialog(
      selectedSingleMerchantGroup || selectedMerchantGroup || merchantGroups[0],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrepaymentOpen]);

  useEffect(() => {
    return cleanUp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!fromPublic || loading) return;
    if (merchantGroups.length) {
      const paymentContent = document.getElementById('register_checkbox');
      if (paymentContent && merchantGroups.length) {
        setTimeout(() => {
          const { offsetTop } = paymentContent;
          window.scrollTo({ top: offsetTop - 50, behavior: 'smooth' });
        }, 300);
      }
    }
  }, [loading, fromPublic, merchantGroups]);

  const renderPaymentFormDialog: ReactNode = selectedMerchantGroup && !fromPublic && (
    <PaymentFormDialog
      accountIsClosed={accountIsClosed}
      accountNumber={accountNumber}
      open={paymentFormDialogIsOpen}
      merchantGroup={selectedMerchantGroup}
      onClose={handleClosePaymentFormDialog}
      paymentSource={paymentSource || paymentSourceByPage}
    />
  );

  const renderPaymentAndCountersFormDialog = selectedMerchantGroup && fromPublic && (
    <PaymentAndCountersFormDialog
      accounts={accounts}
      isVisibile={paymentAndCountersFormDialogIsOpen}
      merchantGroup={selectedMerchantGroup}
      merchants={merchants}
      accountNumber={accountNumber}
      onClose={handleClosePaymentAndCountersFormDialog}
      paymentSource={paymentSource || paymentSourceByPage}
    />
  );

  const renderDetailsModal: ReactElement | null = selectedMerchantGroup && (
    <DetailsModal
      modalVisibility={detailsModalOpen}
      closeModal={handleCloseDetailsModal}
      data={selectedMerchantGroup}
    />
  );

  const renderSingleMerchantGroup = (merchantGroup: MerchantGroup): ReactElement => {
    const {
      bankCommission,
      penalty,
      balance,
      balanceByService,
      balanceDate,
      id,
      bankCommissionIsUpper,
      invoiceFileUrl,
      lastPayedDate,
      docList,
    } = merchantGroup;

    const { getCommission, getPaymentButtonName } = useBankCommissionList(merchantGroup);

    const namePaymentButton = bankCommissionIsUpper ? 'Оплатить' : 'Оплатить без комиссии';

    const currentFullDate = balanceDate
      ? moment(balanceDate, 'X').format('D MMMM YYYY')
      : defaultCurrentMothFirstDay;

    const servicesDialog = (merchantGroup: MerchantGroup): ReactElement => (
      <Dialog open={servicesDialogIsOpen} fullScreen={isXs} onClose={handleCloseOpenServicesDialog}>
        <DialogTitle>
          <Grid container alignItems={'center'} justify={'space-between'}>
            <Grid item>{merchantGroup.name}</Grid>
            <Grid item>
              <IconButton onClick={handleCloseOpenServicesDialog}>
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Box minHeight={250}>
            <MerchantServices
              merchantGroup={merchantGroup}
              merchants={merchants.filter(value => value.groupId === id)}
              services={services}
            />
          </Box>
        </DialogContent>
      </Dialog>
    );

    const lastPayedValue = getLastPayedValue(merchantGroup.currentMonthPayed);
    const lastBalance = getLastPayedValue(balanceByService);
    const fees = getLastPayedValue(penalty);

    return (
      <Fade in timeout={500}>
        <Grid id={PAYMENT_CONTENT} className={classes.paymentBlock}>
          <Grid container className={classes.container}>
            {!externalSupplier && (
              <Grid className={classes.services} item>
                <PaymentDetails
                  type={accountType}
                  invoiceFileUrl={invoiceFileUrl}
                  docList={docList}
                />
                <ScopedCssBaseline className={classes.scrollList}>
                  <div className={classes.listContainer}>
                    <MerchantServices
                      merchantGroup={merchantGroup}
                      merchants={merchants.filter(value => value.groupId === id)}
                      services={listService}
                    />
                  </div>
                </ScopedCssBaseline>
              </Grid>
            )}
            <Grid className={cn(classes.pay, externalSupplier && classes.payFull)} item>
              <Grid container className={classes.paymentData}>
                <Grid item container direction={'column'}>
                  <PaymentData merchantGroup={merchantGroup} />
                </Grid>
                <Grid item container direction={'column'}>
                  <Grid item container className={classes.detailsPaymentInfo}>
                    <Typography className={classes.infoText} variant={'body2'}>
                      По услугам на {currentFullDate}
                    </Typography>
                    <Typography
                      className={cn(classes.infoText, classes.detailsInfoText)}
                      variant={'body2'}
                    >
                      <span className={classes.intPart}>{lastBalance[0]}</span>
                      {lastBalance[1] && `,${lastBalance[1]}`}
                    </Typography>
                  </Grid>
                  <Grid item container className={classes.detailsPaymentInfo}>
                    <PenaltyTooltip className={classes.infoText} />

                    <Tooltip
                      title={<FeesTooltip merchantGroup={merchantGroup} />}
                      arrow={true}
                      open={Boolean(
                        !isSpecialBilling &&
                          penaltyTooltip &&
                          merchantGroup.penalty &&
                          merchantGroup.penalty > 0,
                      )}
                      placement={'left'}
                      classes={{ tooltip: classes.feesTooltip, arrow: classes.feesArrow }}
                      interactive={true}
                    >
                      <Typography
                        className={cn(classes.infoText, classes.detailsInfoText)}
                        variant={'body2'}
                      >
                        <span className={classes.intPart}>{fees[0]}</span>
                        {fees[1] && `,${fees[1]}`}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  {!isSpecialBilling && balance && balance > 0 ? (
                    <Grid item container className={classes.detailsPaymentInfo}>
                      <Typography className={classes.infoText} variant={'body2'}>
                        Комиссия
                      </Typography>
                      <Typography
                        className={cn(classes.infoText, classes.detailsInfoText)}
                        variant={'body2'}
                      >
                        {getCommission(balance)}
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {hasOverPayment(merchantGroup) && (
                    <Grid item container className={classes.detailsPaymentInfo}>
                      <Typography className={classes.infoText} variant={'body2'}>
                        Переплата
                      </Typography>
                      <Typography
                        className={cn(classes.infoText, classes.detailsInfoText)}
                        variant={'body2'}
                      >
                        {moneyFormat(Number(balance) * -1)}
                      </Typography>
                    </Grid>
                  )}

                  {lastPayedDate && !fromPublic && (
                    <Grid item container className={classes.detailsPaymentInfo}>
                      <Typography variant={'body2'}>
                        Оплачено на {moment(lastPayedDate, 'X').format('D MMMM YYYY')}
                      </Typography>
                      <Typography className={classes.infoText} variant={'body2'}>
                        <span className={classes.intPart}>{lastPayedValue[0]}</span>
                        {lastPayedValue[1] && `,${lastPayedValue[1]}`}
                      </Typography>
                    </Grid>
                  )}

                  <Grid
                    item
                    container
                    justify={'space-between'}
                    alignItems="center"
                    className={classes.amountBlock}
                  >
                    <Typography
                      variant={'body2'}
                      className={classes.boldText}
                      color={'textPrimary'}
                    >
                      Итого к оплате
                    </Typography>
                    <Typography variant={'h6'} className={classes.boldText} color={'textPrimary'}>
                      {hasOverPayment(merchantGroup)
                        ? moneyFormat(0)
                        : moneyFormat(Number(balance))}
                    </Typography>
                  </Grid>
                  <Grid item container>
                    <Hidden smUp>
                      {!externalSupplier && !isSpecialBilling && (
                        <PaymentTooltip placement="top-start" upper />
                      )}
                    </Hidden>

                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      onClick={(): void => handleOpenPaymentFormDialog(merchantGroup)}
                    >
                      {getPaymentButtonName(balance!)}
                    </Button>

                    {!isSpecialBilling && isSbpEnabled && Number(merchantGroup.balance) > 0 && (
                      <Button
                        color="primary"
                        variant="outlined"
                        fullWidth
                        className={classes.sbpButton}
                        onClick={(): void => {
                          setQrPaymentWindowOpen(true);
                          yM.goals.buttonQr();
                        }}
                      >
                        <Grid container={true} alignItems={'center'} justify={'center'}>
                          <img className={classes.sbpImg} alt={'SBP'} src="/images/sbp.png" />

                          <span className={classes.sbpText}>Оплатить через СБП</span>
                        </Grid>
                      </Button>
                    )}

                    {!isSpecialBilling && !isSbpEnabled && (
                      <Button
                        color="primary"
                        variant="outlined"
                        fullWidth
                        className={classes.qrButton}
                        onClick={(): void => {
                          // setSelectedMerchantGroup(merchantGroup);
                          setQrForMerchant(merchantGroup);
                          toggleQrModalVisibility(true);
                          yM.goals.buttonQr();
                        }}
                      >
                        QR для оплаты
                      </Button>
                    )}

                    <Hidden xsDown>
                      {!externalSupplier && !isSpecialBilling && <PaymentTooltip />}
                    </Hidden>

                    {merchantGroups.length > 1 && selectedSingleMerchantGroup && (
                      <Button
                        color="primary"
                        variant="outlined"
                        fullWidth
                        onClick={(): void => setSingleMerchantGroup(null)}
                        className={classes.backButton}
                      >
                        Вернуться
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {servicesDialog(merchantGroup)}
        </Grid>
      </Fade>
    );
  };

  const renderMerchantGroups = (merchantGroups: MerchantGroup[]): ReactElement => {
    const renderMerchantGroup = (merchantGroup: MerchantGroup, index: number): ReactElement => {
      const lastPayedValue = getLastPayedValue(merchantGroup.currentMonthPayed);
      const lastBalance = getLastPayedValue(merchantGroup.balanceByService);
      const fees = getLastPayedValue(merchantGroup.penalty);

      const { getCommission } = useBankCommissionList(merchantGroup);

      return (
        <Fade in timeout={500} key={index}>
          <Grid
            id={index === 0 && isSm ? PAYMENT_CONTENT : undefined}
            item
            className={cn(classes.merchantGroup, { [classes.merchantGroupPublic]: fromPublic })}
            style={{ paddingTop: index === 0 && isSm && fromPublic ? '30px' : '0' }}
          >
            <Paper
              className={cn(classes.merchantGroupItem, {
                [classes.merchantGroupItemPublic]: fromPublic,
              })}
            >
              <Grid className={classes.infoBlockRootContainer}>
                <Grid item className={classes.organizationNameBlock}>
                  <Typography className={classes.organizationName}>{merchantGroup.name}</Typography>
                  <Typography className={classes.organizationDate} variant={'caption'}>
                    {merchantGroup.balanceDate
                      ? moment(merchantGroup.balanceDate, 'X')
                          .subtract(1, 'months')
                          .format('MMMM YYYY')
                      : defaultPrevMonth}
                  </Typography>
                  {fromPublic && (
                    <Hidden smUp>
                      <Grid item className={classes.detailsButtonPublicWrapper}>
                        <Button
                          variant="text"
                          className={classes.detailsButtonPublic}
                          onClick={(): void => handleMoreDetails(merchantGroup)}
                        >
                          <Typography variant="body2" align="center">
                            Подробнее
                          </Typography>
                        </Button>
                      </Grid>
                    </Hidden>
                  )}
                </Grid>
                <Grid item>
                  <Grid
                    className={cn(classes.infoBlockContainer, {
                      [classes.infoBlockContainerPublic]: fromPublic,
                    })}
                  >
                    <Grid item className={classes.infoBlock}>
                      <Typography variant={'body2'} className={classes.infoText}>
                        По услугам на{' '}
                        {merchantGroup.balanceDate
                          ? moment(merchantGroup.balanceDate, 'X').format('D MMMM YYYY')
                          : defaultCurrentMothFirstDay}
                        :
                      </Typography>
                      <Typography className={classes.infoText}>
                        <span className={classes.intPart}>{lastBalance[0]}</span>
                        {lastBalance[1] && `,${lastBalance[1]}`}
                      </Typography>
                    </Grid>

                    <Grid item className={classes.infoBlock}>
                      <PenaltyTooltip className={classes.infoText} />
                      <Tooltip
                        title={<FeesTooltip merchantGroup={merchantGroup} />}
                        arrow={true}
                        open={Boolean(
                          !isSpecialBilling &&
                            penaltyTooltip &&
                            merchantGroup.penalty &&
                            merchantGroup.penalty > 0,
                        )}
                        placement={'left'}
                        classes={{ tooltip: classes.feesTooltip, arrow: classes.feesArrow }}
                        interactive={true}
                      >
                        <Typography className={classes.infoText}>
                          <Grid container wrap="nowrap">
                            <span className={classes.intPart}>{fees[0]}</span>
                            {fees[1] && `,${fees[1]}`}
                          </Grid>
                        </Typography>
                      </Tooltip>
                    </Grid>
                    {!isSpecialBilling &&
                    merchantGroup &&
                    merchantGroup.balance &&
                    merchantGroup.balance > 0 ? (
                      <Grid item className={classes.infoBlock}>
                        <Typography variant={'body2'} className={classes.infoText}>
                          Комиссия:
                        </Typography>
                        <Typography className={classes.infoText}>
                          {getCommission(merchantGroup.balance)}
                        </Typography>
                      </Grid>
                    ) : (
                      <></>
                    )}

                    {hasOverPayment(merchantGroup) && (
                      <Grid item className={classes.infoBlock}>
                        <Typography variant={'body2'} className={classes.infoText}>
                          Переплата:
                        </Typography>
                        <Typography className={classes.infoText}>
                          {moneyFormat(Number(merchantGroup.balance) * -1)}
                        </Typography>
                      </Grid>
                    )}

                    {fromPublic && isXs && (
                      <Grid item className={classes.infoBlock}>
                        <Typography variant={'body2'} className={classes.infoText}>
                          Лицевой счет:
                        </Typography>
                        <Typography className={classes.infoText}>
                          {accountNumber || accountNumberGetParam}
                        </Typography>
                      </Grid>
                    )}

                    {merchantGroup.lastPayedDate && !fromPublic && (
                      <Grid item className={classes.infoBlock}>
                        <Typography variant={'body2'} className={classes.infoText}>
                          Оплачено на{' '}
                          {moment(merchantGroup.lastPayedDate, 'X').format('D MMMM YYYY')}:
                        </Typography>
                        <Typography variant={'body2'} className={classes.infoText}>
                          <span className={classes.intPart}>{lastPayedValue[0]}</span>
                          {lastPayedValue[1] && `,${lastPayedValue[1]}`}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {fromPublic && (
                  <Hidden xsDown>
                    <Grid item className={classes.detailsButtonPublicWrapper}>
                      <Button
                        variant="text"
                        className={classes.detailsButtonPublic}
                        onClick={(): void => handleMoreDetails(merchantGroup)}
                      >
                        <Typography variant="body2" align="center">
                          Подробнее
                        </Typography>
                      </Button>
                    </Grid>
                  </Hidden>
                )}
              </Grid>
              <Grid className={classes.paymentInfoContainer}>
                <Grid item>
                  <Grid
                    container
                    justify="flex-end"
                    alignItems="center"
                    className={classes.amountBlock}
                  >
                    <Grid
                      className={cn(classes.paymentResultContainer, {
                        [classes.paymentResultContainerPublic]: fromPublic,
                      })}
                      item
                    >
                      <Typography
                        className={cn(classes.paymentResultText, {
                          [classes.paymentResultTextPublic]: fromPublic,
                        })}
                      >
                        К оплате
                      </Typography>
                      <Typography variant={'h6'} className={classes.boldText} color={'textPrimary'}>
                        {hasOverPayment(merchantGroup)
                          ? moneyFormat(0)
                          : moneyFormat(Number(merchantGroup.balance))}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  className={cn(
                    classes.buttonContainer,
                    fromPublic && classes.buttonPublicContainer,
                  )}
                >
                  {fromPublic ? (
                    <>
                      {!isSpecialBilling &&
                        getSbpEnabled(merchantGroup) &&
                        Number(merchantGroup.balance) > 0 && (
                          <Button
                            color="primary"
                            variant="outlined"
                            className={classes.sbpButtonPublic}
                            onClick={(): void => {
                              setSelectedMerchantGroup(merchantGroup);
                              setQrPaymentWindowOpen(true);
                              yM.goals.buttonQr();
                            }}
                          >
                            <img
                              className={classes.sbpImgPublic}
                              alt={'SBP'}
                              src="/images/sbp.svg"
                            />
                            <p className={classes.sbpTextPublic}></p>Оплатить через СБП
                          </Button>
                        )}

                      <Button
                        className={classes.payButtonPublic}
                        color="primary"
                        variant="contained"
                        onClick={(): void => handleOpenPaymentAndCountersFormDialog(merchantGroup)}
                      >
                        {isSpecialBilling || isBilling ? 'Перейти к оплате' : 'Выбрать'}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        color="primary"
                        variant="outlined"
                        className={classes.detailsButton}
                        onClick={(): void => handleMoreDetails(merchantGroup)}
                      >
                        Подробнее
                      </Button>

                      <Button
                        className={classes.payButton}
                        color="primary"
                        variant="contained"
                        onClick={(): void => handleOpenPaymentFormDialog(merchantGroup)}
                      >
                        Оплатить
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Fade>
      );
    };

    return (
      <Grid container direction="column">
        {merchantGroups.map(renderMerchantGroup)}
      </Grid>
    );
  };

  const renderContent = (): ReactElement => {
    if (selectedSingleMerchantGroup !== null && !fromPublic) {
      return <div>{renderSingleMerchantGroup(selectedSingleMerchantGroup)}</div>;
    }
    if (merchantGroups.length === 1 && !fromPublic) {
      return <div>{renderSingleMerchantGroup(merchantGroups[0])}</div>;
    }

    if (merchantGroups.length >= 1) {
      return <div>{renderMerchantGroups(merchantGroups)}</div>;
    }

    if (!partnerNotActive) {
      return <div>Нет счетов на оплату</div>;
    }

    return <></>;
  };

  return (
    <>
      {loading ? (
        <Box paddingTop={loaderPadding}>
          <Preloader color={isSpecialBilling ? '#3167F3' : ''} />
        </Box>
      ) : (
        renderContent()
      )}
      {renderPaymentFormDialog}
      {renderDetailsModal}
      {renderPaymentAndCountersFormDialog}
      {!isSpecialBilling && renderQrModal()}
      {!fromPublic ? (
        <QrPaymentWindow
          emailForFiscalReceipt={''}
          paymentSource={paymentSource || paymentSourceByPage}
          merchantGroup={String(selectedSingleMerchantGroup?.id)}
          open={qrPaymentWindowOpen}
          amount={
            hasOverPayment(selectedSingleMerchantGroup)
              ? 0
              : Number(selectedSingleMerchantGroup?.balance)
          }
          close={setQrPaymentWindowClose}
        />
      ) : (
        <QrPaymentWindow
          account={accountNumber ? accountNumber : ''}
          provider={selectedMerchantGroup?.providerId ? selectedMerchantGroup?.providerId : 0}
          emailForFiscalReceipt={''}
          paymentSource={paymentSource || paymentSourceByPage}
          merchantGroup={String(selectedMerchantGroup?.id)}
          open={qrPaymentWindowOpen}
          amount={Number(selectedMerchantGroup?.balance)}
          close={setQrPaymentWindowClose}
        />
      )}
    </>
  );
};

export default PaymentBlock;
