/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

export const useOrganizationStore = () => {
  const { billingOrganizationStore } = useStores();

  return useObserver(() => ({
    loading: billingOrganizationStore.loading,
    get: billingOrganizationStore.get,
    list: billingOrganizationStore.list,
    cleanUp: billingOrganizationStore.cleanUp,
    load: billingOrganizationStore.load,
  }));
};
