import React, { FC } from 'react';
import { makeStyles, createStyles, Link, Grid } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { Tooltip } from '@core/components/Tooltip';
import { PENALTY } from '@core/constants/helpCenter';
import { isBilling, isSpecialBilling } from '@core/constants/project';

const useStyles = makeStyles(() =>
  createStyles({
    penaltyIcon: {
      height: 18,
      width: 18,
      marginLeft: 5,
      alignSelf: 'center',
    },
  }),
);

interface PaymentTooltipProps {
  className: string;
}

export const PenaltyTooltip: FC<PaymentTooltipProps> = ({ className }) => {
  const classes = useStyles();
  return (
    <div className={className}>
      <Grid container wrap="nowrap" direction="row" alignItems="center">
        <Grid item>Пени</Grid>
        <Grid item>
          {!isSpecialBilling && (
            <Tooltip
              interactive
              title={
                <div>
                  <span style={{ fontWeight: 'normal' }}>Начисление пени</span>
                  <p>Не начисляются первые 30 дней.</p>
                  <p>Начисляются с 31-го дня просрочки.</p>
                  {isBilling && (
                    <span>
                      <Link target="_blank" href={PENALTY}>
                        Подробнее
                      </Link>{' '}
                      о правилах начислений.
                    </span>
                  )}
                </div>
              }
            >
              <InfoOutlinedIcon className={classes.penaltyIcon} />
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
