import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '1441px',
      padding: '42px 155px 0 149px',
      position: 'relative',
      [theme.breakpoints.up('lg')]: {
        margin: 'auto',
      },
      [theme.breakpoints.down('md')]: {
        padding: '42px 100px 55px 80px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '42px 40px 55px 40px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0 15px 55px 15px',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },

    anchor: {
      position: 'absolute',
      marginTop: '-180px',

      [theme.breakpoints.down('sm')]: {
        marginTop: '-120px',
      },
      [theme.breakpoints.down('xs')]: {
        top: 20,
      },
    },

    background: {
      position: 'absolute',
      marginLeft: '-53px',
      marginTop: '-150px',
      [theme.breakpoints.down('md')]: {
        marginLeft: '-43px',
        marginTop: '-135px',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    backgroundRight: {
      position: 'absolute',
      marginRight: '-127px',
      marginBottom: '500px',
      [theme.breakpoints.down('md')]: {
        marginLeft: '-67px',
        marginTop: '100px',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    contentBlock: {
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
      },
    },
    contentContainer: {
      [theme.breakpoints.up('sm')]: {
        '& + &': {
          marginLeft: '75px',
        },
      },
      [theme.breakpoints.up('md')]: {
        '& + &': {
          marginLeft: '115px',
        },
      },
    },
    container: {
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },
    title: {
      fontWeight: 800,
      fontSize: '40px',
      lineHeight: '48px',
      color: '#07003D',
      [theme.breakpoints.up('md')]: {
        marginBottom: '-30px',
      },

      [theme.breakpoints.down('md')]: {
        fontSize: '36px',
        lineHeight: '44px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '32px',
        lineHeight: '40px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '24px',
        lineHeight: '32px',

        textAlign: 'center',
      },
    },

    subtitle: {
      fontWeight: 800,
      fontSize: '24px',
      lineHeight: '32px',
      color: '#07003D',
      marginBottom: '24px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        lineHeight: '28px',
        marginBottom: '16px',
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: '25px',
        textAlign: 'center',
        paddingLeft: '40px',
        paddingRight: '40px',
      },
    },
    content: {
      fontSize: '18px',
      lineHeight: '28px',
      color: '#07003D',
      marginBottom: '24px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: '26px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '15px',
        lineHeight: '20px',
        textAlign: 'center',
        paddingLeft: '20px',
        paddingRight: '20px',
        marginBottom: '8px',
      },
    },

    link: {
      textDecoration: 'none',
      position: 'relative',
      color: '#18CDA6',
      display: 'flex',
      alignItems: 'center',
      fontSize: '15px',

      [theme.breakpoints.up('sm')]: {
        '&:after': {
          display: 'flex',
          alignItems: 'center',
          content: '"\\2192"',
          marginBottom: '2px',
        },
        fontSize: '18px',
      },
    },

    imageContainer: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        width: '70vw',
        height: '70%',
      },
    },
  }),
);
