import React, { FC, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import cn from 'classnames';

import PaymentForm from '@public/components/PaymentForm';
import FeedbackDialog from '@components/FeedbackDialog';
import { isBilling } from '@core/constants/project';

const textColor = '#07003D';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: '#F3F6FE',
      color: textColor,
      marginBottom: 100,
      [theme.breakpoints.up('lg')]: {
        marginBottom: 50,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 50,
      },
    },

    rootOC: {
      marginTop: 340,
      marginBottom: 0,
      width: 'calc(100% + 240px)',

      [theme.breakpoints.down(1400)]: {
        width: 'calc(100% + 128px)',
      },

      [theme.breakpoints.down(1240)]: {
        width: 'calc(100% + 64px)',
      },

      [theme.breakpoints.down('xs')]: {
        width: 'calc(100% + 32px)',
      },

      [theme.breakpoints.down('lg')]: {
        marginTop: 120,
      },

      [theme.breakpoints.down('md')]: {
        marginTop: 40,
      },

      [theme.breakpoints.up('sm')]: {},
    },

    wrapper: {
      maxWidth: '1441px',
      padding: '72px 155px 64px 149px',
      position: 'relative',
      [theme.breakpoints.up('lg')]: {
        margin: 'auto',
      },
      [theme.breakpoints.down('md')]: {
        padding: '48px 100px 48px 80px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '36px 40px 36px 40px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '24px 15px 24px 15px',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    titleWrapper: {
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(8),
      },
    },
    title: {
      [theme.breakpoints.down('md')]: {
        fontSize: '36px',
        lineHeight: '44px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '32px',
        lineHeight: '40px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '24px',
        lineHeight: '32px',
      },
    },
    inputWrapper: {
      marginBottom: theme.spacing(6),
    },
    help: {
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },
    link: {
      color: theme.palette.primary.main,
      display: 'inline-block',
      cursor: 'pointer',
    },
  }),
);

const FastPay: FC = () => {
  const classes = useStyles();
  const [feedbackModal, setFeedbackModal] = useState(false);

  const openModal = (): void => setFeedbackModal(true);
  const closeModal = (): void => setFeedbackModal(false);

  return (
    <Grid container className={cn(classes.root, !isBilling && classes.rootOC)}>
      <Grid container className={classes.wrapper}>
        <Grid container direction="column" justify="space-between">
          <Grid item className={classes.titleWrapper}>
            <Typography variant="h2" className={classes.title}>
              Быстрая оплата ЖКУ и передача показаний счетчиков
            </Typography>
          </Grid>
          <Grid item container className={classes.inputWrapper}>
            <PaymentForm fromLanding />
          </Grid>
          <Grid item>
            <Typography variant="body2" className={classes.help}>
              Не нашли своего поставщика услуг?{' '}
              <Typography className={classes.link} variant="body2" onClick={openModal}>
                Напишите нам!
              </Typography>
            </Typography>
            <FeedbackDialog open={feedbackModal} onClose={closeModal} fromPublic />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FastPay;
