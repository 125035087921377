import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

export const usePaymentStore = () => {
  const { paymentStore } = useStores();

  return useObserver(() => ({
    paymentGetLink: paymentStore.getLink,
  }));
};
