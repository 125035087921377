import React, { FC } from 'react';
import classnames from 'classnames';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  useMediaQuery,
  useTheme,
  IconButton,
  Button,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

interface SuccessModalProps {
  open: boolean;
  onClose: () => void;
  email: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      width: '100%',
      textAlign: 'center',
    },
    dialogContent: {
      padding: `0 ${theme.spacing(8)}px ${theme.spacing(8)}px ${theme.spacing(8)}px`,
    },
    title: {
      fontWeight: 500,
      paddingBottom: theme.spacing(3),
    },
    text: {
      paddingBottom: theme.spacing(8),
      lineHeight: '24px',
    },
    image: {
      width: '194px',
      paddingTop: '40px',
    },
    modal: {
      maxWidth: '415px',
      borderRadius: '8px',
    },
    closeIcon: {
      position: 'absolute',
      right: '32px',
      top: '16px',
      width: '50px',
      height: '50px',
    },
  }),
);

const SuccessModal: FC<SuccessModalProps> = ({ open, onClose, email }) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={'xs'}
      fullScreen={isXs}
      fullWidth={true}
      PaperProps={{ className: classes.modal }}
    >
      <IconButton onClick={onClose} className={classes.closeIcon}>
        <CloseIcon />
      </IconButton>
      <DialogContent className={classes.dialogContent}>
        <Grid container={true}>
          <Grid item={true} className={classes.row}>
            <img
              src="/images/success_modal.svg"
              alt="Успешная регистрация"
              className={classes.image}
            />
          </Grid>
          <Grid item={true} className={classes.row}>
            <Typography variant={'h5'} className={classes.title}>
              Успешная регистрация
            </Typography>
          </Grid>

          <Grid item={true} className={classnames(classes.row, classes.text)}>
            {`На email ${email} отправлено письмо с данными для доступа в личный кабинет`}
          </Grid>
          <Grid item={true} className={classes.row}>
            <Button color="primary" variant="contained" fullWidth={true} onClick={onClose}>
              Понятно
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessModal;
