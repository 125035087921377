import React, { FC } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  useMediaQuery,
  DialogActions,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import {
  useOwnProfileStore,
  usePaymentStore,
  useAuthenticationStore,
  useNotificationStore,
} from './hooks';
import { isSpecialBilling } from '@core/constants/project';

const textColor = '#07003D';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      [theme.breakpoints.down('xs')]: {
        paddingTop: '64px',
      },
    },

    title: {
      color: '#07003D',
    },

    text: {
      marginBottom: 16,
    },

    passwordField: {
      marginBottom: theme.spacing(4),
    },

    field: {
      color: textColor,
    },

    actionWrapper: {
      padding: '16px 25px',
    },

    loading: {
      padding: '0px !important',
      backgroundColor: 'transparent',
    },

    actions: {
      justifyContent: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
      },
    },

    button: {
      padding: '6px 24px',
      [theme.breakpoints.down('xs')]: {
        '& + &': {
          marginBottom: theme.spacing(3),
        },
      },
      [theme.breakpoints.up('sm')]: {
        '& + &': {
          marginLeft: theme.spacing(2),
        },
      },
    },

    contentWrapper: {
      [theme.breakpoints.down('xs')]: {
        flexGrow: 0,
      },
    },
  }),
);

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const DeleteProfile: FC<Props> = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const { deleteBillingUser, deleteUser, billingUsers, setUserDeleting } = useOwnProfileStore();
  const { loadCards, detachCard } = usePaymentStore();
  const { signOutToSignUp } = useAuthenticationStore();
  const { deleteNotification, deleteNotificationType } = useNotificationStore();

  const handleDelete = async (): Promise<void> => {
    setUserDeleting(true);

    await Promise.all(
      billingUsers.map(_user => deleteNotificationType({ personalAccountId: Number(_user.id) })),
    );

    await deleteNotification();

    await Promise.all(
      billingUsers.map(
        async _user =>
          await deleteBillingUser({
            number: _user.number,
            id: _user.id,
            organizationId: _user.organizationId,
            organizationName: _user.organizationName,
            providerId: _user.providerId,
          }),
      ),
    );

    const cardsList = await loadCards();

    await Promise.all(
      cardsList.map(async _card => {
        await detachCard({ tokenId: _card.tokenId, noMessage: true });
      }),
    );

    const userDeleted = await deleteUser();

    if (userDeleted) {
      signOutToSignUp();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullScreen={isXs}
      className={classes.dialog}
      transitionDuration={500}
      fullWidth
      style={{}}
    >
      <DialogTitle>
        <Grid container alignItems="center" justify="space-between">
          <Grid item className={classes.title}>
            Удаление профиля
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.contentWrapper}>
        <Grid container direction="column">
          <Typography variant="body2" color="textPrimary" className={classes.text}>
            Удаление профиля приведет к удалению вашей учетной записи, привязанных к ней лицевых
            счетов и банковских карт. Вы уверены, что хотите удалить Ваш профиль?
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionWrapper}>
        <Grid container className={classes.actions}>
          <Button color="primary" variant="outlined" className={classes.button} onClick={onClose}>
            Отмена
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={handleDelete}
          >
            Удалить профиль
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
