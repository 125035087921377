import React, { FC } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';

import { SmevAccountInterface } from '@core/entities/Billing/Smev';

interface SmevDetailsModalProps {
  selectedSmevAccount?: SmevAccountInterface;
  modalVisibility: boolean;
  closeModal: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    maxWidth: 600,
    width: '100%',
  },
  dialogTitle: {
    padding: theme.spacing(6, 6, 4, 8),
  },
  dialogContent: {
    padding: theme.spacing(2, 8, 8, 8),
  },
  row: {
    '& + &': {
      marginTop: theme.spacing(2),
    },
  },
  rowTitle: {
    marginRight: theme.spacing(7),
  },
  text: {
    // [theme.breakpoints.down('xs')]: {
    //   fontSize: '12px !important',
    // },
  },
  headerText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px !important',
    },
  },
  titleText: {
    color: 'rgba(7, 0, 61, 0.6)',
  },
  iconButton: {
    padding: theme.spacing(1),
  },
}));

const SmevDetailsModal: FC<SmevDetailsModalProps> = ({
  modalVisibility,
  closeModal,
  selectedSmevAccount,
}) => {
  if (!selectedSmevAccount) {
    return <></>;
  }
  const classes = useStyles();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const { recipient, bank } = selectedSmevAccount;

  return (
    <Dialog
      fullScreen={isXs}
      open={modalVisibility}
      onClose={closeModal}
      classes={{ paper: classes.modalContainer }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <Grid item>
            <Typography variant="h4" align="left" className={classes.headerText}>
              Информация о получателе платежа
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={closeModal} className={classes.iconButton}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Grid container direction="column">
          <Grid container justify="space-between" wrap="nowrap" className={classes.row}>
            <Grid item className={classes.rowTitle}>
              <Typography variant="body2" align="left" className={classes.titleText}>
                Наименование
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" align="right" className={classes.text}>
                {recipient?.name}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justify="space-between" wrap="nowrap" className={classes.row}>
            <Grid item className={classes.rowTitle}>
              <Typography variant="body2" align="left" className={classes.titleText}>
                ИНН
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" align="right" className={classes.text}>
                {recipient?.inn}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justify="space-between" wrap="nowrap" className={classes.row}>
            <Grid item className={classes.rowTitle}>
              <Typography variant="body2" align="left" className={classes.titleText}>
                КПП
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" align="right" className={classes.text}>
                {recipient?.kpp}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justify="space-between" wrap="nowrap" className={classes.row}>
            <Grid item className={classes.rowTitle}>
              <Typography variant="body2" align="left" className={classes.titleText}>
                Банк
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" align="right" className={classes.text}>
                {bank?.name}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justify="space-between" wrap="nowrap" className={classes.row}>
            <Grid item className={classes.rowTitle}>
              <Typography variant="body2" align="left" className={classes.titleText}>
                БИК банка
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" align="right" className={classes.text}>
                {bank?.bik}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justify="space-between" wrap="nowrap" className={classes.row}>
            <Grid item className={classes.rowTitle}>
              <Typography variant="body2" align="left" className={classes.titleText}>
                Корр. счет
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" align="right" className={classes.text}>
                {bank?.correspondentAccount}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justify="space-between" wrap="nowrap" className={classes.row}>
            <Grid item className={classes.rowTitle}>
              <Typography variant="body2" align="left" className={classes.titleText}>
                Расчетный счет
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" align="right" className={classes.text}>
                {recipient?.paymentAccount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SmevDetailsModal;
