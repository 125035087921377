import React, { FC, useCallback, useMemo, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import { Merchant } from '@entities/Billing/Merchant';
import cn from 'classnames';
import theme from '@core/themes/agryz';
import { isSpecialBilling } from '@core/constants/project';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    tooltip: {
      padding: theme.spacing(3, 4),
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },

    arrow: {
      color: 'rgba(0, 0, 0, 0.8)',
      fontSize: 20,
    },

    text: {
      fontWeight: 'normal',
      color: isSpecialBilling ? theme.palette.primary.main : '#18CDA6',
      cursor: 'pointer',
    },

    tooltipText: {
      fontSize: 13,

      '& + &': {
        marginTop: 4,
      },
    },

    boldTooltipText: {
      fontWeight: 'normal',
    },
  }),
);

interface MerchantInfoTooltipProps {
  fullName?: string | null;
  tooltipData: Merchant;
}

export const MerchantInfoTooltip: FC<MerchantInfoTooltipProps> = ({ fullName, tooltipData }) => {
  const classes = useStyles();

  const { contactEmail, contactName, contactPhone } = tooltipData;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const placement = useMemo(() => (isSm ? 'top' : 'right-start'), [isSm]);

  const handleTooltipClose = useCallback((): void => {
    setTooltipOpen(false);
  }, []);

  const handleTooltipOpen = useCallback((): void => {
    setTooltipOpen(true);
  }, []);

  return (
    <span onMouseLeave={handleTooltipClose}>
      <Tooltip
        interactive
        arrow
        placement={placement}
        open={tooltipOpen}
        title={
          <div>
            <Typography className={cn(classes.tooltipText, classes.boldTooltipText)}>
              {fullName}
            </Typography>
            <Typography className={classes.tooltipText}>
              <span className={classes.boldTooltipText}>Контактное лицо: </span>
              {contactName}
            </Typography>
            <Typography className={classes.tooltipText}>
              <span className={classes.boldTooltipText}>Телефон: </span>
              {contactPhone}
            </Typography>
            <Typography className={classes.tooltipText}>
              <span className={classes.boldTooltipText}>E-mail: </span>
              {contactEmail}
            </Typography>
          </div>
        }
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      >
        <span onMouseEnter={handleTooltipOpen} onClick={handleTooltipOpen} className={classes.text}>
          {fullName}
        </span>
      </Tooltip>
    </span>
  );
};
