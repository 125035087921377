import React, { FC } from 'react';
import {
  DatePicker as BaseDatePicker,
  DatePickerProps as MaterialDatePickerProps,
} from '@material-ui/pickers';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clearIcon: {
      cursor: 'pointer',
    },
    dateTimePicker: {
      '& button': {
        borderRadius: 2,
        padding: theme.spacing(0, 1),
      },
      '& h3': {
        fontSize: '2.5rem !important',
      },
      '& h4': {
        fontSize: '1.5rem !important',
      },
      '& h4, h3, h6': {
        color: 'white',
      },
      '& h6': {
        fontSize: '1rem !important',
      },
    },
  }),
);

export type DatePickerProps = MaterialDatePickerProps;

const DateTimePicker: FC<DatePickerProps> = props => {
  const classes = useStyles();

  return (
    <BaseDatePicker PopoverProps={{ classes: { paper: classes.dateTimePicker } }} {...props} />
  );
};

export default DateTimePicker;
