import React, { FC, useMemo } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';

import Icon from '@core/components/Icon';
import { PrepaymentTooltip } from './PrepaymentTooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },

    title: {
      fontSize: 16,
    },

    text: {
      fontSize: '13px !important',
    },

    iconWrapper: {
      flex: 1,
    },

    icon: {
      width: 60,
      height: 60,
    },

    tooltipWrapper: {
      position: 'absolute',
      bottom: 4,
      right: 0,

      [theme.breakpoints.up('sm')]: {
        bottom: -8,
        right: -8,
      },
    },

    footerWrapper: {
      position: 'relative',
    },
  }),
);

const dateFormatter = (step: number): string => {
  if ([2, 3, 4].includes(step)) {
    return 'месяца';
  }

  if ([5, 6].includes(step)) {
    return 'месяцев';
  }

  return 'месяц';
};

interface Props {
  enablePrepayment: boolean;
  step: number;
  isOverPrepayment: boolean;
}

export const PrepaymentBanner: FC<Props> = ({
  enablePrepayment,
  step,
  isOverPrepayment,
  children,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const footerText = useMemo(() => {
    if (isOverPrepayment) {
      return `Аванса хватит больше, чем на 6 месяцев`;
    }

    if (step) {
      return `Аванса хватит примерно на ${step} ${dateFormatter(step)}`;
    }

    if (isXs) {
      return 'Аванс не выбран';
    }

    return 'Перемещайте ползунок, чтобы выбрать сумму аванса';
  }, [step, isXs, isOverPrepayment]);

  return (
    <Grid container direction="column" spacing={2} className={classes.root}>
      <Grid item container wrap="nowrap" spacing={3}>
        <Grid item container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h4" className={classes.title}>
              Добавьте аванс к платежу
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body2" className={classes.text}>
              {enablePrepayment
                ? 'Часто забываете оплатить? Уезжаете в отпуск? Оплатите ЖКУ заранее. Перемещайте ползунок, чтобы выбрать сумму аванса'
                : 'Среднее начисление за ЖКУ по вашему лицевому счету за последние 3 месяца равно нулю. Для оплаты авансом укажите необходимую сумму в поле "Сумма к оплате"'}
            </Typography>
          </Grid>
        </Grid>

        {!isXs && (
          <Grid item className={classes.iconWrapper}>
            <Icon icon="prepaymentCalendar" className={classes.icon} />
          </Grid>
        )}
      </Grid>

      {enablePrepayment ? (
        <>
          <Grid item>{children}</Grid>

          <Grid
            item
            container
            alignItems="center"
            justify={isXs ? 'space-between' : 'flex-start'}
            className={classes.footerWrapper}
          >
            <Typography variant="body2" className={classes.text}>
              {footerText}
            </Typography>

            <PrepaymentTooltip />
          </Grid>
        </>
      ) : (
        <Grid className={classes.tooltipWrapper}>
          <PrepaymentTooltip />
        </Grid>
      )}
    </Grid>
  );
};
