import React, { FC, useState } from 'react';
import cn from 'classnames';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { MerchantGroup } from '@entities/Billing/MerchantGroup';
import { isSpecialBilling } from '@core/constants/project';

interface PaymentDataProps {
  merchantGroup: MerchantGroup;
  hideReceiver?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 500,
      paddingBottom: theme.spacing(4),
    },
    account: {
      marginBottom: theme.spacing(8),
    },
    paymentData: {
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(3),
      },
    },

    paymentDataItem: {
      '& + &': {
        marginTop: theme.spacing(2),
      },
    },

    paymentDataText: {
      fontSize: '15px',
      lineHeight: '24px',
      color: 'rgba(7, 0, 61, 0.5)',

      '& + &': {
        marginLeft: theme.spacing(1),
        maxWidth: '70%',
        textAlign: 'right',
      },
    },

    receiverText: {
      color: isSpecialBilling ? theme.palette.primary.main : '#18CDA6',
      display: 'flex',
      alignItems: 'center',

      '&:last-child::after': {
        marginLeft: theme.spacing(3),
        content: "''",
        borderTop: `4px solid ${isSpecialBilling ? theme.palette.primary.main : '#18CDA6'}`,
        borderRight: '4px solid #FFFFFF',
        borderLeft: '4px solid #FFFFFF',
        width: 0,
        height: 0,
        transition: '0.2s',
      },
    },

    showInfoArrow: {
      '&::after': {
        transform: 'rotate(180deg)',
      },
    },

    additionalInfo: {
      maxHeight: 0,
      overflow: 'hidden',
      transition: '0.35s ease',
      marginTop: 0,
    },

    showAdditionalInfo: {
      maxHeight: 800,
      marginTop: theme.spacing(2),
    },
  }),
);

const PaymentData: FC<PaymentDataProps> = ({ merchantGroup, hideReceiver = false }) => {
  const classes = useStyles();
  const [isInfoVisible, setInfoVisible] = useState(false);

  React.useEffect(() => {
    document.body.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const {
    paymentName,
    paymentInn,
    paymentAcc,
    paymentCorAcc,
    paymentBik,
    paymentBank,
  } = merchantGroup;

  return (
    <Grid container={true} justify={'space-between'}>
      {/* {isMd && renderAccount} */}
      <Grid item={true} container={true} direction={'column'} className={classes.paymentData}>
        <Grid item={true}>
          <Typography className={classes.title} color="textPrimary">
            Платёжная информация
          </Typography>
        </Grid>

        {!hideReceiver && (
          <Grid
            className={classes.paymentDataItem}
            onClick={(): void => setInfoVisible(!isInfoVisible)}
            item={true}
            style={{ cursor: 'pointer' }}
          >
            <Grid container={true} justify={'space-between'} alignItems="center" wrap="nowrap">
              <Typography
                className={cn(classes.paymentDataText, classes.receiverText)}
                variant={'body2'}
              >
                Получатель
              </Typography>
              <Typography
                className={cn(classes.paymentDataText, classes.receiverText, {
                  [classes.showInfoArrow]: !isInfoVisible,
                })}
                variant={'body2'}
              >
                {paymentName}
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid
          className={cn(classes.paymentDataItem, classes.additionalInfo, {
            [classes.showAdditionalInfo]: isInfoVisible,
          })}
        >
          {paymentInn && (
            <Grid className={classes.paymentDataItem} item={true}>
              <Grid container={true} justify={'space-between'} alignItems="center">
                <Typography className={classes.paymentDataText} variant={'body2'}>
                  ИНН получателя
                </Typography>
                <Typography className={classes.paymentDataText} variant={'body2'}>
                  {paymentInn}
                </Typography>
              </Grid>
            </Grid>
          )}

          {paymentAcc && (
            <Grid className={classes.paymentDataItem} item={true}>
              <Grid container={true} justify={'space-between'} alignItems="center">
                <Typography className={classes.paymentDataText} variant={'body2'}>
                  Расчётный счёт
                </Typography>
                <Typography className={classes.paymentDataText} variant={'body2'}>
                  {paymentAcc}
                </Typography>
              </Grid>
            </Grid>
          )}

          {paymentCorAcc && (
            <Grid className={classes.paymentDataItem} item={true}>
              <Grid container={true} justify={'space-between'} alignItems="center">
                <Typography className={classes.paymentDataText} variant={'body2'}>
                  Корр. счёт
                </Typography>
                <Typography className={classes.paymentDataText} variant={'body2'}>
                  {paymentCorAcc}
                </Typography>
              </Grid>
            </Grid>
          )}

          {paymentBik && (
            <Grid className={classes.paymentDataItem} item={true}>
              <Grid container={true} justify={'space-between'} alignItems="center">
                <Typography className={classes.paymentDataText} variant={'body2'}>
                  БИК
                </Typography>
                <Typography className={classes.paymentDataText} variant={'body2'}>
                  {paymentBik}
                </Typography>
              </Grid>
            </Grid>
          )}

          {paymentBank && (
            <Grid className={classes.paymentDataItem} item={true}>
              <Grid container={true} justify={'space-between'} alignItems="center" wrap="nowrap">
                <Typography className={classes.paymentDataText} variant={'body2'}>
                  Банк
                </Typography>
                <Typography className={classes.paymentDataText} variant={'body2'}>
                  {paymentBank}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentData;
