import React, { FC, useState, useEffect } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';

import { useOrganizationStore } from './hooks';
import Autocomplete, { RenderInput, OptionType } from '@components/Autocomplete';
import { OrganizationKeys, Organization } from '@core/entities/Billing/Organization';

interface OrganizationSearchProps {
  textFieldProps?: TextFieldProps;
  selectOrganization?: (value: Organization | null) => void;
  className?: string;
  value?: OptionType | null;
  setValue?: (value: OptionType | null) => void;
  isNeedToClear?: boolean;
}

const OrganizationSearch: FC<OrganizationSearchProps> = ({
  textFieldProps,
  selectOrganization,
  className,
  value,
  setValue,
  isNeedToClear,
}) => {
  const { loading, list, cleanUp, load } = useOrganizationStore();
  const [_value, _setValue] = useState<OptionType | null>(null);
  const [options, setOptions] = useState<OptionType[]>([]);

  useEffect(() => {
    if (list.length) {
      setOptions([]);
      const options = list.map(item => ({
        label: `${item.name} ИНН ${item.inn}`,
        value: item.id.toString(),
        data: item,
      }));
      setOptions(options);
    }
  }, [list]);

  const handleChangeInput = (inputValue: string): void => {
    cleanUp();
    if (inputValue.length > 3) {
      load({
        filter: { [OrganizationKeys.NAME]: { $like: inputValue ? `%${inputValue}%` : '' } },
      });
    }
  };

  const handleChange = async (option: OptionType | null): Promise<void> => {
    if (option) {
      setValue ? setValue(option) : _setValue(option);
      selectOrganization?.(option.data);
    }
  };

  const renderInput: RenderInput = params => {
    return (
      <TextField
        {...params}
        {...textFieldProps}
        variant={'outlined'}
        InputLabelProps={{
          ...params.InputLabelProps,
          ...textFieldProps?.InputLabelProps,
          shrink: true,
        }}
        InputProps={{
          ...params.InputProps,
          ...textFieldProps?.InputProps,
          notched: false,
        }}
        FormHelperTextProps={{
          variant: 'standard',
        }}
        tabIndex={1}
      />
    );
  };

  const clear = (): void => {
    handleChangeInput('');
    selectOrganization?.(null);
    setValue ? setValue(null) : _setValue(null);
  };

  useEffect(() => {
    if (isNeedToClear) clear();
  }, [isNeedToClear]);

  return (
    <Autocomplete
      {...textFieldProps}
      loading={loading}
      options={options}
      value={value || _value}
      renderInput={renderInput}
      onChange={handleChange}
      onClear={clear}
      onInputChange={handleChangeInput}
      className={className}
    />
  );
};

export default OrganizationSearch;
