import project, { isNizhnekamsk } from './project';

const mapZoom = process.env.REACT_APP_MAP_ZOOM;
const mapMaxZoom = process.env.REACT_APP_MAP_MAX_ZOOM;
const mapMinZoom = process.env.REACT_APP_MAP_MIN_ZOOM;
const mapCenterX = process.env.REACT_APP_MAP_CENTER_X;
const mapCenterY = process.env.REACT_APP_MAP_CENTER_Y;
const mapSouthWestX = process.env.REACT_APP_MAP_SOUTH_WEST_X;
const mapSouthWestY = process.env.REACT_APP_MAP_SOUTH_WEST_Y;
const mapNorthEastX = process.env.REACT_APP_MAP_NORTH_EAST_X;
const mapNorthEastY = process.env.REACT_APP_MAP_NORTH_EAST_Y;
const emailValidationPattern = process.env.REACT_APP_EMAIL_VALIDATION_PATTERN;
const projectName = process.env.REACT_APP_PROJECT_NAME;
const projectDescription = process.env.REACT_APP_PROJECT_DESCRIPTION;
const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;
const footerCaption = process.env.REACT_APP_FOOTER_CAPTION;
const cctvURL = process.env.REACT_APP_CCTV_URL;
const supportPhoneNumber = process.env.REACT_APP_SUPPORT_PHONE_NUMBER;
const projectLaunchDate = process.env.REACT_APP_PROJECT_LAUNCH_DATE;
const countOfUploadingFiles = process.env.REACT_APP_COUNT_OF_UPLOADING_FILES;
const weightOfUploadedFile = process.env.REACT_APP_WEIGHT_OF_UPLOADED_FILE;
const oldVersion = process.env.REACT_APP_OLD_VERSION;
const messageCloseTime = process.env.REACT_APP_MESSAGE_CLOSE_TIME;
const yandexMetrikaKey = process.env.REACT_APP_YANDEX_METRIKA_KEY;
const defaultPrivateRoute = process.env.REACT_APP_DEFAULT_PRIVATE_ROUTE;
const defaultPublicRoute = process.env.REACT_APP_DEFAULT_PUBLIC_ROUTE;
const showMobileLinks = Boolean(Number(process.env.REACT_APP_SHOW_MOBILE_LINKS));
const gAKey = process.env.REACT_APP_GA_KEY;
const oneSignalAppId = process.env.REACT_APP_ONESIGNAL_APP_ID;
const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
const providerId = process.env.REACT_APP_PROVIDER_ID;
const telegramLink = process.env.REACT_APP_TELEGRAM_LINK;
const helpCenterPublic = process.env.REACT_APP_HELP_CENTER_PUBLIC;
const helpCenterService = process.env.REACT_APP_HELP_CENTER_SERVICE;
const feedbackEmail = process.env.REACT_APP_FEEDBACK_EMAIL;
const googlePlayLink = process.env.REACT_APP_GOOGLE_PLAY;
const cardAbonentPromo = process.env.REACT_APP_CARD_ABONENT_LANDING;
const signUpSpecialSource = process.env.REACT_APP_ACTION_SPECIAL_SIGN_UP
  ? Number(process.env.REACT_APP_ACTION_SPECIAL_SIGN_UP)
  : undefined;
const signUpActionSource = process.env.REACT_APP_ACTION_SOURCE_SIGN_UP
  ? Number(process.env.REACT_APP_ACTION_SOURCE_SIGN_UP)
  : undefined;
const fastPaymentActionSource = process.env.REACT_APP_ACTION_SOURCE_FAST_PAYMENT
  ? Number(process.env.REACT_APP_ACTION_SOURCE_FAST_PAYMENT)
  : undefined;
const privateActionSource = process.env.REACT_APP_ACTION_SOURCE_PRIVATE
  ? Number(process.env.REACT_APP_ACTION_SOURCE_PRIVATE)
  : undefined;
const adaptiveSignUpActionSource = process.env.REACT_APP_ACTION_SOURCE_SIGN_UP_ADAPTIVE
  ? Number(process.env.REACT_APP_ACTION_SOURCE_SIGN_UP_ADAPTIVE)
  : undefined;
const adaptiveFastPaymentActionSource = process.env.REACT_APP_ACTION_SOURCE_FAST_PAYMENT_ADAPTIVE
  ? Number(process.env.REACT_APP_ACTION_SOURCE_FAST_PAYMENT_ADAPTIVE)
  : undefined;

const isCenterSBK = {
  providerId: 10010,
};

interface ProjectConfig {
  map: {
    zoom: number;
    center: [number, number];
    southWest: [number, number];
    northEast: [number, number];
    maxZoom: number;
    minZoom: number;
  };
  phoneValidationPattern: RegExp;
  projectName: string;
  projectDescription: string;
  supportEmail: string;
  supportPhoneNumber: string;
  footerCaption: string;
  cctvURL: string;
  logo: string;
  personalLicense: string;
  projectLaunchDate: number;
  constraints: {
    countOfUploadingFiles: number;
    weightOfUploadedFile: number;
  };
  oldVersion: string;
  messageCloseTime: number;
  yandexMetrikaKey: string;
  gAKey: string;
  defaultPrivateRoute: string;
  defaultPublicRoute: string;
  showMobileLinks: boolean;
  fakePersonalAccountNumber?: string;
  oneSignalAppId?: string;
  isCenterSBK: typeof isCenterSBK;
  providerId?: string;
  helpCenterPublic?: string;
  helpCenterService?: string;
  feedbackEmail: string;
  googlePlayLink?: string;
  telegramLink?: string;
  recaptchaKey: string;
  cardAbonentPromo?: string;
  signUpActionSource?: number;
  fastPaymentActionSource?: number;
  privateActionSource?: number;
  adaptiveSignUpActionSource?: number;
  adaptiveFastPaymentActionSource?: number;
  signUpSpecialSource?: number;
}

const config: ProjectConfig = {
  cctvURL,
  constraints: {
    countOfUploadingFiles: Number(countOfUploadingFiles),
    weightOfUploadedFile: Number(weightOfUploadedFile),
  },
  footerCaption,
  logo: `/images/logo_${project}_image.svg`,
  map: {
    center: [Number(mapCenterX), Number(mapCenterY)],
    southWest: [Number(mapSouthWestX), Number(mapSouthWestY)],
    northEast: [Number(mapNorthEastX), Number(mapNorthEastY)],
    zoom: Number(mapZoom),
    maxZoom: Number(mapMaxZoom),
    minZoom: Number(mapMinZoom),
  },
  personalLicense: `personalLicences/${project}.md`,
  phoneValidationPattern: new RegExp(emailValidationPattern),
  projectLaunchDate: Number(projectLaunchDate),
  projectName,
  projectDescription,
  supportEmail,
  supportPhoneNumber,
  oldVersion,
  messageCloseTime: Number(messageCloseTime),
  yandexMetrikaKey,
  defaultPrivateRoute,
  defaultPublicRoute,
  showMobileLinks,
  gAKey,
  oneSignalAppId,
  isCenterSBK,
  providerId,
  helpCenterPublic,
  helpCenterService,
  feedbackEmail,
  googlePlayLink,
  recaptchaKey,
  telegramLink,
  cardAbonentPromo,
  signUpActionSource,
  fastPaymentActionSource,
  privateActionSource,
  adaptiveSignUpActionSource,
  signUpSpecialSource,
  adaptiveFastPaymentActionSource,
  ...(isNizhnekamsk && { fakePersonalAccountNumber: '100101000' }),
};

export default config;
