import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        height: 80,
        width: 'inherit',
        marginTop: 10,
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },

    navbarIcon: {
      marginRight: '6px',
      '& > path': {
        fill: '#3167F3 !important',
      },
    },

    link: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: '15px',
      color: '#07003D',
      cursor: 'pointer',
      '& + &': {
        marginLeft: '32px',
      },
    },

    verticalBar: {
      margin: '0 32px',
    },

    signUp: {
      width: 24,
      height: 24,
    },
  }),
);
