/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

export const usePaymentStore = () => {
  const { paymentStore } = useStores();

  return useObserver(() => ({
    createQrCode: paymentStore.createQrCode,
    sendQrOnEmail: paymentStore.sendQrOnEmail,
  }));
};

export const useOperationStore = () => {
  const { operationStore } = useStores();

  return useObserver(() => ({
    isAllowed: operationStore.isAllowed,
  }));
};

export const useOwnProfileStore = () => {
  const { ownProfileStore } = useStores();

  return useObserver(() => ({
    userEmail: ownProfileStore.profile?.email,
  }));
};
