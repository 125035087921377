import React, { FC } from 'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SmevErrorInterface } from '@core/entities/Billing/Smev';

const useStyles = makeStyles({
  errorWrapper: {
    padding: '1vh 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    background: 'RGBA(255,101,80,0.5)',
    borderRadius: '13px',

    '@media (max-width: 600px)': {
      fontSize: '16px',
      marginTop: '31vh',
    },
  },
  errorTitle: {
    fontSize: '16px',
  },
  errorText: {
    marginTop: '0.5vh',
    fontSize: '14px',
  },
  errorCodeText: {
    marginTop: '1vh',
    fontSize: '13px',
  },
  errorMsgText: {
    marginTop: '0.5vh',
    fontSize: '13px',
  },
});

interface SmevErrorBannerProps {
  error: SmevErrorInterface;
}

const SmevErrorBanner: FC<SmevErrorBannerProps> = ({ error }) => {
  const classes = useStyles();

  return (
    <div className={classes.errorWrapper}>
      <Typography align="center" variant="h3" className={classes.errorTitle}>
        ОШИБКА ОБРАБОТКИ ЗАПРОСА СЕРВЕРОМ
      </Typography>

      <Typography align="center" variant="h3" className={classes.errorText}>
        Извините, произошла непредвиденная ошибка.
      </Typography>

      <Typography align="center" variant="h3" className={classes.errorText}>
        Попробуйте повторить операцию позже.
      </Typography>

      <Typography align="center" variant="h3" className={classes.errorCodeText}>
        Error code: {error.code}
      </Typography>

      <Typography align="center" variant="h3" className={classes.errorMsgText}>
        {error.message}
      </Typography>

      <Typography align="center" variant="h3" className={classes.errorMsgText}>
        {error.data}
      </Typography>
    </div>
  );
};

export default SmevErrorBanner;
