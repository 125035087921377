import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Divider } from '@material-ui/core';
import classNames from 'classnames';

import { useFastPaymentStore } from '@public/components/Layout/hooks';

import { useStyles } from './styles';
import { ReactComponent as PayImage } from '../images/pay.svg';
import { ReactComponent as CabinetImage } from '../images/cabinet.svg';
import { ReactComponent as SignUpImage } from '../images/sign_in.svg';
import { ReactComponent as CloseImage } from '../images/close_menu.svg';

import { FAST_PAYMENT, SIGN_IN, SIGN_UP } from '@public/constants/routes';

interface MenuNavbarProps {
  setOpenCallback: () => void;
}

const MenuNavbar: FC<MenuNavbarProps> = ({ setOpenCallback }) => {
  const classes = useStyles();

  const { cleanUp } = useFastPaymentStore();

  return (
    <Grid container direction="column" alignItems="flex-start">
      <Grid className={classes.headerMenu}>
        <Button onClick={setOpenCallback} className={classes.closeButton}>
          <CloseImage />
        </Button>
      </Grid>
      <Divider className={classes.divider} />
      <Link to={SIGN_IN} onClick={cleanUp} className={classes.link}>
        <CabinetImage className={classes.navbarIcon} />
        Войти
      </Link>
      <Link to={SIGN_UP} onClick={cleanUp} className={classes.link}>
        <SignUpImage className={classNames(classes.navbarIcon, classes.signUp)} />
        Зарегистрироваться
      </Link>
      <Link to={FAST_PAYMENT} className={classes.link}>
        <PayImage className={classes.navbarIcon} />
        Оплата ЖКУ
      </Link>
    </Grid>
  );
};

export default MenuNavbar;
