import React from 'react';
import Icon from '@components/Icon';

import { useStyles } from './styles';

interface SmevNotFoundProps {
  publicPart?: boolean;
}

export const SmevNotFound: React.FC<SmevNotFoundProps> = ({ publicPart }) => {
  const classes = useStyles();

  return (
    <div className={publicPart ? classes.containerPublic : classes.container}>
      <Icon className={publicPart ? classes.iconPublic : classes.icon} icon="boxCat" />

      <span className={classes.title}>Ничего не найдено</span>

      <span className={classes.text}>Пожалуйста, попробуйте ещё раз позже</span>
    </div>
  );
};
