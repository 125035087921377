import React, { FC, useCallback, useMemo } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Box,
  useMediaQuery,
  Hidden,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import cn from 'classnames';
import { isEmpty } from 'ramda';

import { FEEDBACK } from '@private/constants/routes';
import { AccountStatus } from '@core/entities/Billing/Account';
import { useOwnProfileStore, useOperationStore } from './hooks';
import BillingMethods from '@core/services/billing/methods';
import { isSpecialBilling } from '@core/constants/project';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {},

    dialogContent: {
      [theme.breakpoints.down('xs')]: {
        flexGrow: 0,
      },
    },

    actionWrapper: {
      [theme.breakpoints.up('sm')]: {
        width: '80%',
      },
    },

    dialogActions: {
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'center',
        paddingBottom: 32,
      },
    },

    button: {
      fontSize: 15,
      '@media (max-width: 350px)': {
        padding: '6px 14px',
      },
    },

    secondaryButton: {
      marginBottom: 8,
      color: '#07003D',
      borderColor: 'rgba(7, 0, 61, 0.2)',

      '&:hover': {
        borderColor: 'rgba(7, 0, 61, 0.5)',
        backgroundColor: '#FFFFFF',
      },
    },

    title: {
      width: 'calc(100% - 48px)',
    },

    text: {
      color: '#07003D',
    },
  }),
);

export const TECH_SUPPORT = 'technicalSupport';

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

export const AddingAccountProblem: FC<Props> = ({ isVisible, onClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const { billingUsers, selectedBillingUser } = useOwnProfileStore();
  const { isAllowed } = useOperationStore();

  const _isVisible = useMemo(() => {
    return isVisible && isAllowed(BillingMethods.USER_ACCOUNT_CREATE);
  }, [isVisible, isAllowed]);

  const noAccount = isEmpty(billingUsers);
  const accountIsClosed = selectedBillingUser?.account?.active === AccountStatus.CLOSED;

  const onClick = useCallback(() => {
    if (noAccount || accountIsClosed) {
      history.replace({ state: { showTechSupportModal: true } });
    } else {
      history.push(FEEDBACK);
    }
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountIsClosed, history, noAccount]);

  return (
    <Dialog
      open={_isVisible}
      onClose={onClose}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={isXs}
      className={classes.dialog}
      transitionDuration={500}
    >
      <DialogTitle>
        <Grid container justify="flex-end" alignItems="center">
          <Hidden smUp>
            <Typography variant="h4" className={cn(classes.text, classes.title)}>
              Не удалось добавить лицевой счет?
            </Typography>
          </Hidden>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Box width="100%" display="flex" justifyContent="center">
            <img
              src={isSpecialBilling ? '/images/special/account_closed.png' : '/images/cat1.svg'}
              alt=""
            />
          </Box>
          <Hidden xsDown>
            <Box width="100%" display="flex" justifyContent="center" marginTop={8}>
              <Typography variant="h4" align="center" className={classes.text}>
                Не удалось добавить лицевой счет?
              </Typography>
            </Box>
          </Hidden>
          <Box width="100%" display="flex" justifyContent="center" marginTop={7}>
            <Typography variant="body2" align="center" className={classes.text}>
              Ваш лицевой счет и исполнитель коммунальных услуг указаны в счете на оплату услуг ЖКХ.
              Если в документе вы видите несколько организаций, необходимо указать именно
              исполнителя услуг.
            </Typography>
          </Box>
          <Box width="100%" display="flex" justifyContent="center" marginTop={5} marginBottom={2}>
            <Grid item container justify="center">
              <Typography variant="body2" align="center" className={classes.text}>
                Все равно не добавляется? Напишите нам об этой ситуации, мы обязательно поможем:
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Grid container direction="column" className={classes.actionWrapper}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.button}
            onClick={onClick}
          >
            Написать в техническую поддержку
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
