import React, { FC, ReactElement } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import { GOOGLE_PLAY_LINK, APP_STORE_LINK } from '@public/constants/mobileAppLinks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      minWidth: 240,
    },
    container: {
      marginTop: theme.spacing(40),
      paddingBottom: theme.spacing(24),
      position: 'relative',
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginTop: theme.spacing(20),
        padding: theme.spacing(0, 4),
      },

      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    content: {
      order: 1,
      position: 'relative',
      zIndex: 2,

      [theme.breakpoints.down('md')]: {
        maxWidth: 'inherit',
      },

      [theme.breakpoints.down('sm')]: {
        order: 2,
      },
    },
    controlsContainer: {
      '& > div:last-child': {
        marginLeft: theme.spacing(4),
      },

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-end',
      },

      [theme.breakpoints.down('xs')]: {
        justifyContent: 'space-between',
      },
    },
    description: {
      maxWidth: 556,

      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        textAlign: 'justify',
      },
    },
    mobileAppLinkImage: {
      cursor: 'pointer',
      maxHeight: 72,

      [theme.breakpoints.down('xs')]: {
        maxHeight: 'inherit',
        width: '100%',
      },
    },
    mobileAppLinkImageContainer: {
      [theme.breakpoints.down('xs')]: {
        width: '50%',
      },
    },
    phoneImg: {
      bottom: 0,
      order: 2,
      position: 'absolute',
      right: 0,
      width: '40%',
      zIndex: 2,

      [theme.breakpoints.down(1240)]: {
        top: 0,
        width: '40%',
      },

      [theme.breakpoints.down('sm')]: {
        order: 1,
        padding: theme.spacing(0, 40, 6),
        position: 'static',
        width: `calc(100% - ${theme.spacing(80)}px)`,
      },

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 0, 6),
        width: '100%',
      },
    },
    title: {
      fontWeight: 700,

      [theme.breakpoints.down('sm')]: {
        fontSize: '1.85rem !important',
        textAlign: 'center',
      },
    },
  }),
);

interface MobileAppBlockProps {
  id: string;
}

const MobileApp: FC<MobileAppBlockProps> = ({ id }) => {
  const classes = useStyles();

  const titleElement: ReactElement = (
    <Grid item={true}>
      <Typography className={classes.title} color="textPrimary" variant="h2">
        Открытый город
      </Typography>
    </Grid>
  );

  const descriptionElement: ReactElement = (
    <Grid container={true} item={true}>
      <Typography className={classes.description} color="textPrimary" variant="body1">
        Приложение Открытый Город поможет вам оперативно отправить заявку коммунальным службам
        города, где бы вы ни находились.
      </Typography>
    </Grid>
  );

  const controlsElement: ReactElement = (
    <Grid className={classes.controlsContainer} container={true} item={true} wrap="nowrap">
      <Grid className={classes.mobileAppLinkImageContainer} item={true}>
        <a
          id={`${id}__app-store-link`}
          target="_blank"
          href={APP_STORE_LINK}
          rel="noopener noreferrer"
        >
          <img className={classes.mobileAppLinkImage} alt="" src="/images/app_store_image.svg" />
        </a>
      </Grid>
      <Grid className={classes.mobileAppLinkImageContainer} item={true}>
        <a
          id={`${id}__google-play-link`}
          target="_blank"
          href={GOOGLE_PLAY_LINK}
          rel="noopener noreferrer"
        >
          <img className={classes.mobileAppLinkImage} alt="" src="/images/google_play_image.svg" />
        </a>
      </Grid>
    </Grid>
  );

  const imageOnMouseDown = (e: React.MouseEvent<HTMLImageElement>): void => e.preventDefault();

  return (
    <Grid
      className={classes.container}
      id={id}
      container={true}
      component="section"
      data-testid="mobile-app"
    >
      <Grid
        className={classes.content}
        container={true}
        direction="column"
        spacing={4}
        justify="center"
      >
        {titleElement}
        {descriptionElement}
        {controlsElement}
      </Grid>
      <Hidden smDown={true}>
        <img
          className={classes.phoneImg}
          alt=""
          src="/images/phone_image.png"
          onMouseDown={imageOnMouseDown}
        />
      </Hidden>
      <Hidden mdUp={true}>
        <img
          className={classes.phoneImg}
          src="/images/phone_mobile_image.png"
          onMouseDown={imageOnMouseDown}
          alt=""
        />
      </Hidden>
    </Grid>
  );
};

export default MobileApp;
