import React, { FC, ReactElement } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { values } from 'ramda';

import { MerchantGroup } from '@entities/Billing/MerchantGroup';
import { Merchant } from '@entities/Billing/Merchant';
import { Service } from '@entities/Billing/Service';
import ValueList from '@private/components/ValueList';
import { moneyFormat } from '@core/utils/formatter';
import { Divider } from '@material-ui/core';
import { MerchantInfoTooltip } from '@private/components/MerchantInfoTooltip';

const useStyles = makeStyles(
  createStyles({
    merchantDialogContainer: {
      marginTop: 0,
      marginBottom: 0,
    },
    listHeader: {
      marginBottom: '8px',
      position: 'sticky',
      top: '-2px',
      background: '#fff',
    },

    intPart: {
      color: '#07003D',
    },

    decimalPart: {
      color: 'rgba(7, 0, 61, 0.5)',
    },

    sumResult: {
      whiteSpace: 'nowrap',
    },
    executor: {
      fontWeight: 'bold',
    },
  }),
);

interface MerchantServicesProps {
  merchantGroup: MerchantGroup;
  merchants: Merchant[];
  services: Service[];
}

const MerchantServices: FC<MerchantServicesProps> = ({ merchantGroup, merchants, services }) => {
  const classes = useStyles();

  const renderMerchant = (merchantValue: Merchant): ReactElement => {
    const merchantServices = services
      .filter(value => value.merchantId === merchantValue.id && value.balance)
      .sort((a, b) => {
        if (!a.balance || !b.balance) {
          return 0;
        }

        return b.balance - a.balance;
      })
      .reduce<Array<{ value: ReactElement; label: string; key: number }>>((acc, value) => {
        const [intPart, decimalPart] = moneyFormat(Number(value.balance)).split(',');

        acc.push({
          value: (
            <div className={classes.sumResult}>
              <span className={classes.intPart}>{intPart}</span>
              <span className={classes.decimalPart}>,{decimalPart}</span>
            </div>
          ),
          label: value.description || '',
          key: value.id,
        });

        return acc;
      }, []);

    if (values(merchantServices).length === 0) return <></>;

    return (
      <Grid key={merchantValue.id} item={true}>
        <Grid
          container={true}
          justify={'space-between'}
          alignItems={'center'}
          className={classes.listHeader}
        >
          <Grid item={true} style={{ width: '100%', paddingBottom: '8px' }}>
            <Typography variant="body2" className={classes.executor}>
              Исполнитель:{' '}
              <MerchantInfoTooltip fullName={merchantValue.name} tooltipData={merchantValue} />
            </Typography>
          </Grid>
          <Grid item-={true}>
            <b>Услуга</b>
          </Grid>
          <Grid item={true}>
            <b>Итого</b>
          </Grid>
        </Grid>
        <Divider />
        <ValueList
          value={merchantServices}
          labelWeight={400}
          variant="space-between"
          withDivider={true}
        />
      </Grid>
    );
  };

  return (
    <Grid
      className={classes.merchantDialogContainer}
      container={true}
      direction="column"
      spacing={6}
    >
      {merchants.map(renderMerchant)}
    </Grid>
  );
};

export default MerchantServices;
