import React, {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { primaryMain } from 'oc-theme/lib';
import { useHistory } from 'react-router-dom';
import { DateType } from '@date-io/type';
import moment from 'moment';
import * as yup from 'yup';
import { useFormik, FormikHelpers } from 'formik';
import { makeStyles, createStyles, useTheme, Theme } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import classNames from 'classnames';
import Alert from '@material-ui/lab/Alert';
import ClearIcon from '@material-ui/icons/Clear';
import {
  Slider,
  Button,
  Grid,
  Typography,
  Link,
  IconButton,
  Checkbox,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import { Tooltip } from '@core/components/Tooltip';
import { Account } from '@entities/Billing/Account';
import { MerchantGroup } from '@entities/Billing/MerchantGroup';
import { usePaymentStore } from '../hooks';
import { Hidden, TextField } from '@material-ui/core';
import DateField from '@components/Fields/DateField';
import { PaymentMethods } from '@core/components/PaymentMethods';
import EmailField from '@core/components/Fields/EmailField';

import { yM } from '@core/utils/yandexMetrika/yandexMetrika';
import { TransitionModal } from './TransitionModal';
import Icon from '@core/components/Icon';
import { CardKeys, Card } from '@entities/Billing/Card';
import Skeleton from '@material-ui/lab/Skeleton';
import { floatValueFormatter } from '@core/utils/formatter';
import { email } from '@core/validation';
import { useOwnProfileStore, useAccrualStore, useMerchantGroupStore } from './hooks';
import { Banner } from '@core/components';
import { PrepaymentBanner } from '../PrepaymentBanner';
import { AccrualKeys } from '@core/entities/Billing/Accrual';
import { AlternativeWrapper } from '@core/components/AlternativeWrapper';
import { PrepaymentSkeleton } from '../PrepaymentBanner/PrepaymentSkeleton';
import Currency from '@core/utils/currency';
import { openTransitionModal } from '@core/utils/clientData';
import { isSpecialBilling } from '@core/constants/project';
import useBankCommissionList from '@core/utils/useBankCommissionList';
import config from '@core/constants/config';
import QrPaymentWindow from '../QrPaymentWindow/QrPaymentWindow';

const FormSchema = yup.object({
  amount: yup
    .number()
    .typeError('Сумма должна быть числом')
    .positive('Сумма должна быть больше нуля')
    .required('Необходимо ввести значение'),
  date: yup.number().required('Необходимо выбрать период'),
  emailForFiscalReceipt: email,
});

type FormProps = yup.InferType<typeof FormSchema>;

const FORM_ID = 'payment-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      fontSize: 15,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    paymentTooltip: {
      paddingBottom: theme.spacing(4),
    },
    link: {
      cursor: 'pointer',
    },
    dialogContainer: {
      marginTop: 0,
      marginBottom: 0,
    },
    img: {
      width: 38,
      marginLeft: '-12px',
      marginRight: '11px',
    },
    sbpImg: {
      marginLeft: '-6px',
      marginRight: '13px',
      paddingTop: '8px',
      width: 30,
    },
    imgContainer: {
      flexBasis: 0,
    },
    dialogOverrideRoot: {
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        borderRadius: 0,
      },
    },

    paymentInfo: {
      padding: '0 12px',
    },

    label: {
      color: 'rgba(7, 0, 61, 0.5)',
    },

    dialog: {
      [theme.breakpoints.down('xs')]: {
        flex: 'initial',
        paddingBottom: 0,
      },
      paddingBottom: theme.spacing(8),
    },

    input: {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },

      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
    },

    datepickerIcon: {
      '& > path': {
        fill: '#07003D !important',
      },
    },
    saveCardBlock: {
      marginLeft: -12,
      width: 'calc(100% + 12px)',

      '&:hover': {
        cursor: 'pointer',
      },

      [theme.breakpoints.down('xs')]: {
        justifyContent: 'space-between',
      },
    },

    creditCard: {
      color: theme.palette.primary.main,
      width: '27px',
      height: '27px',
      paddingRight: '27px',
    },
    paymentMethodSelectPaper: {
      borderRadius: '10px',
      padding: '14px',

      [theme.breakpoints.up('sm')]: {
        width: '70vw',
        maxWidth: 500,
      },
    },
    paymentMenuItem: {
      fontSize: '15px',
      borderRadius: '4px',
      border: '1px solid rgba(7, 0, 61, 0.15)',
      padding: '16px',
      marginBottom: '8px',
      background: '#fff !important',
      height: '80px',
    },
    checkIcon: {
      color: theme.palette.primary.main,
    },
    selectRoot: {
      background: '#fff !important',
      backgroundColor: '#fff !important',
    },
    mir: {
      marginRight: theme.spacing(2),
    },
    select: {
      padding: 6,
    },
    loaderContainer: {
      width: '100%',
      padding: 6,
      marginTop: 4,
      border: `1px solid ${theme.palette.text.secondary}`,
      borderRadius: theme.spacing(1),
    },
    iconContainer: {
      width: 'auto',
    },
    infoIcon: {
      width: 18,
      height: 18,
      marginLeft: theme.spacing(1),
    },
    tooltip: {
      padding: theme.spacing(3, 4),
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      borderRadius: 8,
    },
    arrow: {
      color: 'rgba(0, 0, 0, 0.8)',
    },
    tooltipText: {
      fontSize: '13px !important',
    },
    deleteIcon: {
      color: theme.palette.text.secondary,
    },
    iconButton: {
      marginLeft: '13px',
      padding: 5,
    },
    textBlock: {
      [theme.breakpoints.down('xs')]: {
        width: '75%',
      },
    },
    tooltipLink: {
      color: isSpecialBilling ? theme.palette.primary.main : '#18CDA6',
      textDecoration: 'none',
    },
    emailInput: {
      marginTop: 0,
    },

    mobileButtonWrapper: {
      position: 'sticky',
      padding: '12px 0 24px 0',

      [theme.breakpoints.up('sm')]: {
        padding: '12px 0 0 0',
      },
    },

    desktopButtonWrapper: {
      flex: 1,
      marginTop: 4,
    },

    leftBlockPaymentSelect: {
      flex: 1.3,
    },

    paymentSelectWrapper: {
      [theme.breakpoints.up('sm')]: {},
    },

    rail: {
      height: 3,
      color: 'rgba(0, 0, 0, 0.26)',
    },

    mark: {
      color: '#000000',
    },

    markActive: {
      backgroundColor: primaryMain,
    },

    track: {
      height: 3,
    },

    valueLabel: {
      top: 15,

      '& > span': {
        backgroundColor: 'transparent',
      },
    },

    prepaymentMeasure: {
      color: 'rgba(7, 0, 61, 0.5)',
    },

    prepaymentValue: {
      fontSize: '13px !important',
      whiteSpace: 'nowrap',
    },

    firstPrepaymentValue: {
      left: '100%',
    },

    lastPrepaymentValue: {
      left: 'calc(50% - 50px)',
    },
  }),
);

const EMPTY_CARD = 'card';
const SBP = 'sbp';
const DELETE_ICON = 'delete_icon';

interface PaymentFormDialogProps {
  open: boolean;
  merchantGroup: MerchantGroup;
  accountNumber: Account['number'];
  accountIsClosed?: boolean;
  onClose: () => void;
  paymentSource?: number;
}

const PaymentFormDialog: FC<PaymentFormDialogProps> = ({
  open,
  merchantGroup,
  accountNumber,
  accountIsClosed = false,
  onClose,
  paymentSource,
}) => {
  const { profile } = useOwnProfileStore();
  const { accrualList, load, loading, getMeanBalanceByMerchant } = useAccrualStore();

  const { getCommissionPaymentForm, getSbpEnabled } = useBankCommissionList(merchantGroup);

  const currentBalanceDate = merchantGroup.balanceDate
    ? moment(merchantGroup.balanceDate, 'X')
        .subtract(1, 'months')
        .unix()
    : moment()
        .month(moment().month() - 1)
        .date(2)
        .unix();

  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();

  const [qrPaymentModalOpen, setQrPaymentModalOpen] = useState(false);
  const [cardsLoading, toggleCardsLoading] = useState(true);
  const [paymentError, setPaymentError] = useState('');
  const [link, setLink] = useState('');
  const [isTransitionModalOpen, setTransitionModalState] = useState(false);
  const [saveCardCheckbox, toggleSaveCardCheckbox] = useState(true);
  const [fiscalCheckbox, toggleFiscalCheckbox] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<string | number>(EMPTY_CARD);
  const [cards, setCards] = useState<Card[]>([]);
  const [buttonDisabled, toggleButtonDisabled] = useState(false);
  const [isPrepaymentBannerOpen, setPrepaymentBannerState] = useState(true);
  const { paymentGetLink, loadCards, payByCard, detachCard } = usePaymentStore();
  const [meanAccrual, setMeanAccrual] = useState(0);
  const [prepaymentStep, setPrepaymentStep] = useState(0);
  const [isOverPrepayment, setOverPrepayment] = useState(false);
  const [metrikaUpload, setMetrikaState] = useState(false);
  const [isSbpEnabled, setIsSbpEnabled] = useState(false);

  const _balance = useMemo(
    () => (merchantGroup.balance && merchantGroup.balance > 0 ? merchantGroup.balance : 0),
    [merchantGroup.balance],
  );

  const isNeedToShowEmailField = useMemo(() => fiscalCheckbox, [fiscalCheckbox]);

  const hasCard = useMemo(() => {
    const card = cards.find(item => item.tokenId === paymentMethod);

    return !!card;
  }, [cards, paymentMethod]);

  const emailFieldLabel = `Email ${
    merchantGroup.fiscal ? 'для получения фискального чека' : 'для получения информации о платеже'
  }`;

  const handleTransitionModalClose = useCallback(() => {
    setTransitionModalState(false);
  }, []);

  const qrPaymentWindowCloseHandler = (): void => {
    setQrPaymentModalOpen(false);
  };

  const deleteCardFromList = (tokenId: number): void => {
    const card = cards.find(item => item.tokenId === tokenId);

    if (card) {
      const newCardsArray = [...cards];
      newCardsArray.splice(newCardsArray.indexOf(card), 1);
      setCards(newCardsArray);
      if (newCardsArray.length) {
        setPaymentMethod(newCardsArray[0].tokenId);
      } else {
        setPaymentMethod(EMPTY_CARD);
      }
    }
  };

  const refreshPaymentMethod = (): void => {
    setCards([...cards]);

    if (cards.length) {
      setPaymentMethod(cards[0].tokenId);
    } else {
      setPaymentMethod(EMPTY_CARD);
    }
  };

  const detachUserCard = (tokenId: number): void => {
    detachCard({ tokenId }).then(detachResult => {
      if (detachResult) {
        deleteCardFromList(tokenId);
      }
    });
  };

  const loadUserCards = useCallback(async (): Promise<void> => {
    const userCards = await loadCards({
      filter: { [CardKeys.MERCHANT_GROUP_ID]: merchantGroup.id },
      limit: 0,
      offset: 0,
    });

    if (userCards.length) {
      setCards(userCards);
      setPaymentMethod(userCards[0].tokenId);
    }

    toggleCardsLoading(false);
  }, [loadCards, merchantGroup]);

  const onSubmit = (
    { amount, date, emailForFiscalReceipt }: FormProps,
    { setFieldError }: FormikHelpers<FormProps>,
  ): void => {
    setPaymentError('');

    if (merchantGroup.paymentMin && Number(amount) < merchantGroup.paymentMin) {
      setFieldError(
        'amount',
        `Минимально возможный платеж ${Number(merchantGroup.paymentMin)} руб.`,
      );

      return;
    }

    if (merchantGroup?.paymentMax && Number(amount) > merchantGroup.paymentMax) {
      setFieldError(
        'amount',
        `Максимально возможный платеж ${Number(merchantGroup.paymentMax)} руб.`,
      );

      return;
    }

    toggleButtonDisabled(true);

    if (merchantGroup.providerId && accountNumber && paymentMethod !== 'sbp') {
      if (hasCard && typeof paymentMethod === 'number' && merchantGroup.tokenizationEnabled) {
        payByCard({
          providerId: merchantGroup.providerId,
          merchantGroupId: merchantGroup.id,
          accountNumber,
          amount: Number(amount),
          date: Number(date),
          tokenId: paymentMethod,
          source: config.privateActionSource,
          ...(isNeedToShowEmailField ? { emailForFiscalReceipt } : {}),
        })
          .then((paymentId): void => {
            onClose();
            if (paymentId) {
              history.push('/payment_status/' + paymentId, {
                accountNumber,
                providerId: merchantGroup.providerId,
                amount,
              });
            }
          })
          .finally(() => toggleButtonDisabled(false));
      } else {
        paymentGetLink({
          providerId: merchantGroup.providerId,
          merchantGroupId: merchantGroup.id,
          accountNumber,
          amount: Number(amount),
          date: Number(date),
          bindPaymentToken: merchantGroup.tokenizationEnabled && saveCardCheckbox,
          paymentSource,
          ...(fiscalCheckbox ? { emailForFiscalReceipt } : {}),
        })
          .then(({ value, error }) => {
            if (value) {
              yM.goals.sendFormPayment();
              if (openTransitionModal) {
                setLink(value);
                setTransitionModalState(true);
              } else {
                window.open(value);
              }
            }

            if (error) {
              setPaymentError(error);
            }
          })
          .finally(() => toggleButtonDisabled(false));
      }
    } else if (paymentMethod === 'sbp') {
      setQrPaymentModalOpen(true);
      toggleButtonDisabled(false);
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik<FormProps>({
    initialValues: {
      amount: 0,
      date: currentBalanceDate,
      emailForFiscalReceipt: profile?.email || '',
    },
    validationSchema: FormSchema,
    onSubmit,
  });

  const prepaymentValue = useMemo(() => {
    if (values.amount - _balance <= 0) {
      return;
    }

    return (values.amount - _balance).toFixed(2);
  }, [_balance, values.amount]);

  const _prepaymentValue = useMemo(() => {
    if (!prepaymentValue) {
      return [null];
    }

    const [intPart, decimalPart] = prepaymentValue.split('.');
    return [intPart, decimalPart];
  }, [prepaymentValue]);

  useEffect(() => {
    if (accrualList && accrualList.length) {
      const accrualOverThreeMonth = getMeanBalanceByMerchant(merchantGroup.id, 3);

      if (accrualOverThreeMonth.length) {
        const _meanAccrual = Number(
          floatValueFormatter(
            accrualOverThreeMonth.reduce((item, acc) => item + acc, 0) /
              accrualOverThreeMonth.length,
            2,
          ),
        );

        if (_meanAccrual < 0) {
          return setMeanAccrual(0);
        }
        return setMeanAccrual(_meanAccrual);
      }

      return setMeanAccrual(0);
    }

    const func = async (): Promise<void> => {
      if (accountNumber && merchantGroup.providerId) {
        await load({
          filter: {
            [AccrualKeys.ACCOUNT_NUMBER]: { $eq: accountNumber },
            [AccrualKeys.PROVIDER_ID]: { $eq: merchantGroup.providerId },
            [AccrualKeys.START_DATE]: Number(
              moment()
                .subtract(3, 'month')
                .format('X'),
            ),
            [AccrualKeys.END_DATE]: Number(moment().format('X')),
          },
        });
      }
    };

    func();
  }, [accrualList, merchantGroup, accountNumber]);

  useEffect(() => {
    if (_balance) {
      setFieldValue('amount', _balance);
    }
  }, [_balance, open, setFieldValue]);

  useEffect(() => {
    if (merchantGroup.tokenizationEnabled) loadUserCards();
  }, [loadUserCards, merchantGroup]);

  useEffect(() => {
    setIsSbpEnabled(getSbpEnabled(values.amount));
  }, [values.amount]);

  useEffect(() => {
    refreshPaymentMethod();
  }, [isSbpEnabled]);

  const handleClose = useCallback(() => {
    onClose();
    resetForm();
    setPrepaymentStep(0);
    setOverPrepayment(false);
    setPrepaymentBannerState(true);
  }, [onClose, resetForm]);

  const handleLink = useCallback(() => {
    handleClose();
    handleTransitionModalClose();
  }, [handleClose, handleTransitionModalClose]);

  const changePaymentMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    const evt = parseInt(event.target.value);

    if (isNaN(evt)) {
      setPaymentMethod(event.target.value as string);
    } else {
      setPaymentMethod(evt);
    }
  };

  const handleSumChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.target.value = floatValueFormatter(event.target.value, 2, 5);
      handleChange(event);

      const newStep = Number(
        floatValueFormatter((Number(event.target.value) - _balance) / meanAccrual, 1),
      );

      setOverPrepayment(false);

      if (newStep <= 0) {
        setPrepaymentStep(0);
      } else if (newStep > 6) {
        setOverPrepayment(true);
        setPrepaymentStep(6);
      } else {
        setPrepaymentStep(newStep);
      }
    },
    [handleChange, meanAccrual, _balance],
  );

  const handleClosePrepayment = useCallback(() => {
    setPrepaymentBannerState(false);
  }, []);

  const handlePrepaymentStepChange = (
    event: React.ChangeEvent<{}>,
    newValue: number | number[],
  ): void => {
    if (!metrikaUpload) {
      yM.goals.sliderPrepayment();
      setMetrikaState(true);
    }

    if (Array.isArray(newValue)) {
      return;
    }
    setPrepaymentStep(newValue);
    setFieldValue('amount', floatValueFormatter(_balance + newValue * meanAccrual, 2));
  };

  const formattedAmount = new Intl.NumberFormat('ru-RU').format(values.amount);

  const buttonName = buttonDisabled
    ? 'Подождите, идет загрузка'
    : isXs && values.amount > 0
    ? `Оплатить ${formattedAmount} ₽`
    : 'Оплатить';

  const FiscalTooltip = (
    <Tooltip
      interactive
      title={
        <Typography className={classes.tooltipText}>
          {merchantGroup.fiscal ? (
            <span>
              Информация о платеже будет отправлена на указанный вами email. Фискальный документ
              (кассовый чек) направляется Оператором фискальных данных (процесс отправки может
              занимать несколько дней). В случае, если плательщик не получил фискальный документ,
              ему необходимо обратиться с запросом к получателю платежа.
              <br />
              <a
                className={classes.tooltipLink}
                href="https://payment.help.opencity.pro/ka_receipt "
                target={'__blank'}
              >
                Подробнее
              </a>
            </span>
          ) : (
            <span>
              Информация о платеже будет отправлена на указанный вами email. Для получения
              фискального документа (кассового чека) необходимо обращаться к получателю платежа.
              <br />
              <a
                className={classes.tooltipLink}
                href="https://payment.help.opencity.pro/ka_receipt "
                target={'__blank'}
              >
                Подробнее
              </a>
            </span>
          )}
        </Typography>
      }
      placement={'bottom-start'}
    >
      <InfoOutlinedIcon className={classes.infoIcon} />
    </Tooltip>
  );

  const renderPaymentCommission = useCallback(() => {
    return getCommissionPaymentForm(values.amount);
  }, [values.amount]);

  const renderDateField = (): ReactNode => {
    const handleChangeDateTimeField = (value: DateType | null): void => {
      if (value) {
        setFieldValue('date', value.format('X'));
      }
    };

    return (
      <Grid item>
        <DateField
          value={values.date}
          name="date"
          error={errors.date}
          touched={touched.date}
          views={['year', 'month']}
          openTo="month"
          label="Период оплаты"
          InputLabelProps={{
            className: classes.label,
          }}
          clearable={false}
          minDate={moment()
            .year(Number(moment().format('YYYY')) - 10)
            .month(moment().month() - 1)
            .date(1)
            .toDate()}
          maxDate={moment()
            .month(moment().month() - 1)
            .date(1)
            .toDate()}
          disablePast={false}
          onChange={handleChangeDateTimeField}
          onBlur={handleBlur}
          format={'MMMM YYYY'}
          endAdornment={<Icon className={classes.datepickerIcon} icon="calendar" />}
        />
      </Grid>
    );
  };

  const renderPaymentMethodFormControlLabels = (value: Card): ReactNode => {
    switch (value.paymentSystem) {
      case 'VISA':
        return (
          <FormControlLabel
            value={value.tokenId}
            control={<Radio color="primary" />}
            label={
              <div
                style={{
                  marginLeft: '30px',
                  height: '48px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  fontSize: '15px',
                }}
              >
                <div className={classNames(classes.imgContainer, classes.mir)}>
                  <img className={classes.img} alt={'Visa'} src="/images/visa.svg" />
                </div>
                {value.mask}
                <IconButton
                  className={classes.iconButton}
                  onClick={(): void => {
                    detachUserCard(value.tokenId);
                  }}
                >
                  <DeleteOutlineIcon className={classNames(classes.deleteIcon, DELETE_ICON)} />
                </IconButton>
              </div>
            }
            labelPlacement="start"
            style={{
              border: '1px solid rgba(7, 0, 61, 0.15)',
              borderRadius: '8px',
              width: '100%',
              padding: 0,
              margin: 0,
              marginBottom: '16px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          />
        );

      case 'MASTERCARD':
        return (
          <FormControlLabel
            value={value.tokenId}
            control={<Radio color="primary" />}
            label={
              <div
                style={{
                  marginLeft: '30px',
                  height: '48px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  fontSize: '15px',
                }}
              >
                <div className={classNames(classes.imgContainer, classes.mir)}>
                  <img className={classes.img} alt={'Master Card'} src="/images/master-card.svg" />
                </div>
                {value.mask}
                <IconButton
                  className={classes.iconButton}
                  onClick={(): void => {
                    detachUserCard(value.tokenId);
                  }}
                >
                  <DeleteOutlineIcon className={classNames(classes.deleteIcon, DELETE_ICON)} />
                </IconButton>
              </div>
            }
            labelPlacement="start"
            style={{
              border: '1px solid rgba(7, 0, 61, 0.15)',
              borderRadius: '8px',
              width: '100%',
              padding: 0,
              margin: 0,
              marginBottom: '16px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          />
        );

      case 'MIR':
        return (
          <FormControlLabel
            value={value.tokenId}
            control={<Radio color="primary" />}
            label={
              <div
                style={{
                  marginLeft: '30px',
                  height: '48px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  fontSize: '15px',
                }}
              >
                <div className={classNames(classes.imgContainer, classes.mir)}>
                  <img className={classes.img} alt={'Mir'} src="/images/mir.svg" />
                </div>
                {value.mask}
                <IconButton
                  className={classes.iconButton}
                  onClick={(): void => {
                    detachUserCard(value.tokenId);
                  }}
                >
                  <DeleteOutlineIcon className={classNames(classes.deleteIcon, DELETE_ICON)} />
                </IconButton>
              </div>
            }
            labelPlacement="start"
            style={{
              border: '1px solid rgba(7, 0, 61, 0.15)',
              borderRadius: '8px',
              width: '100%',
              padding: 0,
              margin: 0,
              marginBottom: '16px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          />
        );

      default:
        return (
          <FormControlLabel
            value={value.tokenId}
            control={<Radio color="primary" />}
            label={
              <div
                style={{
                  marginLeft: '30px',
                  height: '48px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  fontSize: '15px',
                }}
              >
                <CreditCardIcon className={classes.creditCard} />
                {value.mask}
                <IconButton
                  className={classes.iconButton}
                  onClick={(): void => {
                    detachUserCard(value.tokenId);
                  }}
                >
                  <DeleteOutlineIcon className={classNames(classes.deleteIcon, DELETE_ICON)} />
                </IconButton>
              </div>
            }
            labelPlacement="start"
            style={{
              border: '1px solid rgba(7, 0, 61, 0.15)',
              borderRadius: '8px',
              width: '100%',
              padding: 0,
              margin: 0,
              marginBottom: '16px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          />
        );
    }
  };

  const renderSubmitButton = (
    <Button
      className={classes.button}
      color="primary"
      variant="contained"
      type="submit"
      form={FORM_ID}
      fullWidth={true}
      disabled={buttonDisabled}
    >
      {buttonName}
    </Button>
  );

  const renderPaymentMethodDefault = (
    <Hidden xsDown>
      <Grid item container direction="column" spacing={6}>
        <Grid item>
          <PaymentMethods />
        </Grid>
      </Grid>
    </Hidden>
  );

  const renderPaymentMethodSelect = (
    <Grid item className={classes.paymentSelectWrapper}>
      {!cardsLoading && (
        <Typography variant="h4" style={{ marginBottom: '9px', fontSize: '16px' }}>
          {Boolean(cards.length) || isSbpEnabled ? 'Выберите способ оплаты:' : 'Cпособ оплаты: '}
        </Typography>
      )}

      <Grid container wrap="nowrap" spacing={3} direction={'column'}>
        {cardsLoading ? (
          <Grid item container className={classes.leftBlockPaymentSelect}>
            <Skeleton height={48} />
          </Grid>
        ) : (
          <>
            <Grid item container className={classes.leftBlockPaymentSelect}>
              {
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={paymentMethod}
                  onChange={changePaymentMethod}
                  style={{ width: '100%', padding: 0 }}
                >
                  {cards.map(card => renderPaymentMethodFormControlLabels(card))}

                  {isSbpEnabled && (
                    <FormControlLabel
                      value={SBP}
                      control={<Radio color="primary" />}
                      label={
                        <div
                          style={{
                            marginLeft: '30px',
                            height: '48px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            fontSize: '15px',
                          }}
                        >
                          <div className={classNames(classes.imgContainer, classes.mir)}>
                            <img
                              className={classes.sbpImg}
                              alt={'SBP'}
                              src="/images/sbp_logo.png"
                            />
                          </div>
                          Оплата через СБП
                        </div>
                      }
                      labelPlacement="start"
                      style={{
                        border: '1px solid rgba(7, 0, 61, 0.15)',
                        borderRadius: '8px',
                        width: '100%',
                        padding: 0,
                        margin: 0,
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    />
                  )}

                  <FormControlLabel
                    value={EMPTY_CARD}
                    control={<Radio color="primary" />}
                    label={
                      <div
                        style={{
                          marginLeft: '24px',
                          height: '48px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          fontSize: '15px',
                        }}
                      >
                        <CreditCardIcon className={classes.creditCard} />
                        Картой
                      </div>
                    }
                    labelPlacement="start"
                    style={{
                      border: '1px solid rgba(7, 0, 61, 0.15)',
                      borderRadius: '8px',
                      width: '100%',
                      padding: 0,

                      margin: 0,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  />
                </RadioGroup>
              }
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );

  const renderPaymentForm: ReactNode = (
    <Grid item>
      <TextField
        fullWidth={true}
        label="Сумма к оплате"
        name="amount"
        value={values.amount}
        error={Boolean(touched.amount && errors.amount)}
        helperText={
          Boolean(touched.amount && errors.amount) ? errors.amount : renderPaymentCommission()
        }
        onChange={handleSumChange}
        onBlur={handleBlur}
        InputLabelProps={{
          className: classes.label,
        }}
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
        type="number"
      />
    </Grid>
  );

  const renderPrepaymentBanner: ReactNode = (
    <Grid item>
      <Banner isVisible={isPrepaymentBannerOpen} onClick={handleClosePrepayment}>
        <AlternativeWrapper loading={loading} view={<PrepaymentSkeleton />}>
          <PrepaymentBanner
            enablePrepayment={Boolean(meanAccrual)}
            step={Math.trunc(prepaymentStep)}
            isOverPrepayment={isOverPrepayment}
          >
            <Slider
              value={prepaymentStep}
              onChange={handlePrepaymentStepChange}
              marks
              defaultValue={0}
              step={1}
              min={0}
              max={6}
              valueLabelDisplay={prepaymentValue ? 'on' : 'off'}
              valueLabelFormat={(): ReactNode => (
                <Typography variant="body2" color="textPrimary" className={classes.prepaymentValue}>
                  + {_prepaymentValue[0]}
                  {_prepaymentValue[1] && (
                    <span className={classes.prepaymentMeasure}>, {_prepaymentValue[1]}</span>
                  )}
                  {Currency.ruble}
                </Typography>
              )}
              classes={{
                rail: classes.rail,
                mark: classes.mark,
                markActive: classes.markActive,
                track: classes.track,
                valueLabel: classNames(
                  classes.valueLabel,
                  prepaymentStep < 0.3 && classes.firstPrepaymentValue,
                  prepaymentStep > 5.7 && classes.lastPrepaymentValue,
                ),
              }}
            />
          </PrepaymentBanner>
        </AlternativeWrapper>
      </Banner>
    </Grid>
  );

  const renderPublicOffer: ReactNode = (
    <Grid item className={classes.paymentInfo}>
      <Typography variant="caption" component="p" color="textSecondary">
        Оплачивая, вы соглашаетесь с условиями{' '}
        <Link className={classes.link} target="_blank" href={merchantGroup.offerLink || ''}>
          оферты.
        </Link>{' '}
        Принимаются карты только российских банков
      </Typography>
    </Grid>
  );

  const renderTransitionModal: ReactNode = (
    <TransitionModal
      isOpen={isTransitionModalOpen}
      link={link}
      onClose={handleTransitionModalClose}
      handleLink={handleLink}
    />
  );

  const renderSaveCardCheckBox: ReactNode = !hasCard && (
    <Grid
      item
      container
      alignItems={'center'}
      justify={'flex-start'}
      className={classes.saveCardBlock}
    >
      <Grid
        item
        onClick={(): void => {
          toggleSaveCardCheckbox(!saveCardCheckbox);
        }}
      >
        <Checkbox checked={saveCardCheckbox} />
      </Grid>
      <Grid
        item
        className={classes.textBlock}
        onClick={(): void => {
          toggleSaveCardCheckbox(!saveCardCheckbox);
        }}
      >
        <Typography variant="body2">Сохранить карту. Это безопасно</Typography>
      </Grid>
      <Grid item>
        <Tooltip
          title={
            <Typography className={classes.tooltipText}>
              Данные карты надёжно защищены и зашифрованы по международному стандарту безопасности
              PCI DSS.
            </Typography>
          }
          placement={'bottom-start'}
        >
          <InfoOutlinedIcon className={classes.infoIcon} />
        </Tooltip>
      </Grid>
    </Grid>
  );

  const renderFiscalCheckbox: ReactElement = (
    <Grid
      container
      item
      alignItems={'center'}
      justify={'flex-start'}
      className={classes.saveCardBlock}
    >
      <Grid
        item
        onClick={(): void => {
          toggleFiscalCheckbox(!fiscalCheckbox);
        }}
      >
        <Checkbox checked={fiscalCheckbox} />
      </Grid>
      <Grid
        item
        className={classes.textBlock}
        onClick={(): void => {
          toggleFiscalCheckbox(!fiscalCheckbox);
        }}
      >
        <Typography variant="body2">
          {merchantGroup.fiscal
            ? 'Получить фискальный чек на email'
            : 'Получить информацию о платеже на email'}
        </Typography>
      </Grid>

      <Grid item>{FiscalTooltip}</Grid>
    </Grid>
  );

  const renderError: ReactNode = Boolean(paymentError) && (
    <Grid item>
      <Alert severity="error">{paymentError}</Alert>
    </Grid>
  );

  const helperText = merchantGroup.fiscal
    ? 'На указанный email также будет отправлена информация о платеже'
    : undefined;

  return (
    <Dialog
      open={open}
      maxWidth={'sm'}
      fullWidth={true}
      fullScreen={isXs}
      onClose={handleClose}
      classes={{ paper: classes.dialogOverrideRoot }}
    >
      <DialogTitle>
        <Grid container={true} justify={'space-between'} alignItems={'center'}>
          <Grid item={true}>Оплата услуг ЖКХ</Grid>
          <Grid item={true}>
            <IconButton onClick={handleClose}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <Grid
          className={classes.dialogContainer}
          id={FORM_ID}
          container={true}
          direction="column"
          spacing={4}
          component="form"
          onSubmit={handleSubmit}
          noValidate
        >
          {renderPaymentForm}

          {accountIsClosed ? null : renderPrepaymentBanner}

          {renderDateField()}

          {renderPublicOffer}

          {!isSpecialBilling && renderFiscalCheckbox}

          {isNeedToShowEmailField && (
            <Grid item>
              <EmailField
                className={classes.emailInput}
                label={emailFieldLabel}
                value={values.emailForFiscalReceipt}
                onChange={handleChange}
                helperText={errors.emailForFiscalReceipt || helperText}
                error={errors.emailForFiscalReceipt}
                name={'emailForFiscalReceipt'}
                InputLabelProps={{
                  className: classes.label,
                }}
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
              />
            </Grid>
          )}

          <Hidden xsDown>{renderError}</Hidden>

          {!merchantGroup.tokenizationEnabled
            ? renderPaymentMethodDefault
            : renderPaymentMethodSelect}

          {merchantGroup.tokenizationEnabled &&
            !cardsLoading &&
            paymentMethod !== 'sbp' &&
            renderSaveCardCheckBox}
        </Grid>

        <Hidden smUp>{renderError}</Hidden>

        {renderTransitionModal}

        <div className={classes.mobileButtonWrapper}>{renderSubmitButton}</div>
      </DialogContent>

      <QrPaymentWindow
        emailForFiscalReceipt={fiscalCheckbox ? values.emailForFiscalReceipt : ''}
        paymentSource={paymentSource ? paymentSource : 0}
        merchantGroup={merchantGroup.id}
        open={qrPaymentModalOpen}
        close={qrPaymentWindowCloseHandler}
        amount={Number(values.amount)}
      />
    </Dialog>
  );
};

export default PaymentFormDialog;
