import React, { FC, useState, useRef, useEffect, useCallback, useMemo } from 'react';
import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Checkbox,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import classNames from 'classnames';
import { useFormik, FormikHelpers } from 'formik';

import { Tooltip } from '@core/components/Tooltip';
import EmailField from '@components/Fields/EmailField';
import { useUserStore } from './hooks';
import { FormProps, FormSchema } from './FormSchema';
import SuccessModal from './SuccessModal';
import AlreadyExistModal from './AlreadyExistModal';
import config from '@core/constants/config';

interface OrganizationData {
  organizationId: number;
  organizationName: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    signUp: {
      marginTop: theme.spacing(10),
      background: 'rgba(243, 246, 254, 0.5)',
      padding: theme.spacing(10),
      textAlign: 'center',
    },
    title: {
      color: '#07003D',
      marginBottom: theme.spacing(7),
    },
    info: {
      color: 'rgba(7, 0, 61, 0.5)',
    },
    emailField: {
      maxWidth: '672px',
      border: 'none',
      marginBottom: theme.spacing(7),
      borderRadius: theme.spacing(2),
      minHeight: '75px',
    },
    emailFieldInput: {
      marginTop: 0,
      background: '#fff',
      boxShadow: '0px 8px 16px rgba(68, 84, 107, 0.1)',
    },
    notchedOutline: {
      borderColor: '#fff',
      transition: '0.5s',
    },
    signUpButton: {
      background: '#12A7D8',
      minWidth: 220,
      color: '#fff',
      fontWeight: 500,
      '&:hover': {
        background: '#12A7D8',
      },
      '&:disabled': {
        opacity: '0.5',
        background: '#12A7D8',
      },
    },
    loader: {
      color: '#fff',
      width: '20px !important',
      height: '20px !important',
      padding: '2.5px',
    },
    buttonText: {
      color: '#fff',
      height: '25px',
    },
    inlineText: {
      display: 'inline-block',
    },
    tooltipIcon: {
      height: '15px',
      width: '15px',
      paddingLeft: '5px',
      marginBottom: '-2px',
    },
    mobileBlock: {
      marginTop: theme.spacing(6),
    },
    mobileCheckboxText: {
      width: 'calc(100% - 42px)',
    },
    mobileEmailField: {
      minHeight: '72px',
    },
    emailFieldLabel: {
      color: 'rgba(7, 0, 61, 0.5)',
    },
    emailFieldDescription: {
      marginBottom: '30px',
    },
    signUpMobileButton: {
      height: '43px',
    },
  }),
);
interface SignUpProps {
  focus: boolean;
  onBlur: () => void;
  organizationData: OrganizationData | null;
  accountNumber: string | null;
  providerId: string | null;
}

interface SignUpTooltipProps {
  isMobile?: boolean;
}

const SignUpTooltip: FC<SignUpTooltipProps> = ({ isMobile }) => {
  const classes = useStyles();

  const Title = (
    <div>
      <p>В личном кабинете вам будут доступны:</p>
      <p>- оплата ЖКУ любым удобным способом;</p>
      <p>- передача показаний счетчиков;</p>
      <p>- история платежей и начислений;</p>
      <p>- электронная квитанция;</p>
      <p>- отправка обращений в обслуживающую организацию.</p>
      <p>Это удобно, надежно и безопасно!</p>
    </div>
  );

  return (
    <div className={classes.inlineText}>
      <Grid container={true}>
        {isMobile && (
          <Grid item={true}>
            <div>кабинете</div>
          </Grid>
        )}

        <Grid item={true}>
          <Tooltip title={Title}>
            <InfoOutlinedIcon className={classes.tooltipIcon} />
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
};

const SignUp: FC<SignUpProps> = ({
  focus,
  onBlur,
  organizationData,
  accountNumber,
  providerId,
}) => {
  const classes = useStyles();
  const emailRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { userCreate } = useUserStore();
  const [successEmail, setSuccessEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [existError, setExistError] = useState(false);
  const [openMobileForm, setOpenMobileForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const sourceId = useMemo(
    () => (isSm ? config.adaptiveFastPaymentActionSource : config.fastPaymentActionSource),
    [isSm],
  );

  const showSuccessModal = (email: string): void => {
    setSuccessEmail(email);
    setSuccess(true);
  };

  const hideSuccessModal = (): void => {
    setSuccess(false);
    setTimeout(() => {
      setSuccessEmail('');
    }, 500);
  };

  const hideAlreadyExistModal = (): void => {
    setExistError(false);
    setTimeout(() => {
      setSuccessEmail('');
    }, 500);
  };

  const signUpByEmail = (values: FormProps, helpers: FormikHelpers<FormProps>): void => {
    setLoading(true);

    const { email } = values;
    const { setFieldError } = helpers;

    if (email.length === 0) {
      setFieldError('email', 'Введите email');
      setLoading(false);
      return;
    } else {
      userCreate({
        email,
        accountNumber: accountNumber || undefined,
        sourceId,
        ...(organizationData
          ? {
              organizationId: organizationData.organizationId,
              organizationName: organizationData.organizationName,
            }
          : {
              providerId: providerId ? Number(providerId) : undefined,
            }),
      })
        .then(res => {
          const [result, error] = res;
          if (result) {
            showSuccessModal(email);
          } else {
            if (error === 'ALREADY_EXIST') {
              setSuccessEmail(email);
              setExistError(true);
              return;
            }
            setFieldError('email', error);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const { values, errors, handleChange, submitForm } = useFormik<FormProps>({
    initialValues: {
      email: '',
    },
    validationSchema: FormSchema,
    onSubmit: signUpByEmail,
  });

  const toggleCheckBox = useCallback((): void => {
    setOpenMobileForm(openMobileForm => !openMobileForm);
  }, []);

  const EmailFieldProps = {
    error: errors.email,
    helperText: errors.email || '',
    label: '',
    value: values.email,
    className: classes.emailField,
    placeholder: 'Введите ваш email',
    name: 'email',
    onChange: handleChange,
    customInputProps: {
      endAdornment: (
        <Button
          variant="contained"
          className={classes.signUpButton}
          size="small"
          disabled={loading}
          type="submit"
        >
          {loading ? (
            <CircularProgress className={classes.loader} />
          ) : (
            <div className={classes.buttonText}>Зарегистрироваться</div>
          )}
        </Button>
      ),
      classes: {
        root: classes.emailFieldInput,
        notchedOutline: classes.notchedOutline,
      },
      inputRef: emailRef,
      onBlur,
    },
  };

  useEffect(() => {
    if (focus && emailRef.current) {
      emailRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
      setTimeout(() => {
        emailRef?.current && emailRef.current.focus();
      }, 700);
    }
  }, [focus, emailRef]);

  const DesktopSingUp = (
    <div className={classes.signUp}>
      <Grid direction="column" container={true}>
        <Grid item={true}>
          <Typography variant="h2" className={classes.title}>
            Быстрая регистрация в личном кабинете
          </Typography>
        </Grid>
        <Grid item={true}>
          <EmailField {...EmailFieldProps} />
        </Grid>
        <Grid item={true}>
          <Typography variant="body2" className={classes.info}>
            <div className={classes.inlineText}>
              На указанный email мы отправим письмо с данными для доступа в личный кабинет{' '}
            </div>
            <SignUpTooltip />
          </Typography>
        </Grid>
      </Grid>
    </div>
  );

  const MobileSignUp = (
    <div className={classes.mobileBlock}>
      <Grid container={true} direction="column">
        <Grid item={true}>
          <Grid container={true} alignItems="center" id="register_checkbox">
            <Grid item={true}>
              <Checkbox
                checked={openMobileForm}
                onChange={toggleCheckBox}
                style={{ paddingLeft: 0 }}
              />
            </Grid>
            <Grid item={true} className={classes.mobileCheckboxText}>
              <Typography variant="body2" className={classes.info}>
                <div className={classes.inlineText}>
                  Хочу зарегистрироваться в личном <SignUpTooltip isMobile={true} />
                </div>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true}>
          {openMobileForm && (
            <Grid container={true} direction="column">
              <Grid item={true}>
                <Typography className={classes.emailFieldLabel} variant="body2">
                  Введите ваш email
                </Typography>
              </Grid>
              <Grid item={true}>
                <EmailField
                  label=""
                  helperText={errors.email || ''}
                  error={errors.email}
                  value={values.email}
                  className={classes.mobileEmailField}
                  onChange={handleChange}
                  name={'email'}
                />
              </Grid>
              <Grid item={true}>
                <Typography
                  className={classNames(classes.emailFieldLabel, classes.emailFieldDescription)}
                  variant="body2"
                >
                  На указанный email мы отправим письмо с данными для доступа в личный кабинет
                </Typography>
              </Grid>
              <Grid item={true}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth={true}
                  type={'submit'}
                  className={classes.signUpMobileButton}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress className={classes.loader} />
                  ) : (
                    <span>Зарегистрироваться</span>
                  )}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );

  return (
    <form
      onSubmit={(e: React.FormEvent): void => {
        e.preventDefault();
        submitForm();
      }}
    >
      {isXs ? MobileSignUp : DesktopSingUp}
      <SuccessModal open={success} email={successEmail} onClose={hideSuccessModal} />
      <AlreadyExistModal open={existError} email={successEmail} onClose={hideAlreadyExistModal} />
    </form>
  );
};

export default SignUp;
