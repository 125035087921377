import React, {
  FC,
  ReactElement,
  ReactNode,
  Suspense,
  useEffect,
  useMemo,
  lazy,
  useState,
} from 'react';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import Preloader from 'oc-preloader/lib';
import OpencityOperations from '@services/opencity/methods';
import BillingMethods from '@services/billing/methods';
import Snackbar from '@components/Snackbar';
import IsAllowed from '@components/IsAllowed';
import config from '@constants/config';
import * as routes from '@public/constants/routes';
import Layout from '@public/components/Layout';
import Theme from '@public/components/Theme';
import { useSnackbarStore, useOperationStore, useSignStore } from './hooks';
import { isBilling, isSpecialBilling } from '@constants/project';
import OldVersionAlert from '@core/components/OldVersionAlert';
import Main from './pages/Main';
import { CodeConfirmed } from '@public/components';
import { MobileAppPromotion } from '@core/components';

const Order = lazy(() => import('./pages/Order'));

const Shutdowns = lazy(() => import('./pages/Shutdowns'));

const Ratings = lazy(() => import('./pages/Ratings'));

const HelpCenter = lazy(() => import('./pages/HelpCenter'));

const SignUp = lazy(() => import('./pages/SignUp'));

const SignIn = lazy(() => import('./pages/SignIn'));

const PasswordRecovery = lazy(() => import('./pages/PasswordRecovery'));

const PasswordChange = lazy(() => import('./pages/PasswordChange'));

const Landing = lazy(() => import('./pages/Landing'));

const FastPayment = lazy(() => import('./pages/FastPayment'));

const Acquiring = lazy(() => import('./pages/Acquiring'));

const Unsubscribe = lazy(() => import('../common/pages/Unsubscribe'));

const useStyles = makeStyles(
  createStyles({
    preloader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

const Root: FC = () => {
  const classes = useStyles();

  const history = useHistory();
  const { pathname } = useLocation();

  const { cleanUp, message, type } = useSnackbarStore();
  const { loadOperation, operations } = useOperationStore();
  const { codeConfirmed, cleanConfirmationStatus } = useSignStore();

  const [oldVersionAlert, setOldVersionAlert] = useState(true);
  const [fromApp, setFromApp] = useState(false);

  const isDisplayBanner = useMemo(() => oldVersionAlert && isBilling, [oldVersionAlert]);

  const handleOldVersionAlert = (): void => setOldVersionAlert(false);

  const contains = (originString: string, searchSubstring: string): boolean =>
    Boolean(originString.indexOf(searchSubstring) + 1);

  useEffect(() => {
    loadOperation();

    history.listen(() => window.scroll(0, 0));

    return cleanUp;
  }, [history, cleanUp, loadOperation]);

  useEffect(() => {
    const location = window.location;

    if (contains(location.search, 'providerId') && contains(location.search, 'accountNumber')) {
      setFromApp(true);
    }
  }, [location]);

  const preloaderElement: ReactElement = (
    <Preloader
      color={isSpecialBilling ? '#3167F3' : ''}
      className={classes.preloader}
      height="100%"
    />
  );

  const routeDefaultElement: ReactElement = (
    <Redirect
      to={config.defaultPublicRoute ? `${routes.ROOT}${config.defaultPublicRoute}` : routes.ROOT}
    />
  );

  const renderMain = (): ReactElement => <Main pathname={pathname} />;

  const renderOrder = (): ReactElement => (
    <Suspense fallback={preloaderElement}>
      <Order />
    </Suspense>
  );

  const renderShutdowns = (): ReactElement => (
    <Suspense fallback={preloaderElement}>
      <Shutdowns />
    </Suspense>
  );

  const renderRatings = (): ReactElement => (
    <Suspense fallback={preloaderElement}>
      <Ratings />
    </Suspense>
  );

  const renderHelpCenter = (): ReactElement => (
    <Suspense fallback={preloaderElement}>
      <HelpCenter />
    </Suspense>
  );

  const renderHelpSignUp = (): ReactElement => (
    <Suspense fallback={preloaderElement}>
      <SignUp />
    </Suspense>
  );

  const renderHelpSignIn = (): ReactElement => (
    <Suspense fallback={preloaderElement}>
      <SignIn />
    </Suspense>
  );

  const renderPasswordRecovery = (): ReactElement => (
    <Suspense fallback={preloaderElement}>
      <PasswordRecovery />
    </Suspense>
  );

  const renderPasswordChange = (): ReactElement => (
    <Suspense fallback={preloaderElement}>
      <PasswordChange />
    </Suspense>
  );

  const renderLanding = (): ReactElement => (
    <Suspense fallback={preloaderElement}>
      <Landing />
    </Suspense>
  );

  const renderFastPayment = (): ReactElement => (
    <Suspense fallback={preloaderElement}>
      <FastPayment />
    </Suspense>
  );

  const renderAcquiring = (): ReactElement => (
    <Suspense fallback={preloaderElement}>
      <Acquiring />
    </Suspense>
  );

  const renderUnsubscribe = (): ReactElement => (
    <Suspense fallback={preloaderElement}>
      <Unsubscribe />
    </Suspense>
  );

  if (isSpecialBilling) {
    return (
      <Theme>
        <Switch>
          <Route exact={true} path={routes.ROOT} render={renderHelpSignIn} />
          <Route exact={true} path={routes.UN_SUBSCRIBE} render={renderUnsubscribe} />
          <Route exact={true} path={routes.FAST_PAYMENT} render={renderFastPayment} />
          <Layout pathname={pathname}>
            {operations.length > 0 ? (
              <Switch>
                <Route
                  exact={true}
                  path={routes.PASSWORD_RECOVERY}
                  render={renderPasswordRecovery}
                />
                <Route exact={true} path={routes.PASSWORD_CHANGE} render={renderPasswordChange} />
                <Route exact={true} path={routes.SIGN_IN} render={renderHelpSignIn} />
                <Route exact={true} path={routes.SIGN_UP} render={renderHelpSignUp} />
                <Route path={routes.OTHER} render={renderHelpSignIn} />
              </Switch>
            ) : (
              preloaderElement
            )}
          </Layout>
          <Route path={routes.OTHER} render={renderHelpSignIn} />
        </Switch>
        <Snackbar open={Boolean(message)} message={message} type={type} onClose={cleanUp} />
      </Theme>
    );
  }

  return (
    <Theme>
      {isDisplayBanner && <OldVersionAlert onClick={handleOldVersionAlert} />}
      <Switch>
        <Route exact={true} path={routes.UN_SUBSCRIBE} render={renderUnsubscribe} />
        {isBilling ? (
          <Route exact={true} path={routes.ROOT} render={renderLanding} />
        ) : (
          <Route exact={true} path={routes.ROOT} render={renderMain} />
        )}
        {isBilling && <Route exact={true} path={routes.FAST_PAYMENT} render={renderFastPayment} />}
        {isBilling && <Route exact={true} path={routes.ACQUIRING} render={renderAcquiring} />}

        <Layout pathname={pathname}>
          <>
            {operations.length > 0 ? (
              <Switch>
                <Route
                  exact={true}
                  path={routes.ORDER}
                  render={(): ReactNode => (
                    <IsAllowed
                      operation={OpencityOperations.ISSUE_CREATE}
                      defaultElement={routeDefaultElement}
                    >
                      {renderOrder}
                    </IsAllowed>
                  )}
                />
                <Route
                  exact={true}
                  path={routes.SHUTDOWNS}
                  render={(): ReactNode => (
                    <IsAllowed
                      operation={OpencityOperations.INTERRUPT_INDEX}
                      defaultElement={routeDefaultElement}
                    >
                      {renderShutdowns}
                    </IsAllowed>
                  )}
                />
                <Route
                  exact={true}
                  path={routes.RATINGS}
                  render={(): ReactNode => (
                    <IsAllowed
                      operation={OpencityOperations.ORGANIZATION_RATING_INDEX}
                      defaultElement={routeDefaultElement}
                    >
                      {renderRatings}
                    </IsAllowed>
                  )}
                />
                <Route
                  exact={true}
                  path={routes.ACQUIRING}
                  render={(): ReactNode => (
                    <IsAllowed
                      operation={BillingMethods.PAYMENT_INDEX}
                      defaultElement={routeDefaultElement}
                    >
                      {renderAcquiring}
                    </IsAllowed>
                  )}
                />
                {!isBilling && (
                  <Route
                    exact={true}
                    path={routes.FAST_PAYMENT}
                    render={(): ReactNode => (
                      <IsAllowed
                        operation={BillingMethods.ACCOUNT_INDEX}
                        defaultElement={routeDefaultElement}
                      >
                        {renderFastPayment}
                      </IsAllowed>
                    )}
                  />
                )}
                <Route exact={true} path={routes.HELP_CENTER} render={renderHelpCenter} />
                <Route exact={true} path={routes.SIGN_IN} render={renderHelpSignIn} />
                <Route exact={true} path={routes.SIGN_UP} render={renderHelpSignUp} />
                <Route
                  exact={true}
                  path={routes.PASSWORD_RECOVERY}
                  render={renderPasswordRecovery}
                />
                <Route exact={true} path={routes.PASSWORD_CHANGE} render={renderPasswordChange} />
                {routeDefaultElement}
              </Switch>
            ) : (
              preloaderElement
            )}
          </>
        </Layout>
      </Switch>
      <CodeConfirmed isOpen={codeConfirmed} onClose={cleanConfirmationStatus} />
      <Snackbar open={Boolean(message)} message={message} type={type} onClose={cleanUp} />
      {isBilling && !fromApp && <MobileAppPromotion />}
    </Theme>
  );
};

export default Root;
