import React, { FC } from 'react';
import { Grid, makeStyles, createStyles, Theme } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    skeleton: {
      backgroundColor: '#FFFFFF',
    },

    icon: {
      flex: 1,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  }),
);

export const PrepaymentSkeleton: FC = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container wrap="nowrap" spacing={4}>
        <Grid item container direction="column" justify="space-between">
          <Grid item>
            <Skeleton height={30} className={classes.skeleton} />
          </Grid>
          <Grid item>
            <Skeleton height={30} className={classes.skeleton} />
          </Grid>
        </Grid>
        <Grid item container className={classes.icon}>
          <Skeleton height={60} width={60} variant="rect" className={classes.skeleton} />
        </Grid>
      </Grid>
      <Grid item>
        <Skeleton height={30} className={classes.skeleton} />
      </Grid>
    </Grid>
  );
};
