import React, { FC } from 'react';
import { Document } from '@core/entities/Billing/MerchantGroup';
import { Grid, Typography, makeStyles, createStyles } from '@material-ui/core';
import blueGrey from '@material-ui/core/colors/blueGrey';

import Icon from '@components/Icon';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '15px 37px',
      borderRadius: 4,
      borderBottom: '1px solid rgba(224, 224, 224, 0.5)',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: blueGrey[50],
      },
    },
  }),
);

interface Props {
  document: Document;
}

export const DocumentItem: FC<Props> = ({ document }) => {
  const classes = useStyles();

  const { docName, docUrl } = document;

  const handleDownload = (): void => {
    window.open(docUrl);
  };

  return (
    <Grid container justify="space-between" onClick={handleDownload} className={classes.root}>
      <Typography>{docName}</Typography>
      <Icon icon="download" />
    </Grid>
  );
};
