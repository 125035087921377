import { FilterOperators } from '@stores/interfaces/Filter';
import { Load } from '@stores/interfaces/Entity';

export interface CameraInterface {
  id: string;
  azimuth?: number | null;
  districtId?: number | null;
  latitude?: string | null;
  longitude?: string | null;
  provider?: string | null;
  remoteId?: string | null;
  name?: string | null;
  url?: string | null;
  streamData?: StreamData | null;
}

export interface StreamData {
  channel: string;
  token: string;
  provider: string;
}

export class Camera {
  public id: string;
  public azimuth?: number | null;
  public districtId?: number | null;
  public latitude?: string | null;
  public longitude?: string | null;
  public provider?: string | null;
  public remoteId?: string | null;
  public name?: string | null;
  public url?: string | null;
  public streamData?: StreamData | null;

  constructor(value: CameraInterface) {
    this.id = value.id;
    this.azimuth = value.azimuth;
    this.districtId = value.districtId;
    this.latitude = value.latitude;
    this.provider = value.provider;
    this.remoteId = value.remoteId;
    this.longitude = value.longitude;
    this.name = value.name;
    this.url = value.url;
    this.streamData = value.streamData;
  }
}

export type LoadCameras = Load<{ filter?: CameraFilterProps }, Camera[]>;
export type LoadCamera = Load<{ id: string }, Camera | null>;

export enum CameraKeys {
  LONGITUDE = 'longitude',
  LATITUDE = 'latitude',
  PUBLIC = 'public',
  RADIUS = 'radius',
  DISTRICT = 'districtId',
  PROVIDER = 'provider',
}

export interface CameraFilterProps {
  [CameraKeys.LONGITUDE]?: number;
  [CameraKeys.LATITUDE]?: number;
  [CameraKeys.PUBLIC]?: FilterOperators<boolean>;
  [CameraKeys.RADIUS]?: FilterOperators<number>;
  [CameraKeys.DISTRICT]?: FilterOperators<number>;
  [CameraKeys.PROVIDER]?: FilterOperators<string>;
}
