/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

export const useOwnProfileStore = () => {
  const { ownProfileStore } = useStores();

  return useObserver(() => ({
    deleteBillingUser: ownProfileStore.deleteBillingUser,
    setUserDeleting: ownProfileStore.setUserDeleting,
    deleteUser: ownProfileStore.deleteUser,
    billingUsers: ownProfileStore.billingUsers,
  }));
};

export const usePaymentStore = () => {
  const { paymentStore } = useStores();

  return useObserver(() => ({
    loadCards: paymentStore.loadCards,
    detachCard: paymentStore.detachCard,
  }));
};

export const useAuthenticationStore = () => {
  const { authenticationStore } = useStores();

  return useObserver(() => ({
    signOutToSignUp: authenticationStore.signOutToSignUp,
  }));
};

export const useNotificationStore = () => {
  const { notificationStore } = useStores();

  return useObserver(() => ({
    deleteNotification: notificationStore.deleteNotification,
    deleteNotificationType: notificationStore.deleteNotificationType,
  }));
};
