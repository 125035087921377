import React from 'react';
import Icon from '@components/Icon';
import { isSpecialBilling } from '@core/constants/project';

import { useStyles } from './styles';

interface PartnerNotActiveProps {
  publicPart?: boolean;
}

export const PartnerNotActive: React.FC<PartnerNotActiveProps> = ({ publicPart }) => {
  const classes = useStyles();

  if (publicPart) {
    return (
      <div className={classes.containerPublic}>
        {!isSpecialBilling && <Icon className={classes.iconPublic} icon="smellyCat" />}

        <div className={classes.wrapperPublic}>
          <span className={classes.titlePublic}>
            Ваша организация больше не подключена к сервису
          </span>

          <span className={classes.textPublic}>
            Сервис "Кабинет Абонента" больше не доступен для абонентов вашей обслуживающей
            организации. Доступ в сервис был закрыт по ее запросу.
          </span>

          <span className={classes.textPublic}>
            По всем вопросам обратитесь, пожалуйста, в вашу обслуживающую организацию. Если вы
            хотите продолжать пользоваться сервисом рекомендуем сообщить вашей организации напрямую
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {!isSpecialBilling && <Icon className={classes.iconPublic} icon="smellyCat" />}

      <span className={classes.title}>Ваша организация больше не подключена к сервису</span>

      <span className={classes.text}>
        Сервис "Кабинет Абонента" больше не доступен для абонентов вашей обслуживающей организации.
        Доступ в сервис был закрыт по ее запросу.
      </span>

      <span className={classes.subText}>
        По всем вопросам обратитесь, пожалуйста, в вашу обслуживающую организацию. Если вы хотите
        продолжать пользоваться сервисом рекомендуем сообщить вашей организации напрямую
      </span>
    </div>
  );
};
