import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

export const useSmevStore = () => {
  const { smevStore } = useStores();

  return useObserver(() => ({
    loadSmevAccounts: smevStore.loadSmevAccounts,
    loadingSmevAccounts: smevStore.loadingSmevAccounts,
    capchaError: smevStore.capchaError,
  }));
};
