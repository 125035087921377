import React, { FC } from 'react';
import cn from 'classnames';
import { makeStyles, createStyles, Grid, Typography, Theme, TooltipProps } from '@material-ui/core';

import { Tooltip } from '@core/components/Tooltip';
import { yM } from '@core/utils/yandexMetrika/yandexMetrika';

import Icon from '../Icon';
import { isSpecialBilling } from '@core/constants/project';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltipContainer: {
      marginTop: theme.spacing(2),
    },

    tooltipContainerUpper: {
      marginTop: 0,
      marginBottom: theme.spacing(2),
    },

    tooltipIcon: {
      height: 16,
      width: 16,
      marginRight: theme.spacing(2),
      '@media (min-width: 600px) and (max-width: 630px)': {
        display: 'none',
      },
      '& > path': {
        fill: `rgba(7, 0, 61, 0.5) !important`,
      },
    },
    link: {
      display: 'block',
      marginTop: '10px',
      textDecoration: 'none',
      color: theme.palette.primary.main,
      fontWeight: 400,
    },
    tooltipInnerText: {
      margin: 0,
      fontWeight: 400,
    },
    tooltipText: {
      margin: 0,
      fontWeight: 400,
      color: isSpecialBilling ? theme.palette.primary.light : '#18CDA6',
      '@media (max-width: 350px)': {
        fontSize: '13px !important',
      },
      '@media (min-width: 600px) and (max-width: 690px)': {
        fontSize: '13px !important',
      },
      '@media (min-width: 960px) and (max-width: 1000px)': {
        fontSize: '13px !important',
      },
      '@media (min-width: 1280px) and (max-width: 1355px)': {
        fontSize: '13px !important',
      },
    },
  }),
);

interface PaymentTooltipProps {
  upper?: boolean;
  placement?: TooltipProps['placement'];
}

export const PaymentTooltip: FC<PaymentTooltipProps> = ({
  upper = false,
  placement = 'bottom-start',
}) => {
  const classes = useStyles();
  return (
    <Grid
      item
      className={cn(classes.tooltipContainer, { [classes.tooltipContainerUpper]: upper })}
      onClick={() => {
        yM.goals.buttonMypay();
      }}
    >
      <Tooltip
        placement={placement}
        interactive={true}
        title={
          <div>
            <p className={classes.tooltipInnerText}>
              Информация о платеже, совершенном в{' '}
              {isSpecialBilling ? 'Личном Кабинете' : 'Кабинете Абонента'}, отобразится на вашем
              лицевом счете в течение 15 минут!
            </p>

            {!isSpecialBilling ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={'https://payment.help.opencity.pro/mypay'}
                className={classes.link}
              >
                Подробнее
              </a>
            ) : null}
          </div>
        }
      >
        <Grid container alignItems="center">
          <Icon icon="info" className={classes.tooltipIcon} />
          <Typography variant="body2" className={classes.tooltipText}>
            Как быстро поступит мой платеж?
          </Typography>
        </Grid>
      </Tooltip>
    </Grid>
  );
};
