import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
    },

    title: {
      marginTop: 20,
      color: '#07003D',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '28px',
      textAlign: 'center',
    },

    text: {
      maxWidth: 700,
      marginTop: 32,
      color: '#07003D',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '24px',
      textAlign: 'center',
    },

    subText: {
      maxWidth: 700,
      marginTop: 20,
      color: '#07003D',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '24px',
      textAlign: 'center',
    },

    icon: {
      maxWidth: '400px',
      width: '100%',
    },

    containerPublic: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },

    titlePublic: {
      color: '#07003D',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '28px',
      textAlign: 'center',
    },

    textPublic: {
      maxWidth: 700,
      marginTop: 10,
      color: '#07003D',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '24px',
      textAlign: 'start',

      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },

    iconPublic: {
      maxWidth: '200px',
      width: '100%',
    },

    wrapperPublic: {
      marginLeft: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
  }),
);
