import React, { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { Tooltip } from '@core/components/Tooltip';
import Currency from '@core/utils/currency';

const useStyles = makeStyles(
  createStyles({
    icon: {
      width: 18,
      height: 18,
      marginLeft: 4,
    },

    title: {
      fontSize: '13px !important',
    },
  }),
);

export const PrepaymentTooltip: FC = () => {
  const classes = useStyles();

  return (
    <Tooltip
      interactive
      title={
        <Typography className={classes.title}>
          {`Аванс ${Currency.dash} это дополнительная сумма, которую вы можете добавить к текущему платежу, для
          оплаты будущих начислений за ЖКУ. Сумма аванса рассчитывается на основе ваших начислений
          за последние 3 месяца`}
        </Typography>
      }
    >
      <InfoOutlinedIcon className={classes.icon} />
    </Tooltip>
  );
};
