/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

export const useSmevStore = () => {
  const { smevStore } = useStores();

  return useObserver(() => ({
    loadSmevAccounts: smevStore.loadSmevAccounts,
    loadingSmevAccounts: smevStore.loadingSmevAccounts,
    loadingSmevAccountsError: smevStore.loadingSmevAccountsError,
    smevAccountsError: smevStore.smevAccountsError,
    smevAccountList: smevStore.smevAccountList,
    smevAccountListCleanUp: smevStore.smevAccountListCleanUp,
    notFirstFromSmev: smevStore.notFirstFromSmev,
  }));
};
