/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

export const useUserStore = () => {
  const { userStore } = useStores();

  return useObserver(() => ({
    userCreate: userStore.userCreate,
  }));
};
