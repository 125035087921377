/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

export const useDadataStoreStore = () => {
  const { dadataStore } = useStores();

  return useObserver(() => ({
    loading: dadataStore.loadingDadataAdresses,
    loadingError: dadataStore.loadingDadataAdressesError,
    capchaError: dadataStore.capchaError,
    list: dadataStore.dadataAdressesList,
    cleanUpList: dadataStore.dadataAdressesListCleanUp,
    loadList: dadataStore.loadDadataAdresses,
    checkDadataIsShowCaptcha: dadataStore.checkDadataIsShowCaptcha,
  }));
};
