export function makeDebounce(callback: any, delay: number) {
  let timer: any;

  return function(...args: any) {
    clearTimeout(timer);

    timer = setTimeout(() => {
      callback.apply(this, args);
    }, delay);
  };
}
