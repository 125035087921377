import React, { FC, RefObject } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { useStyles } from './styles';
import AboutImage from '../../Landing/images/about.svg';

import { SIGN_UP } from '@public/constants/routes';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

interface FeedbackProps {
  aboutElementRef?: RefObject<HTMLDivElement>;
}

const About: FC<FeedbackProps> = ({}) => {
  const classes = useStyles();

  const theme = useTheme();
  const isSm = useMediaQuery('(max-width:600px)');

  return (
    <Grid container alignItems={'center'} style={{ marginTop: '50px' }}>
      {isSm ? (
        <Grid
          item
          xs={12}
          sm={5}
          md={5}
          lg={5}
          className={`${classes.contentContainer} ${classes.imageContainer}`}
        >
          <img src={AboutImage} className={classes.image} />
        </Grid>
      ) : (
        <Grid item xs={12} sm={5} md={5} lg={5} className={classes.contentContainer}>
          <Grid container className={classes.container}>
            <Typography className={classes.subtitle} variant="h2">
              Подавай показания счетчиков
            </Typography>
          </Grid>
          <Grid container className={classes.container}>
            <Typography className={classes.content} variant="h5">
              Подавай показания счетчиков в любое удобное время. Не знаете когда следующая поверка?
              Мы вам предоставим полную информацию о ваших счетчиках!
            </Typography>
          </Grid>
          <Grid container className={classes.container}>
            <Link to={SIGN_UP} className={classes.link}>
              Попробовать
            </Link>
          </Grid>
        </Grid>
      )}
      {isSm ? (
        <Grid item xs={12} sm={5} md={5} lg={5} className={classes.contentContainer}>
          <Grid container className={classes.container}>
            <Typography className={classes.subtitle} variant="h2">
              Подавай показания счетчиков
            </Typography>
          </Grid>
          <Grid container className={classes.container}>
            <Typography className={classes.content} variant="h5">
              Подавай показания счетчиков в любое удобное время. Не знаете когда следующая поверка?
              Мы вам предоставим полную информацию о ваших счетчиках!
            </Typography>
          </Grid>
          <Grid container className={classes.container}>
            <Link to={SIGN_UP} className={classes.link}>
              Попробовать
            </Link>
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          sm={5}
          md={5}
          lg={5}
          className={`${classes.contentContainer} ${classes.imageContainer}`}
        >
          <img src={AboutImage} className={classes.image} />
        </Grid>
      )}
    </Grid>
  );
};

export default About;
