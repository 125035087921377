import React, { FC } from 'react';
import { textPrimary } from 'oc-theme/lib';
import {
  createStyles,
  makeStyles,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { MerchantGroup } from '@core/entities/Billing/MerchantGroup';
import { DocumentItem } from './components/DocumentItem';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      color: textPrimary,
    },

    button: {
      fontSize: 15,
      width: 180,
    },

    content: {
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
  }),
);

interface DeleteAccountProps {
  isVisible: boolean;
  onClose: () => void;
  docList: MerchantGroup['docList'];
}

export const DocumentsModal: FC<DeleteAccountProps> = ({ isVisible, onClose, docList }) => {
  const classes = useStyles();

  return (
    <Dialog open={isVisible} onClose={onClose} maxWidth="sm" transitionDuration={500} fullWidth>
      <DialogTitle>
        <Grid container alignItems="center" justify="space-between">
          <Grid item className={classes.title}>
            Список документов
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {docList.map((item, key) => (
          <DocumentItem document={item} key={key} />
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose} className={classes.button}>
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
};
