import { ReactElement } from 'react';
import { RenderInputParams } from '@material-ui/lab/Autocomplete';

import Autocomplete from './Autocomplete';

export type RenderInput = (params: RenderInputParams) => ReactElement;

export interface OptionType {
  label: string;
  value: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

export default Autocomplete;
