import React, { FC } from 'react';
import { createStyles, makeStyles, Theme, Dialog, DialogContent } from '@material-ui/core';
import Preloader from 'oc-preloader/lib';
import { isSpecialBilling } from '@core/constants/project';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('xs')]: {
        paddingTop: '64px',
      },

      '& > .MuiDialog-container': {
        '& > .MuiPaper-root': {
          backgroundColor: 'transparent',
          boxShadow: 'inherit !important',
          transition: 'inherit !important',
        },
      },
    },

    content: {
      padding: '0px !important',
      backgroundColor: 'transparent',
    },
  }),
);

interface Props {
  isVisible: boolean;
}

export const UpperLoader: FC<Props> = ({ isVisible }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isVisible}
      className={classes.root}
      transitionDuration={500}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogContent className={classes.content}>
        <Preloader color={isSpecialBilling ? '#3167F3' : ''} />
      </DialogContent>
    </Dialog>
  );
};
