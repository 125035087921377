import React, { FC, useCallback, useEffect, useState } from 'react';
import { Grid, Hidden, Typography, Button, Link, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { MerchantGroup } from '@entities/Billing/MerchantGroup';
import { CounterKeys } from '@core/entities/Billing/Counter';
import { Merchant } from '@core/entities/Billing/Merchant';
import { isBilling, isSpecialBilling } from '@core/constants/project';

import {
  useFastPaymentStore,
  useCounterStore,
  useMerchantGroupStore,
  useMerchantStore,
  useAccountStore,
  useSmevStore,
} from '../hooks';
import SpecialBillingHeader from '@public/components/SpecialBillingHeader';
import PaymentForm from '@public/components/PaymentForm';
import PaymentBlockComponent from '@private/pages/Payment/PaymentBlock/PaymentBlock';
import { ReactComponent as Image } from './images/image.svg';
import { ReactComponent as ImageSpecial } from './images/image_special.svg';
import { SIGN_IN, SIGN_UP } from '@public/constants/routes';
import { EXTERNAL_SUPPLIER } from '@core/constants/openCityProviderId';
import SignUp from '../SingUp';
import { Account, AccountKeys } from '@core/entities/Billing/Account';
import DadataPaymentForm from '@public/components/DadataPaymentForm';
import SmevPaymentBlock from '../../../../common/components/SmevPaymentBlock';
import { PartnerNotActive } from '@private/pages/PaymentOpencity/components/PartnerNotActive/PartnerNotActive';
import { OptionType } from '@core/components/Autocomplete';

const textColor = '#07003D';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      position: 'relative',
      color: textColor,
      marginTop: '-24px',
    },
    wrapper: {
      maxWidth: 1441,
      margin: 'auto',
      marginTop: 0,
      padding: '90px 149px 0 149px',

      [theme.breakpoints.down('md')]: {
        padding: '60px 100px 20px 80px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '60px 40px 20px 40px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '60px 15px 15px 15px',
      },
    },
    container: {
      zIndex: 2,
    },
    background: {
      position: 'absolute',
      top: 20,
      left: 41,
      width: '90%',
      zIndex: 1,
      [theme.breakpoints.down('md')]: {
        left: 31,
      },
      [theme.breakpoints.down('sm')]: {
        left: 21,
      },
    },
    titleWrapper: {
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(8),
      },
    },
    title: {
      [theme.breakpoints.down('md')]: {
        fontSize: '36px',
        lineHeight: '44px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '32px',
        lineHeight: '40px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '24px',
        lineHeight: '32px',
      },
    },
    form: {
      marginBottom: theme.spacing(8),
    },
    messageWrapper: {
      paddingTop: 68,
      paddingBottom: 32,
      [theme.breakpoints.down('xs')]: {
        paddingTop: 20,
        paddingBottom: 20,
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: 40,
        paddingBottom: 20,
      },
    },
    defaultMessage: {
      textAlign: 'center',
      opacity: 0.5,
    },

    accountNumberNotFound: {
      opacity: 1,
    },

    notActive: {
      border: '1px solid rgba(7, 0, 61, 0.15)',
      borderRadius: 8,
      padding: '24px 24px 24px 46px',
      [theme.breakpoints.down('sm')]: {
        padding: '24px 24px 24px 40px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '20px 20px 20px 20px',
      },
    },

    text: {
      color: textColor,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },

    signInButton: {
      marginTop: 8,
      color: '#07003D',
      border: '1px solid rgba(7, 0, 61, 0.15)',
      fontWeight: 'normal',
      [theme.breakpoints.up('sm')]: {
        marginTop: 0,
        width: 'calc(50% - 8px)',
      },

      [theme.breakpoints.up('md')]: {
        width: 160,
        marginTop: 8,
      },

      [theme.breakpoints.up('lg')]: {
        marginTop: 0,
        width: 140,
      },
    },

    signUpButton: {
      [theme.breakpoints.up('sm')]: {
        width: 'calc(50% - 8px)',
      },

      [theme.breakpoints.up('md')]: {
        width: 160,
      },

      [theme.breakpoints.up('lg')]: {
        width: 180,
      },
    },

    buttonWrapper: {
      flexDirection: 'column-reverse',

      [theme.breakpoints.down('sm')]: {
        marginTop: 16,
      },

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },

      [theme.breakpoints.up('md')]: {
        width: 160,
        flexDirection: 'column-reverse',
        justifyContent: 'flex-end',
      },

      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: 336,
      },
    },

    imageWrapper: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginBottom: 16,
      },
    },

    textWrapper: {
      [theme.breakpoints.up('md')]: {
        width: 'calc(100% - 125px - 32px - 160px)',
      },

      [theme.breakpoints.up('lg')]: {
        width: 'calc(100% - 125px - 48px - 320px)',
      },
    },
    link: {
      cursor: 'pointer',
    },
    signUpOrLogin: {
      marginTop: theme.spacing(8),
    },

    orgSwitcher: {
      marginTop: '13px',
      fontSize: '15px',
      color: '#18cda6',
      cursor: 'pointer',

      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
        fontSize: '12px',
      },
    },
  }),
);

export enum FastPaymentAccountStatus {
  NOT_TROUBLE,
  NOT_FOUND,
  NOT_ACTIVE,
}

interface PaymentBlockProps {
  focusOnSignUp: () => void;
  focus: boolean;
  onBlur: () => void;
}

interface OrganizationData {
  organizationId: number;
  organizationName: string;
}

const PaymentBlock: FC<PaymentBlockProps> = ({ focusOnSignUp, focus, onBlur }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const [organizaitonData, changeOrganizationData] = useState<OrganizationData | null>(null);

  const setOrganizationData = (organizationId: number, organizationName: string): void => {
    changeOrganizationData({ organizationId, organizationName });
  };

  const url = new URL(window.location.href);
  const accountNumber = url.searchParams.get('accountNumber');
  const providerId = url.searchParams.get('providerId');

  const paymentSource = url.searchParams.get('pay_src')
    ? Number(url.searchParams.get('pay_src'))
    : undefined;

  const { loadMerchantGroup } = useMerchantGroupStore();
  const { getAccount, setAccount } = useFastPaymentStore();
  const { loadCounters } = useCounterStore();
  const { loadMerchant } = useMerchantStore();
  const { partnerNotActive, checkPartners } = useAccountStore();
  const { smevAccountListCleanUp, setNotFirstFromSmev } = useSmevStore();

  const [merchantGroups, setMerchantGroups] = useState<MerchantGroup[]>([]);
  const [accounts, setAccounts] = useState<Account[]>();
  const [merchants, setMerchants] = useState<Merchant[]>([]);
  const [selectedSingleMerchantGroup, setSingleMerchantGroup] = useState<MerchantGroup | null>(
    null,
  );
  const [accountNotTrouble, setAccountTrouble] = useState<FastPaymentAccountStatus>(
    FastPaymentAccountStatus.NOT_TROUBLE,
  );
  const [action, setAction] = useState<boolean>(false);
  const [dataLoading, toggleLoading] = useState(false);

  const [orgSearchSwitcher, setOrgSearchSwitcher] = useState<boolean>(false);

  const switchOrgSearch = () => {
    setOrgSearchSwitcher(!orgSearchSwitcher);
  };

  useEffect(() => {
    return () => {
      setNotFirstFromSmev(false);
      smevAccountListCleanUp();
    };
  }, []);

  useEffect(() => {
    if (isBilling) {
      const specialMGroups = merchantGroups.filter(merchantGroup => merchantGroup.sourceId === 7);

      if (specialMGroups.length > 0) {
        window.location.replace(url.href.replace('cabinet.rc-online.ru', 'abonent.cab'));
      }
    }
  }, [merchantGroups]);

  const loadByParams = async (accountNumber: string, providerId: string): Promise<void> => {
    setAction(true);
    toggleLoading(true);

    const singleMethod = EXTERNAL_SUPPLIER.includes(Number(providerId));

    const account = await checkPartners({
      filter: {
        [AccountKeys.ACCOUNT_NUMBER]: { $eq: accountNumber },
        [AccountKeys.PROVIDER_ID]: { $eq: Number(providerId) },
      },
    });

    setAccounts(account);

    if (!account[0]?.active && Number(account[0]?.balance) <= 0 && !partnerNotActive) {
      setAccountTrouble?.(FastPaymentAccountStatus.NOT_ACTIVE);
    }

    const merchantGroups = await loadMerchantGroup({
      filter: {
        providerId: { $eq: Number(providerId) },
        accountNumber: { $eq: accountNumber },
      },
    });

    singleMethod &&
      setMerchants(
        await loadMerchant({
          filter: {
            providerId: { $eq: Number(providerId) },
            accountNumber: { $eq: accountNumber },
            merchantGroupId: { $in: merchantGroups.map(value => value.id) },
          },
          limit: 0,
        }),
      );

    await loadCounters({
      filter: {
        [CounterKeys.PROVIDER_ID]: { $eq: Number(providerId) },
        [CounterKeys.ACCOUNT_NUMBER]: { $eq: accountNumber },
      },
    });

    setMerchantGroups(merchantGroups);

    if (merchantGroups.length === 1) {
      setSingleMerchantGroup(merchantGroups[0]);
    }

    toggleLoading(false);
  };

  const handleSignIn = useCallback(() => {
    history.push(SIGN_IN);
  }, [history]);

  const handleSignUp = useCallback(() => {
    history.push(SIGN_UP);
  }, [history]);

  useEffect(() => {
    if (accountNumber && providerId) {
      loadByParams(accountNumber, providerId);
      setAccount(accountNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNumber, providerId]);

  const [addrValue, setAddrValue] = useState<null | OptionType>(null);
  const [accValue, setAccValue] = useState<string>('');

  return (
    <Grid container className={classes.root}>
      <Grid container direction="column" style={{ minHeight: '100%' }}>
        {isSpecialBilling && <SpecialBillingHeader />}
        <Grid container className={classes.wrapper}>
          <Grid item container direction="column" className={classes.container}>
            <Grid item className={classes.titleWrapper}>
              <Typography variant="h2" className={classes.title}>
                {isSpecialBilling || orgSearchSwitcher
                  ? 'Быстрая оплата ЖКУ'
                  : 'Быстрая оплата ЖКУ и передача показаний счетчиков'}
              </Typography>
            </Grid>

            <Grid item container className={classes.form}>
              {orgSearchSwitcher ? (
                <DadataPaymentForm
                  switchOrgSearch={switchOrgSearch}
                  addrValue={addrValue}
                  setAddrValue={setAddrValue}
                  accValue={accValue}
                  setAccValue={setAccValue}
                />
              ) : (
                <PaymentForm
                  setParentAccounts={(val: Account[]) => {
                    setAccounts(val);
                  }}
                  setMerchantGroups={setMerchantGroups}
                  setMerchants={setMerchants}
                  setSingleMerchantGroup={setSingleMerchantGroup}
                  setAccountTrouble={setAccountTrouble}
                  action={action}
                  setAction={setAction}
                  switchOrgSearch={switchOrgSearch}
                  toggleLoading={toggleLoading}
                  setOrganizationData={setOrganizationData}
                />
              )}
            </Grid>

            {!action && !orgSearchSwitcher && (
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.messageWrapper}
              >
                <Grid className={classes.defaultMessage}>
                  Введите название организации и лицевой счёт
                </Grid>
              </Grid>
            )}

            {action && partnerNotActive && !orgSearchSwitcher && (
              <Grid className={cn(classes.defaultMessage, classes.accountNumberNotFound)}>
                <PartnerNotActive publicPart />
              </Grid>
            )}

            {action && Boolean(accountNotTrouble) && !orgSearchSwitcher && !partnerNotActive && (
              <>
                {accountNotTrouble === FastPaymentAccountStatus.NOT_FOUND && (
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.messageWrapper}
                  >
                    <Grid className={cn(classes.defaultMessage, classes.accountNumberNotFound)}>
                      Указанный лицевой счёт не найден
                    </Grid>
                  </Grid>
                )}

                {accountNotTrouble == FastPaymentAccountStatus.NOT_ACTIVE && (
                  <Grid container justify="space-between" className={classes.notActive}>
                    <Grid item className={classes.imageWrapper}>
                      {isSpecialBilling ? <ImageSpecial /> : <Image />}
                    </Grid>
                    <Grid item container alignItems="center" className={classes.textWrapper}>
                      <Typography variant="body2" className={classes.text}>
                        Ваш лицевой счет закрыт вашей обслуживающей организацией. Вам не доступны
                        платежи и передача показаний счетчиков. Но вы можете посмотреть историю
                        платежей и начислений в личном кабинете.
                      </Typography>
                    </Grid>
                    <Grid item container className={classes.buttonWrapper}>
                      <Button
                        variant="outlined"
                        onClick={handleSignIn}
                        className={classes.signInButton}
                        fullWidth
                      >
                        Войти
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSignUp}
                        className={classes.signUpButton}
                        fullWidth
                      >
                        Регистрация
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </>
            )}

            {action && !accountNotTrouble && !orgSearchSwitcher && (
              <>
                {isBilling && isXs && merchantGroups.length ? (
                  <SignUp
                    focus={focus}
                    onBlur={onBlur}
                    organizationData={organizaitonData}
                    accountNumber={getAccount || accountNumber}
                    providerId={providerId}
                  />
                ) : null}

                <PaymentBlockComponent
                  accounts={accounts}
                  accountType={0}
                  accountNumber={getAccount}
                  selectedSingleMerchantGroup={selectedSingleMerchantGroup}
                  setSingleMerchantGroup={setSingleMerchantGroup}
                  merchantGroups={merchantGroups}
                  merchants={merchants}
                  loading={dataLoading}
                  paymentSource={paymentSource}
                  fromPublic
                />
              </>
            )}

            {orgSearchSwitcher && <SmevPaymentBlock paymentSource={paymentSource} fromPublic />}

            {isBilling && action && !accountNotTrouble && !orgSearchSwitcher && (
              <Hidden xsDown={true}>
                <Grid
                  container={true}
                  direction="column"
                  spacing={2}
                  alignItems="center"
                  className={classes.signUpOrLogin}
                >
                  <Grid item={true}>
                    <Typography variant="body2">
                      Вы можете получить больше информации по вашему лицевому счету в личном
                      кабинете!
                    </Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant="body2">
                      <Link className={classes.link} onClick={handleSignIn}>
                        Войти
                      </Link>{' '}
                      или{' '}
                      <Link className={classes.link} onClick={focusOnSignUp}>
                        Зарегистрироваться
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Hidden>
            )}
          </Grid>
        </Grid>
      </Grid>

      {isBilling && (
        <div style={{ width: '100%' }}>
          <Hidden xsDown={true}>
            <SignUp
              focus={focus}
              onBlur={onBlur}
              organizationData={organizaitonData}
              accountNumber={getAccount || accountNumber}
              providerId={providerId}
            />
          </Hidden>
        </div>
      )}

      {!isSpecialBilling && (
        <Hidden xsDown>
          <img
            src="images/public_pages/payment_form_bg.svg"
            alt=""
            className={classes.background}
          />
        </Hidden>
      )}
    </Grid>
  );
};

export default PaymentBlock;
