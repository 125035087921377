import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navbarIcon: {
      marginRight: '6px',
      '& > path': {
        fill: '#3167F3 !important',
      },
    },

    link: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: '15px',
      color: '#07003D',
      paddingLeft: 20,
      paddingRight: 40,
      marginTop: 20,
      cursor: 'pointer',
    },

    headerImage: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 20,
      marginLeft: '-10px !important',
      width: 90,
      height: 45,
      cursor: 'pointer',

      [theme.breakpoints.down('sm')]: {
        '& > img': {
          width: 100,
          height: 50,
        },
      },

      [theme.breakpoints.down('xs')]: {
        '& > img': {
          width: 90,
          height: 45,
        },
      },
    },

    headerMenu: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },

    closeButton: {
      marginRight: 10,
      marginBottom: 10,
      padding: 0,
      minWidth: 50,
      minHeight: 50,
      borderRadius: '50%',
    },

    divider: {
      backgroundColor: '#3167F3',
      height: 2,
      width: '100%',
      marginBottom: 10,
    },

    signUp: {
      width: 24,
      height: 24,
    },
  }),
);
