import React, { FC, ReactNode, ReactElement, CSSProperties } from 'react';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';

interface ValueListProps {
  value:
    | { [name: string]: { label: ReactNode; value: ReactNode } }
    | Array<{ label: ReactNode; value: ReactNode; key: string | number }>;
  variant?: 'default' | 'space-between' | 'flex-start';
  labelWeight?: number;
  labelMinWidth?: number;
  valueWidth?: string;
  spacing?: GridSpacing;
  withDivider?: boolean;
}

const useStyles = makeStyles(
  createStyles({
    divider: {
      backgroundColor: 'rgba(7, 0, 61, 0.15)',
    },

    text: {
      lineHeight: '24px',
      color: '#07003D',
    },

    container: {
      margin: '4px 0',
    },
  }),
);

const ValueList: FC<ValueListProps> = ({
  value,
  variant = 'default',
  labelWeight = 600,
  labelMinWidth = 150,
  valueWidth = 'initial',
  spacing = 2,
  withDivider,
}) => {
  const classes = useStyles();

  const renderList = (): ReactElement => {
    const values = Array.isArray(value)
      ? value
      : Object.keys(value).map(key => ({ ...value[key], key }));

    const renderValueElement = (value: ReactNode, style?: CSSProperties): ReactNode =>
      typeof value !== 'object' ? (
        <Typography className={classes.text} variant="body2" style={style}>
          {value}
        </Typography>
      ) : (
        value
      );

    return (
      <Grid container={true} direction="column" spacing={spacing}>
        {values.map(value => (
          <>
            <Grid
              className={classes.container}
              key={value.key}
              container={true}
              item={true}
              justify={variant === 'space-between' ? 'space-between' : 'flex-start'}
              wrap="nowrap"
            >
              <div style={{ minWidth: labelMinWidth }}>
                {renderValueElement(value.label, { fontWeight: labelWeight })}
              </div>
              <div style={{ width: valueWidth }}>
                {renderValueElement(value.value, { textAlign: 'right', whiteSpace: 'nowrap' })}
              </div>
            </Grid>
            {withDivider && <Divider className={classes.divider} />}
          </>
        ))}
      </Grid>
    );
  };

  return renderList();
};

export default ValueList;
