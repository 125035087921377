import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'transparent',
      position: 'sticky',
      top: 0,
      zIndex: 1300,
    },

    wrapper: {
      maxWidth: '1792px',
      display: 'flex',
      height: '120px',
      padding: '0 32px',
      justifyContent: 'space-between',

      width: '100%',
      boxSizing: 'border-box',

      [theme.breakpoints.up('lg')]: {
        margin: 'auto',
      },

      [theme.breakpoints.down('sm')]: {
        height: '80px',
        padding: '0 24px',
      },

      [theme.breakpoints.down('xs')]: {
        height: '60px',
        padding: 0,
      },
    },

    iconGrid: {
      display: 'flex',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row-reverse',
      },
    },

    menu: {
      paddingTop: 10,
      backgroundColor: '#FFFFFF',
    },

    openButton: {
      padding: 0,
      minWidth: 50,
      minHeight: 50,
      borderRadius: '50%',
    },
  }),
);
