import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';

import AboutFastPayImage from '../../Landing/images/about_fast_pay.svg';
import { useStyles } from './styles';
import { SIGN_UP } from '@public/constants/routes';

const AboutFastPay: FC<{}> = () => {
  const classes = useStyles();

  return (
    <Grid container direction="row">
      <Grid
        className={`${classes.contentContainer} ${classes.imageContainer}`}
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
      >
        <img src={AboutFastPayImage} className={classes.image} />
      </Grid>
      <Grid
        container
        className={classes.contentContainer}
        item
        xs={12}
        sm={5}
        md={5}
        lg={5}
        direction="column"
        justify="center"
        alignContent="center"
      >
        <Grid container className={classes.container}>
          <Typography className={classes.subtitle} variant="h2">
            Удобная и быстрая онлайн оплата ЖКУ
          </Typography>
        </Grid>
        <Grid container className={classes.container}>
          <Typography className={classes.content} variant="h5">
            Добавляй лицевые счета в кабинет, узнавай детали начислений и оплачивай услуги ЖКХ за
            себя и своих близких.
          </Typography>
        </Grid>
        <Grid container className={classes.container}>
          <Link to={SIGN_UP} className={classes.link}>
            Оплатить
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AboutFastPay;
